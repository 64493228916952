import { createRouter, createWebHistory } from 'vue-router'
// import axios from 'axios';

const routes = [
  {
    path: '/',
    name: 'workboard-view',
    component: () => import('@/views/WorkboardView.vue'),
    meta: { 
        layout: 'main',
        requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'profile-view',
    component: () => import('@/views/ProfileView.vue'),
    meta: { 
        layout: 'user',
        requiresAuth: true
    }
  },
  {
    path: '/partnership',
    name: 'partnership-view',
    component: () => import('@/views/DevelopingView.vue'),
    meta: { 
        layout: 'user',
        requiresAuth: true
    }
  },
  {
    path: '/billing',
    name: 'billing-view',
    component: () => import('@/views/BillingPage.vue'),
    meta: { 
        layout: 'user',
        requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login-view',
    component: () => import('@/views/LoginView.vue'),
    meta: {
        layout: 'logsign',
        // guest: true,
        requiresAuth: false,
    }
  },
  {
    path: '/register',
    name: 'register-view',
    component: () => import('@/views/RegisterView.vue'),
    meta: { 
        layout: 'logsign',
        // guest: true,
        requiresAuth: false,
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/ForgotPassView.vue'),
    meta: { 
        layout: 'logsign',
        // guest: true,
        requiresAuth: false,
    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/ResetPassView.vue'),
    meta: { 
        layout: 'logsign',
        // guest: true,
        requiresAuth: false,
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy-page',
    component: () => import('@/views/PrivacyView.vue'),
    meta: { 
        layout: 'policy',
        // guest: true,
        requiresAuth: false,
    }
  },
  {
    path: '/public-offer',
    name: 'public-offer',
    component: () => import('@/views/OfferView.vue'),
    meta: { 
        layout: 'policy',
        // guest: true,
        requiresAuth: false,
    }
  },
  {
    path: '/quiz-templates',
    name: 'quiz-templates',
    component: () => import('@/views/QuizTemplates.vue'),
    meta: {
      layout: 'main',
      requiresAuth: true,
    },
  },
  {
    path: '/:code/constructor',
    name: 'constructor-steps',
    component: () => import('@/views/ConstructorSteps.vue'),
    meta: {
      layout: 'constructor',
      tab: "steps",
      requiresAuth: true,
    },
  },
  {
    path: '/:code/settings',
    name: 'constructor-settings',
    component: () => import('@/views/ConstructorSettings.vue'),
    meta: {
      layout: 'constructor',
      tab: "steps",
      requiresAuth: true,
    }
  },
  {
    path: '/:code/design',
    name: 'constructor-design',
    component: () => import('@/views/ConstructorDesign.vue'),
    meta: {
      layout: 'constructor',
      tab: "steps",
      requiresAuth: true,
    }
  },
  {
    path: '/:code/admin-settings',
    name: 'admin-settings',
    component: () => import('@/views/SettingsAdmin.vue'),
    meta: {
      layout: 'main',
      tab: "settings",
      requiresAuth: true,
    }
  },
  {
    path: '/:code/widget-site-code',
    name: 'constructor-widget-site-code',
    component: () => import('@/views/QuizWidgetSiteCode.vue'),
    meta: {
      layout: 'main',
      tab: "settings",
      requiresAuth: true,
    }
  },
  {
    path: '/:code/widget-popup',
    name: 'constructor-widget-popup',
    component: () => import('@/views/QuizWidgetPopup.vue'),
    meta: {
      layout: 'main',
      tab: "settings",
      requiresAuth: true,
    }
  },
  {
    path: '/:code/widget-button',
    name: 'constructor-widget-button',
    component: () => import('@/views/QuizWidgetButton.vue'),
    meta: {
      layout: 'main',
      tab: "settings",
      requiresAuth: true,
    }
  },
   {
    path: '/healthz',
    name: 'health-check',
    meta: {
      layout: 'lodsign',
      requiresAuth: false,
    },
    component: {
      template: '<div>Application is healthy</div>'
    }
  },
  {
  path: '/readiness',
  name: 'readiness-check',
  meta: {
    layout: 'lodsign',
    requiresAuth: false,
  },
  component: {
    template: `<div>
                 <h2>Application is ready</h2>
                 <p>All systems are functional.</p>
               </div>`,
    mounted() {
    },
    methods: {
    }
  }
}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


function setCookie(name, value, options = {}) {

  options = {
    path: '/',
    // при необходимости добавьте другие значения по умолчанию
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}
function deleteCookie(name) {
  setCookie(name, "", {
    'max-age': -1
  })
}

router.beforeEach((to, from, next) => {
  let isLogin = localStorage.getItem('isLogin')
  if (to.path == '/') {
    let fullUrl = to.fullPath
    if (fullUrl == '/?is_login') {
      localStorage.setItem('isLogin', '1')
    }
  }
  if (to.path == '/POST') {
    next({
        path: '/',
    })
  }
  if (to.path == '/login') {
    if (isLogin == '0') {
      next()
    } else if (isLogin == '1') {
      next({ name: 'workboard-view'})
    }
    
  }
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (isLogin == '0') {
        deleteCookie('adsquiz_session')
        deleteCookie('XSRF-TOKEN')
        next({
            name: 'login-view',
            params: { nextUrl: to.fullPath }
        })
    } else {
      next()
    }
  } else if(to.matched.some(record => record.meta.guest)) {
      if(isLogin == false){
          next()
      }
      else{
          next({ name: 'workboard-view'})
      }
  }else {
      next() 
  }
})

export default router
