import { createI18n } from 'vue-i18n'
const i18n = createI18n({
  // default locale
  locale: 'EN',
  pluralizationRules: {
    /**
     * @param choice {number} индекс выбора, переданный в $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} общее количество доступных вариантов
     * @returns финальный индекс для выбора соответственного варианта слова
     */
    'UA': function(choice, choicesLength) {
      if (choice === 0) {
        return 0;
      }
      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;
      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }
      return (choicesLength < 4) ? 2 : 3;
    },
    'RU': function(choice, choicesLength) {
      if (choice === 0) {
        return 0;
      }
      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;
      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }
      return (choicesLength < 4) ? 2 : 3;
    }
  },
  // translations
  messages: {
    UA: {
      madeInUkr: "made in Ukraine",
      upProVersion: "Перейти на PRO",
      getLeads: "Купити Ліди",
      poperBillings: "Платежі",
      poperTemplates: "Шаблони",
      poperPartnership: "Партнерська програма",
      poperProfile: "Профайл",
      poperLogout: "Вихід",
      defaultNewQuestion: "Нове запитання",
      defaultNewQuestionDescription: "Сюди ви можете вставити короткий опис запитання (до 80 символів)",
      defaultNewVariant: "Новий варіант",
      defaultBtnText: "Розпочати",
      navBillings: "Платежі",
      navPartnership: "Партнерська програма",
      navProfile: "Профайл",
      welcomeBack: "З поверненням!",
      loginHeader: "Увійти",
      loginHeader2Part: "щоб продовжити",
      loginEmailPlaceholder: "E-mail",
      loginPasswordlPlaceholder: "○ ○ ○ ○ ○ ○ ○ ○ ○",
      loginBtnValue: "Увійти",
      loginWith: "Увійти за допомогою",
      loginSignup: "Зареєструватись",
      passwordAreForgetPassword: "Забули пароль?",
      passwordResetPasswordHeader: "Відновлення паролю",
      passwordResetPassword: "Відновити пароль",
      passwordWeSentReset: "Ми надіслали вам посилання на відновлення паролю на електронну пошту.",
      passwordInputNewPassword: "Введіть новий пароль",
      passwordConfirmPassword: "Підтвердіть пароль",
      passwordChangePassword: "Змінити пароль",
      passwordResetedPasswordCompleted: "Ваш пароль успішно змінено!",
      passwordGoToWorkboard: "Перейти в акаунт",
      helloHeader: "Вітаємо!",
      signupHeader: "Зареєструватись",
      signupHeader2Part: "щоб продовжити",
      signupNamePlaceholder: "Олександр",
      signupPhonePlaceholder: "номер телефону",
      signupEmailPlaceholder: "E-mail",
      signupPasswordlPlaceholder: "○ ○ ○ ○ ○ ○ ○ ○ ○",
      signupBtnValue: "Створити аккаунт",
      signupWith: "Зареєструватись за допомогою",
      signupLogin: "Увійти в мій аккаунт",
      signupTariffHeader: "Тарифи",
      signupTariffFree: "Free.",
      signupTariffPro: "Pro.",
      signupTariffFreeDescription: "Безлімітна кількість створення нових квізів (веб сторінок). Можливість генерувати до 300 заявок в місяць безкоштовно.",
      signupTariffProPrice: "$20 / місяць",
      signupTariffProDescription: "Безлімітна кількість створення нових квізів (веб сторінок). Можливість генерувати",
      signupTariffProDescriptionBold: "понад 300 заявок.",
      userBackToQuiz: "Повернутись на воркборд",
      userBackToSettings: "Повернутись до налаштувань",
      billingYourTariff: "Ваш тарифний план",
      billingYourPro: "Pro",
      billingYorFree: "Free",
      billingFreeLeads: "Безкоштовних заявок",
      billingInfinity: "безліміт",
      billingTariffRenewData: "Дата оновлення тарифу",
      billingTariffEndData: "Дата закінчення тарифу",
      billingOtherTariffFree: "Free.",
      billingOtherTariffPro: "Pro.",
      billingTariffFreeDescription: "Безлімітна кількість створення нових квізів (веб сторінок). Можливість генерувати до 300 заявок безкоштовно.",
      billingYouHavePro: "Зараз ви на Pro версії. Навіщо робити крок назад?",
      billingYouHasFreeLeads: "Залишилось безкоштовних заявок",
      billingYouHasLeads: "Залишилось заявок",
      billingTariffProDescription: "Безлімітна кількість створення нових квізів (веб сторінок). Можливість генерувати",
      billingTariffProDescriptionBold: "понад 300 заявок.",
      billingYourFutureTariff: "Ваш майбутній тариф",
      billingBtnChangeToPro: "Перейти",
      billingBtnRenewPro: "Оновити",
      billingBillings: "Оплати",
      billingYouHaveNotBillings: "У вас поки що немає оплат",
      billingPaymentsMethod: "Метод оплати",
      billingPaymentCard: "Карта",
      billingChangeData: "Змінити дані",
      billingDeletePayMethod: "Видалити",
      billingModalDeleteShure: "Ви дійсно хочете видалити цей спосіб оплати?",
      billingModalDeleteCancel: "Відмінити",
      billingModalDeleteOk: "Видалити",
      billingModalDeleteSuccess: "Ви успішно видалили свою карту",
      billingModalRegularPaymentDeleteSuccess: "Регулярні платежі відмінено",
      billingModalRegularPaymentDeleteOk: "ОК",
      billingYourMonthPlan: "Ваш щомісячний план",
      billingLeads: "заявок",
      billingChangePlan: "Змінити план",
      billingChangePlanDescription: "Прайс буде відповідним до бажаної кількості заявок з усіх квізів у вашому аккаунті.",
      billingSelectPeriod: "Обрати період",
      billingChangePlanDescriptionImportant: "Важливо:",
      billingChangePlanDescriptionImportantMonth: "Ліди можна використовувати 1 місяць після покупки",
      billingChangePlanDescriptionImportantMonthAfterBuy: " після покупки.",
      subscriptionTarifLable: "Підпишіться на нашу автоматичну місячну підписку. Ви можете будь-коли змінити свій план.",
      billingChangePlanWarning: "Придбані ліди будуть ",
      billingTariffMonth: "міс.",
      billingChangePlanPay: "Змінити тариф",
      profileName: "Ім’я",
      profilePhone: "Номер",
      profileEmail: "E-mail",
      profileStatus: "Статус",
      profileStatusAgency: "Агенція",
      profileStatusPlaceholder: "Виберіть що вас найкраще описує",
      profileStatusFreelancer: "Фрілансер",
      profileStatusBuisines: "Власник бізнесу",
      profilePassword: "Пароль",
      profileChangePassword: "Змінити пароль",
      profileNewPassword: "Новий пароль",
      profileSaveNewData: "Оновити дані",
      workboardAddNewQuiz: "Створити проєкт",
      workboardModalGiveQuizName: "Дайте назву квізу",
      workboardModalQuizNamePlaceholder: "Новий квіз",
      workboardModalNewQuizBtnValue: "додати",
      workboardAlertGiveAnyName: "Введіть хоч якусь назву",
      workboardAlertNextTimeAddQuiz: "Що ж, наступного разу наважитесь)",
      workboardChangeQuizName: "Змінити назву",
      workboardQuizLink: "лінк",
      workboardQuizLinkCopied: "Посилання скопійовано в буфер",
      workboardEditQuiz: "Редагувати",
      workboardCopyQuiz: "Скопіювати квіз",
      workboardQuizCopied: "Квіз успішно скопійовано",
      workboardDeleteQuiz: "Видалити",
      workboardModalDeleteQuizIrreversible: "Ця дія незворотна!",
      workboardModalDeleteQuizSure: "Ви дійсно хочете видалити цей квіз?",
      workboardModalDeleteQuizCancel: "Відмінити",
      workboardModalDeleteQuizDelete: "Видалити",
      workboardModalDeleteQuizSuccess: "Квіз успішно видалено",
      workboardModalQuizDeleteOk: "ОК",
      workboardStatsTitle: "Статистика",
      workboardModalLeadsAnswersCount: "Відповіді на квіз ",
      workboardModalLeadsLeadSerial: "№",
      workboardModalLeadsLeadDate: "Дата",
      workboardModalLeadsLeadEmail: "Email",
      workboardModalLeadsLeadName: "Ім'я",
      workboardModalLeadsLeadPhone: "Телефон",
      workboardModalLeadsLeadActions: "Дії",
      workboardModalLeadsLeadShowMore: "Показати більше",
      workboardModalLeadsLeadHideMore: "Приховати",
      workboardModalLeadsLeadAnswerScipped: "Пропущено",
      workboardModalNoLeads: "Поки що заявок немає",
      constructorLink: "лінк",
      constructorLinkCopied: "Скопійовано в буфер обміну:",
      constructorNavSteps: "Кроки",
      constructorNavDesign: "Дизайн",
      constructorNavSettings: "Налаштування",
      constructorStep: "крок",
      cancel: "Відмінити",
      constructorCancel: "Відмінити",
      constructorSave: "Зберегти",
      constructorextraNavStart: "С",
      constructorextraNavContact: "К",
      constructorextraNavFinish: "Ф",
      cstepsSection: "Секції",
      cstepsStartPage: "Стартова сторінка",
      cstepsContactPage: "Форма контактів",
      cstepsFinishPage: "Фінішна сторінка",
      cstepsEdit: "Редагувати",
      cstepsMove: "Перемістити",
      cstepsDeleteStep: "Видалити крок",
      cstepsAddSection: "Додати секцію",
      cdesignColorTheme: "Схема кольорів",
      cdesignCustomTheme: "Власна схема кольорів",
      cdesignDefaultThemes: "Готові схеми кольорів",
      cdesignThemeStandart: "Стандарт",
      cdesignThemeDark: "Дарк",
      cdesignThemeNud: "Нюд",
      cdesignThemeGradient: "Градієнт",
      cdesignShapeDesign: "Форма дизайну",
      cdesignShapeRound: "Заокруглена",
      cdesignShapeSquare: "Кутова",
      csettingsIntegration: "Інтеграції",
      csettingsOweDomain: "Підключення власного домену",
      csettingsDocs: "Юридичні документи",
      cintegrationIntegration: "Інтеграції",
      cintegrationShareLeads: "Передача заявок",
      cintegrationTitleEmail: "E-MAIL",
      cintegrationInstruction: "Інструкція",
      cintegrationInstructionDetail: "Деталі",
      cintegrationEmailInstruction: "Вставте в поле знизу потрібну електронну адресу, на яку необхідно відправляти інформацію. Кожна заявка з цього квіза буде дублюватися на вказану вами пошту.",
      cintegrationPublishAfterEdit: "Збережіть квіз після редагування",
      cintegrationSendNewLeadsTo: "Надсилати повідомлення про нові заявки на:",
      cintegrationInputEmail: "Введіть адресу електронної скриньки",
      cintegrationAddEmail: "Додати email",
      cintegrationEmailSaved: "Дані Email інтеграції збережено",
      cintegrationEmailDeleted: "Дані Email інтеграції видалено",
      cintegrationTitleFB: "FB pixel",
      cintegrationIDPixel: "Pixel ID",
      cintegrationFBInstruction1: "Зайдіть до рекламного кабінету Facebook Ads і у розділі Event Manager скопіюйте ID Facebook Pixel.",
      cintegrationFBInstruction2: "Не копіюйте весь код, лише ідентифікатор із 15-16 цифр.",
      cintegrationFBInstruction3: "Скопіюйте та вставте в поле нижче.",
      cintegrationFBInstruction: 'Подія на яку потрібно налаштовувати конверсію це "Lead". Ця подія буде викликана як тільки користувач усішно відправить заявку в квізі.',
      cintegrationInputIDPixel: "Введіть ID пікселя",
      cintegrationDataFBSaved: "Дані FB пікселя збережено",
      cintegrationAccesToken: "ACCES TOKEN",
      cintegrationTitleGA: "Google analytics",
      cintegrationGAInstructionDescription: "Дізнайтеся, як люди знаходять ваш квіз та взаємодіють з ним. Отримайте дані, необхідні для аналізу кампаній, покращення конверсій тощо. ",
      cintegrationGAInstruction: 'При отриманні заявки в квізі в Google Analitics буде викликана подія "generate_lead".',
      cintegrationIDFolow: "ID відстеження",
      cintegrationInputID: "Введіть ID",
      cintegrationDataGASaved: "Дані Google analytics збережено",
      cintegrationTitleGTM: "Google Tag Manager",
      cintegrationGTMInstructionDescription: "Опис для Google Tag Manager. (не використовується)",
      cintegrationGTMInstruction: "Скопіюйте індификатор Google Tag Manager та вставте в поле нижче.",
      cintegrationDataGTMSaved: "Дані Google Tag Manager збережено",
      cintegrationTitleTG: "Telegram",
      cintegrationHowReciveTGLeadBot: "Як отримати заявки в Телеграм бот?",
      cintegrationTGInstructionBot1: "1. Скопіюйте token у полі нижче.",
      cintegrationTGInstructionBot2: "2. Перейдіть за посиланням в бот, натисніть кнопку «ПОЧАТИ».",
      cintegrationTGInstructionBot3: "3. Скопійований token надішліть повідомленням в наш бот.",
      cintegrationHowReciveTGLeadChat: "Як отримати заявки в чат-телеграм?",
      cintegrationTGInstructionChat1: "1. Додайте наш бот в потрібний чат та виставте боту статус адміністратора.",
      cintegrationTGInstructionChat2: "2. Скопіюйте token у полі нижче і відправте його в потрібний чат.",
      cintegrationTGInstructionChat3: "3. Дочекайтесь відповіді `OK! Your quiz token was saved!”",
      cintegrationTGInstructionChat4: "4. Тепер заявки будуть надходити в цей чат.",
      cintegrationTGInstructionWarning: "Важливо:",
      cintegrationTGInstructionWarningText: "Зверніть увагу що це повинна бути саме група, а не Telegram канал. В Telegram канал заявки надходити не будуть.",
      cintegrationTGBotLink: "лінк на чатбот",
      cintegrationTGTitleToken: "TOKEN",
      cintegrationInputTGToken: "Введіть телеграм токен чатботу",
      cintegrationAddIntegration: "Додати інтеграцію",
      cintegrationDataTGSaved: "Дані Telegram інтеграції збережено",
      cintegrationTitleTT: "TT pixel",
      cintegrationTTInstruction: "Для відстеження подій у рекламному кабінеті Tik-Tok Ads скопіюйте ідентифікатор пікселя та вставте в поле нижче.",
      cintegrationDataTTSaved: "Дані TT пікселя збережено",
      cintegrationTitleWH: "WebHook",
      cintegrationWHInstruction1: "Ви можете сповіщати сторонні додатки про заяви, що надійшли. Для цього вкажіть URL, на який буде надіслано WebHook.",
      cintegrationWHInstruction2: "Ми підготували",
      cintegrationWHInstruction3: "відео інструкцію",
      cintegrationWHInstruction4: "з докладним описом даного функціоналу.",
      cintegrationWHInstruction5: "Переглянути відео.",
      cintegrationWHInstruction6: "У цьому відео ми розглянемо передачу хука на основі відправлення даних до Google таблиці та відправлення даних до CRM-системи Trello.",
      cintegrationWHInstruction7: "Ми рекомендуємо використовувати для цього сервіс",
      cintegrationWHInstruction8: "При реєстрації за цим посиланням, для наших користувачів діють бонус - 10 000 безкоштовних дій для будь-яких інтеграцій.",
      cintegrationWHInstruction9: "Посилання",
      cintegrationWHInstruction10: "для реєстрації з бонусом.",
      cintegrationWHUrl: "Адреса вебхуку",
      cintegrationInputWHUrl: "Введіть адресу вебхуку",
      cintegrationAddWH: "Додати Web-hook",
      cintegrationWebHookDataSaved: "WebHook дані збережено",


      cintegrationComonWHInstructionShort: "Ви можете сповіщати сторонні програми про надходження лідів. Вкажіть URL-адресу вебхука для відправки даних.",
      cintegrationComonWHInstruction1Title: "1. Вставте URL-адресу вебхука в поле для введення:",
      cintegrationComonWHInstruction1Description: "Вставте URL-адресу вебхука, наданого вашим сервісом або додатком, в поле для введення.",
      cintegrationComonWHInstruction2Title: "2. Збережіть зміни:",
      cintegrationComonWHInstruction2Description: 'Натисніть кнопку "Зберегти зміни", щоб зберегти налаштування вебхука.',
      cintegrationComonWHInstruction3Title: "3. Готово:",
      cintegrationComonWHInstruction3Description: "Тепер ваш вебхук налаштований для відправки даних на обраний вами сервіс.",
      cintegrationComonWHInstructionAdditional: "Дані будуть відсилатися на вебхук при кожному отриманні ліда. Метод відправки - POST. Приклад даних, які будуть відправлені, розташований ",
      cintegrationComonWHInstructionAdditionalLink: "тут",
      cintegrationComonWHInstructionVideo: "Детальна відео-інструкція",
      
      cintegrationApixDriveWHInstructionShort: "Ви можете сповіщати ApixDrive про надходження лідів. Для цього вкажіть URL-адресу, на яку буде відправлено вебхук.",
      cintegrationApixDriveWHInstructionShort1: "При реєстрації за ",
      cintegrationApixDriveWHInstructionShort2: "цим посиланням",
      cintegrationApixDriveWHInstructionShort3: " наші користувачі отримують бонус - 10 000 безкоштовних дій для будь-яких інтеграцій.",
      cintegrationApixDriveWHInstruction1Title: "1. Зареєструйтеся або відкрийте свій акаунт ApixDrive:",
      cintegrationApixDriveWHInstruction1Description1: "Посилання ",
      cintegrationApixDriveWHInstruction1Description2: "для реєстрації з бонусом.",
      cintegrationApixDriveWHInstruction2Title: "2. Отримайте URL-адресу для вебхука:",
      cintegrationApixDriveWHInstruction2Description1: "Зайдіть в налаштування необхідного зв'язку і скопіюйте адресу для відправки вебхука:",
      cintegrationApixDriveWHInstruction2Description2: 'Ви можете створити новий зв\'язок, натисканням кнопки "Створити зв\'язок", і на кроці вибору системи оберіть "ADSQuiz".',
      cintegrationApixDriveWHInstruction3Title: "3. Вставте URL-адресу вебхука:",
      cintegrationApixDriveWHInstruction3Description: "Вставте URL-адресу вебхука, наданого ApixDrive, в поле для введення.",
      cintegrationApixDriveWHInstruction4Title: "4. Збережіть зміни:",
      cintegrationApixDriveWHInstruction4Description: 'Натисніть кнопку "Зберегти зміни", щоб зберегти налаштування вебхука.',
      cintegrationApixDriveWHInstruction5Title: "5. Готово:",
      cintegrationApixDriveWHInstruction5Description: "Тепер ваш вебхук налаштований для відправки даних на ApixDrive.",
      cintegrationApixDriveWHInstructionAdditional: "Дані будуть відсилатися на вебхук при кожному отриманні ліда. Метод відправки - POST. Приклад даних, які будуть відправлені, розташований ",
      cintegrationApixDriveWHInstructionAdditionalLink: "тут",
      cintegrationApixDriveWHInstructionVideo: "Детальна відео-інструкція",
      



      cintegrationConectOweDomain: "Підключення власного домену",
      cintegrationOweDomainInstruction1: "1. Щоб розмістити ваш квіз на необхідному домені, зайдіть в обліковий запис вашого хостингу. Перейдіть в DNS-налаштування та створіть A-запис з параметром IP: 185.25.117.21",
      cintegrationOweDomainInstruction2: "2. В полі вищще вкажіть домен і збережіть квіз.",
      cintegrationDomain: "Домен",
      cintegrationInputYourDomain: "Введіть ваш домен",
      cintegrationCheckDomenIsCorrect: "Перевірьте правильність введеного домену",
      cintegrationDomainWhichCorrect: "Введіть домен без протоколів. наприклад: some.site.com",
      cintegrationInsertedDefaultDomain: "Домен встановлено за замовчуванням",
      cintegrationDomainDataSaved: "Дані домену збережено",
      cintegrationPrivacyPolicy: "Політика конфіденційності",
      cintegrationPrivacyPolicyByDefault: "За умовчанням ми використовуємо нашу стандартну політику конфіденційності. Ми рекомендуємо додати вашу політику конфіденційності, яка буде актуальною для вашого бізнесу.",
      cintegrationPrivacyPolicyAdventage: "Це дасть зрозуміти вашим потенційним клієнтам, як ви використовуєте отримані контактні дані. Також цього вимагають деякі рекламні майданчики.",
      cintegrationPrivacyPolicyUrl: "Посилання на ваші документи",
      cintegrationPrivacyPolicyInputYourDocsLink: "Вставте посилання на документи",
      cintegrationDocsDataSaved: "Посилання на ваші документи збережено",
      allstepsUpploadPhoto: "Завантажити фото",
      allstepsChangePhoto: "Змінити фото",
      allstepsDeletePhoto: "Видалити фото",
      allstepsTitle: "Заголовок",
      allstepsDescription: "Опис",
      allstepsButtonText: "Текст кнопки",
      allstepsOtherSettings: "Інші налаштування",
      allstepsIsSaveBeforeRoute: "Зберегти данні перед переходом?",
      cfirstFormat: "Формат",
      cfirstHeaderStartText: "Ваш заголовок має бути тут. Мотивуйте користувачів пройти квіз до кінця",
      cfirstAddMediafile: "Додати медіафайл (фото)",
      cfirstBonus: "Бонус",
      cfirstAddBonus: "Додати бонус",
      cfirstStartPageDataUpdated: "Дані стартової сторінки оновлені",
      csanyAnswerType: "Тип запитання",
      csanyATCard: "картка",
      csanyATList: "список",
      csanyATCustom: "вільне поле",
      csanyQuestion: "Запитання",
      csanyAnswers: "Відповіді",
      csanyAddAnswer: "додати",
      csanyRequiredStep: "Обов'язковий крок",
      csanyAlowMultiple: "Дозволити декілька відповідей",
      csanyAutoRedirect: "Автоматичний перехід далі",
      csanyQuestionLogic: "Налаштування логіки питань",
      csanyDataStepUpdated: "Дані сторінки з запитанням оновлені",
      cformForm: "Форма",
      cformValueName: "Ім'я",
      cformValueSurname: "Прізвище",
      cformValuePhone: "Номер телефону",
      cformValueEmail: "E-mail",
      cformDataContactsPageUpdated: "Дані контактної сторінки оновлені",
      cformRequiredInput: "Обов'язкове",
      cfinishFinishPageTitle: "Фінішна сторінка",
      cfinishText: "Текст",
      cfinishDescription: "Опис",
      cfinishAddImage: "Додати зображення",
      cfinishImageAdded: "Зображення додано",
      cfinishURL: "URL",
      cfinishButtonText: "Текст кнопки",
      cfinishInsertNikname: "Вставте сюди ваш нікнейм",
      cfinishCheckDataIsCorrect: "Перевірьте правильність введеного ідентифікатора",
      cfinishFbWhichCorrect: "Введіть ідентифікатор (нікнейм) без адреси. Наприклад: some.name",
      cfinishIgWhichCorrect: "Введіть ідентифікатор (нікнейм) без адреси. Наприклад: some.name",
      cfinishTgWhichCorrect: "Введіть ідентифікатор (нікнейм) без адреси. Наприклад: some.name",
      cfinishFinishPageDataUpdated: "Дані фінішної сторінки оновлені",
      adminUsersReg: "Користувачів в системі",
      adminNewUsers: "Нових користувачів за місяць",
      adminProAccounts: "PRO accounts",
      adminLTV: "LTV",
      adminSharePro: "PRO відносно усіх",
      adminReciveLeads: "Отримано заявок",
      adminLeadsForOne: "На одного юзера",
      adminMadeQuiz: "Зроблено квізів",
      adminQuizForOne: "На одного юзера",
      adminAvarageUsed: "Середня кількість місяців використання",
      adminProfitMonth: "Зароблено за місяць",
      adminProfitAlltime: "Зароблено за весь час",
      adminUsersList: "База користувачів",
      notificationErrorLogin: "Помилка авторизації:",
      notificationLogin: "Вхід...",
      notificationWrongLoginData: "Неправильні дані для входу",
      notificationNetworkError: "Перевірте з'єднання з інтернетом",
      notificationInputLoginData: "Введіть данні для входу",
      notificationCheckInputEmail: "Перевірте правильність електронної пошти",
      notificationCheckInputPhone: "Перевірте правильність номеру телефону",
      notificationInputAllInputs: "Заповніть всі поля для реєстрації",
      notificationAgreePrivacyPolicy: "Погодьтесь з політикою конфіденційності",
      adminEditorForPartnership: "Редактор партнерської програми",
      developmentPage: "Розділ знаходиться в розробці",
      signupAgreeIAgreeWith: "Я погоджуюсь з",
      signupAgreePolicy: "політикою конфіденційності",
      signupAgreeAnd: "та",
      signupAgreeOffer: "публічною офертою",
      signupAgreeService: "сервісу",
      workboardModalLeadsLeadReferrer: "Не визначено",
      workboardModalLeadsLeadReferrerClarification: "Дані про джерело переходу заблоковані або не визначені",
      workboardModalLeadsLeadEmailCopied: "Email скопійовано до буферу",
      workboardModalLeadsLeadPhoneCopied: "Номер телефону скопійовано до буферу",
      workboardModalLeadsHaveNotSended: "Ви маєте неотримані ліди",
      workboardModalLeadsLeadReload: "Отримати",
      workboardModalLeadsLeadReloadedTitle: "Запит отримано",
      workboardModalLeadsLeadReloaded: "Якщо у вас залишились ліди, неотримані ліди будуть завантажені зверху списку. Також, вони будуть відправлені в додані інтеграції",
      cfirstStartPagePhotoUpdated: "Фото стартової сторінки оновлене",
      allstepsPhotoTooBig: "Файл занадто великий!",
      allstepsPhotoMustBeLess: "Фото повинно бути меньше",
      cfirstStartPageBonusPhotoUpdated: "Фото бонусу оновлене",
      cstepsQuestionsOrderSaved: "Порядок запитань збережено",
      csanyAnswerOrderSaved: "Порядок відповідей збережено",
      allstepsPhotoAdaptation: "Адаптувати фото під пристрої",
      allstepsPhotoFit: "Співвідношення сторін",
      constructorYouHideStep: "Ви сховали цей крок",
      cstepsHiddenStep: "Приховати крок",
      cstepsShowStep: "Відобразити крок",
      cfinishFinishPageHiddenUpdated: "Фінішна сторінка прихована.",
      cfinishFinishPageHiddenUpdatedGoContact: "Перейдіть на сторінку Форми контактів",
      cfinishFinishPageHiddenUpdatedSetRedirect: "та налаштуйте редирект після відправки даних",
      cformActionAfterContactRecive: "Дії після отримання контактів",
      cformActionAfterContactThankyou: "Сторінка подяки",
      cformActionAfterContactRedirect: "Переадресація",
      cformAdditionalSettings: "Додаткові налаштування",
      cformRedirectURL: "URL",
      cintegrationOweDomainInstructionImportant: "Важливо: Адреса у вашому хостинг обліковому записі і полі нижче, повинні збігатися. Час застосування змін DNS-налаштуваннь, та виписки SSL сертифікату - до 3-х годин після збереження.",
      cintegrationOweDomainInstructionVideo: "Переглянути відео інструкцію",
      workboardModalLeadsLeadLimit: "Ліміт ваших лідів вичерпаний.",
      workboardModalLeadsLeadLimitInstruction1: "Щоб побачити нові ліди,",
      workboardModalLeadsLeadLimitInstruction2: "перейдіть на інший тариф",
      workboardModalLeadsLeadLimitInstruction3: "оновіть поточній тариф",
      workboardModalLeadsLeadLimitInstruction4: '(не стосується "Free") або дочекайтеся оновлення тарифу, якщо у вас підключене автопродовження тарифу',
      billingBonusesTitle: 'Промокод',
      billingBonusesDescription: 'Введіть отриманий промокод у полі поруч',
      billingBonusesPlaceholder: 'Промокод',
      billingBonusesBtn: 'Активувати',
      billingBonusesActivated: 'Промокод активовано!',
      billingBonusesLeadIncreased: 'Кількість доступних лідів збільшено.',
      billingBonusesInvalid: 'Неправильний промокод',
      billingBonusesNotAvailable: 'Ви вже активували цей промокод',
      billingBonusesAlreadyActivated: 'Бонуси цього промокоду вже були нараховані',
      createQuizSPDescription: "Це місце для короткого опису",
      createQuizCPTitle: "Як ми можемо з вами зв'язатися?",
      createQuizCPDescription: "Будь ласка, заповніть форму нижче",
      createQuizCPButton: "Готово!",
      createQuizFPTitle: "Дякуємо!",
      createQuizFPDescription: "Тепер ми підберемо найкраще рішення для вас!",
      createQuizFPButton: "Покажи мені щось!",
      selectInternationalPrefix: "Обрати міжнародний формат",
      autoInternationalPrefix: "Визначати країну автоматично",
      dovnloadCsvTitle: "Експортувати данні за вибраний період",
      dovnloadCsvSelectAllPeriod: "Обрати за весь період",
      dovnloadCsvCancel: "Відмінити",
      dovnloadCsvExport: "Експортувати",
      
      


      widgetEmbedVidgetToSite: "Встановлення квіза на сайті",
      widgetEmbedVidgetSelectEmbedVariant: "Оберіть варіант інтеграції",
      widgetEmbedVidgetSitecode: "В коді сайта",
      widgetEmbedVidgetPopup: "Поп-ап",
      widgetEmbedVidgetChat: "Квіз-кнопка",
      widgetEmbedVidgetInProgres: "В розробці",
      widgetEmbedVidgetToSiteCode: "Встановлення квіза на сайті",
      widgetSiteCodeInstruction0: "Щоб встановити квіз на вашому сайті, додайте код з першого поля в тег `<head>` на вашому сайті або Google Tag Manager, якщо він інтегрований у ваш сайт. Якщо після того, як ви вже вставите квіз на сайт, ви зміните домен у квіза Вам потрібно буде оновити код інтеграції на вашому сайті.",
      widgetSiteCodeInstruction1: "В місце куди ви хочете інтегрувати квіз вставте код з другого поля.",
      widgetSiteCodeInstruction2: "Квіз можна інтегрувати в контейнер будь-якого розміру, навіть, в сайдбари! Максимальный розмір квізу, так само як і при проходженні на окремому домені - 900х552 пікселя. Його можна додавати в контейнери і більшого розміру, в такому випадку квіз буде відцентровано.",
      widgetSiteCodeInstruction3: "Не соромтеся - експерементуйте!",
      widgetSiteCodeCopyToHead: "Скопіюйте та вставьте в `<head>` вашого сайту",
      widgetSiteCodeCopyToBody: "Вставте код в те місце, де повинен бути квіз",
      workboardModalAddQuizHeader: "Створення нового Quiz",
      workboardModalGiveQuizNameAndMethod: "Придумайте назву вашого квізу та виберіть бажаний спосіб створення",
      workboardModalNewQuizFromStart: "Створити з нуля",
      workboardModalNewQuizFromTemplates: "Готові шаблони",
      QuizTemplatesSearchInput: "Назва шаблону та/або категорій",
      QuizTemplatesSearchBtn: "Пошук",
      QuizTemplatesReadyTemplates: "Готові шаблони",
      QuizTemplateView: "Переглянути",
      QuizTemplateSelect: "Обрати",
      widgetPopupInstruction0: "Щоб встановити квіз на вашому сайті, додайте код з поля з кодом в тег `<head>` на вашому сайті або Google Tag Manager, якщо він інтегрований у ваш сайт. Якщо після того, як ви вже вставите квіз на сайт, ви зміните домен у квіза або тригери відкриття (відсоток скрола або таймінг), вам потрібно буде оновити код інтеграції на вашому сайті.",
      widgetPopupInstruction1: "Відразу перед закриттям тега `</footer>` вставте код з другого поля.",
      widgetPopupInstruction2: "Для програмістів чи просунутих користувачів",
      widgetPopupInstruction3: "Якщо вам з якихось причин незручно вставляти квіз перед закриваючим тегом `</footer>` ви можете вставити код з другого поля в будь-яке зручне місце, але враховуйте, що контейнер, в який ви вставляєте код з другого поля, повинен бути поверх всіх вікон і вкрай бажано повинен мати висоту та ширину максимально можливу для дисплея.",
      widgetPopupCopyToHead: "Скопіюйте та вставте в `<head>` вашого сайту",
      widgetPopupCopyToBody: "Скопіюйте та вставте код перед закриваючим тегом `</footer>`",
      widgetPopupTimeToOpen: "Проміжок часу",
      secondReduction: "сек",
      widgetPopupTimeClarification: "Попап з’явиться з затримкою зазначеного вище часу у секундах",
      widgetPopupScrollToOpen: "Відсоток прогортування сайту",
      widgetPopupScrollClarification: "Попап з’явиться після зазначеного вище відсотку прогортування вашого сайту",
      quizzesTemplatesAllTags: "Всі шаблони",
      widgetEmbedVidgetButton: "Конструктор кнопки",
      widgetButtonInstruction0: "Ви можете встановити на вашому сайті квіз який буде відкриватись при кліку по кнопці.",
      widgetButtonInstruction1: "Додайте заокруглення, тінь, та текст кнопки. Також, ви можете змінити колір кнопки та тексту всередині.",
      widgetButtonInstruction2: "Скопіюйте скрипт розміщений в першому полі з кодом та вставте його перед закриваючим тегом `</head>`.",
      widgetButtonInstruction3: "Скопіюйте згенерований код кнопки, з другого поля з кодом та вставте в будь-яке місце вашого сайту.",
      widgetButtonInstruction4: 'Ви можете не використовувати код з другого поля з кнопкою а просто додати `id="button_open_quiz_popup"` до будь-якого єлемента `<input type="button">` або єлемента `<button></button>` на сайті.',
      widgetRealTimeChange: "Ви можете бачити зміни в реальному часі у правій частині екрану",
      widgetButtonCornerRound: "Заокруглення кнопки",
      widgetButtonShadow: "Тінь кнопки",
      widgetButtonBgColor: "Колір кнопки",
      widgetButtonTextColor: "Колір тексту",
      widgetButtonTextValue: "Текст кнопки",
      openPopupQuizText: "Розпочати квіз",
      widgetButtonInsertBeforeAfterBody: "Вставте код квіза одразу після відкриваючого тега `<body>` або перед одразу перед закриваючим тегом `</body>`",
      widgetSiteCodeCopyButton: "Вставте код кнопки в будь-яке місце на сайті",
      footerContactUs: "Маєте питання? Можете з нами зв'язатись:",
      enterYourName: "Введіть ваше ім'я",
      enterYourPhone: "Введіть номер телефону",
      enterYourEmail: "Введіть ваш email",
      enterYourPassword: "Введіть ваш пароль",
      selectOption: "Оберіть варіант",
      createNewGroup: "Створити нову групу",
      dontHaveAccount: "Не маєте облікового запису?",
      resetPasswordTip: "Якщо у вас виникли додаткові проблеми чи запитання, будь ласка, не соромтеся зв’язатися з нашою командою підтримки клієнтів. Ми тут, щоб допомогти.",
      getBackAccount: "Поверніться до свого облікового запису",
      inMinutes: "за хвилину",
      alreadyHaveAccount: "Вже маєте обліковий запис?",
      quizSlotLastEdit: "Остання зміна",
      workboardQuizzesTitle: "Мої квізи",
      allstepsBack: "Назад",
      constructorGetLink: "Поділитись квізом",
      allOr: "або",
      allAnd: "і",
      acceptedFileType: "Прийнятні формати: ",
      conjunctionAnd: "та",
      acceptedFileSize: "Максимальний розмір: ",
      acceptedFileSizeMeasure: "МБ",
      cfirstBonusDisabled: "Вимкнено",
      cfirstBonusAvailable: "Доступно",
      allstepsResetInHeader: "Скинути",
      placeholderText: "Введіть тут",
      constructorSaveChanges: "Зберегти зміни",
      cformInputAvailable: "Доступно",
      cformInputDisabled: "Вимкнено",
      constructorBack: "Назад",
      videoInstruction: "Відеоінструкція",
      csettingsIntegrationFB: "Інтеграція - Facebook Pixel",
      csettingsIntegrationTT: "Інтеграція - TikTok",
      csettingsIntegrationGA: "Інтеграція - Google Analytics",
      csettingsIntegrationGTM: "Інтеграція - Google Tag Manager",
      csettingsIntegrationWH: "Інтеграція - WebHook",
      csettingsIntegrationTG: "Інтеграція - Telegram",
      csettingsIntegrationApixDrive: "Інтеграція - APIxDrive",
      csettingsIntegrationEmail: "Інтеграція - Email",
      csettingsIntegrationAbout: "Докладно",
      csettingsIntegrationFBAbout: "Перейдіть в рекламний кабінет Facebook Ads та скопіюйте ідентифікатор Facebook Pixel в розділі Менеджер подій. Скопіюйте лише 15-16-значний ідентифікатор, а не весь код, та вставте його у поле нижче.",
      csettingsIntegrationTTAbout: "Для відстеження подій у вашому рекламному обліковому записі Tik-Tok скопіюйте ідентифікатор пікселя та вставте його у поле нижче.",
      csettingsIntegrationGAAbout: "Скопіюйте ідентифікатор Google Analytics та вставте його у поле нижче.",
      csettingsIntegrationGTMAbout: "Скопіюйте ідентифікатор Google Tag Manager та вставте його у поле нижче.",
      csettingsIntegrationWHAbout1: "Ви можете сповіщати сторонні програми про надходження потенційних клієнтів. Для цього вкажіть URL-адресу, на яку буде відправлено вебхук. Ми рекомендуємо використовувати цю послугу. ",
      csettingsIntegrationWHAboutApix: "Apix-Drive ",
      csettingsIntegrationWHAbout2: "При реєстрації за цим посиланням нашим користувачам надається бонус - 10 000 безкоштовних дій для будь-яких інтеграцій.",
      csettingsIntegrationTGAbout: "Важливо: зауважте, що це повинна бути група, а не канал у Telegram. Потенційні клієнти не будуть надсилатися до каналу в Telegram.",
      csettingsIntegrationADAbout: "Щось про APIxDrive",
      csettingsIntegrationEmailAbout: "Активуйте електронний лист для вашого респондента та повідомте свою команду. Співпрацюйте зі своєю командою.",
      cSettingsIntegrationNeedHelp: "Потрібна допомога?",
      cSettingsIntegrationRefreshToken: "Оновити токен",
      csettingsSettingASettings: "Додаткові налаштування",
      cSettingsSettingQuizLangTitle: "Мова квізу",
      cSettingsSettingQuizLangDescription: "Щоб забезпечити вам найкращий досвід, будь ласка, виберіть потрібну мову квізу з варіантів нижче. Цією мовою будуть відображатись системні сповіщення та деякі інші незмінні тексти при проходженні квізу.",
      selectQuizLanguage: "Оберіть мову квізу",
      cSettingsSettingQuizHideLabelTitle: "Приховати мітку",
      cSettingsSettingQuizHideLabelDescription: 'Увімкнувши цей параметр, ви зможете насолоджуватися вільним від мітки "made by adsquiz" інтерфейсом, що дозволить вам та вашим відвідувачам зосередитися виключно на питаннях.',
      cSettingDataSaved: "Додаткові налаштування збережено",
      userAccountInfo: "Інформація про обліковий запис",
      userGeneralInfoHeader: "Загальна інформація",
      userGeneralInfoDescription1: "Ваш профіль користувача - це місце, де ви можете керувати своїм обліковим записом та налаштуваннями.",
      userGeneralInfoDescription2: "Оновлюйте свою особисту інформацію, змінюйте пароль та налаштовуйте свої відомості про сповіщення.",
      profileNameFirst: "Ім'я",
      profileNameLast: "Прізвище",
      userSecuritySettingsHeader: "Налаштування безпеки",
      userSecuritySettingsDescription: "Для зміни пароля просто двічі введіть новий пароль для підтвердження.",
      profileEmailCurent: "Поточна адреса електронної пошти",
      profileEmailNew: "Нова адреса електронної пошти",
      profileNewPasswordConfirm: "Підтвердіть новий пароль",
      profileDeleteAccount: "Видалити обліковий запис",
      profileDeleteAccountPopupHeader: "Видалити обліковий запис?",
      profileDeleteAccountPopupDescription: 'Ви впевнені, що хочете видалити свій обліковий запис? Ця дія неможлива до скасування. Для підтвердження видалення, будь ласка, введіть слово "ВИДАЛИТИ" у поле нижче.',
      profileDeleteAccountCheckedWord: "ВИДАЛИТИ",
      profileDeleteAccountPopupPlaceholder: "Введіть тут",
      notificationSuccess: "Успішно!",
      notificationSuccessSaveAccount: "Ваш пароль успішно змінено! Використовуйте новий пароль для входу",
      notificationSuccessDeleteAccount: "Ваш обліковий запис успішно видалено! Використовуйте новий пароль для входу",
      cardPanUnspecified: "Не вказано",
      userBillingsDate: "Дата",
      userBillingsCost: "Вартість",
      userBillingsId: "ID",
      userBillingsMethod: "Спосіб оплати",
      paymentSwitchToProHeader: "Перейти на Pro",
      paymentSwitchToProDescription: "Знайдіть план, який підходить саме вам! Оновлюйте або знижуйте план у будь-який час - ми полегшуємо вибір потрібного плану.",
      paymentYourCurrentPlan: "Ваш поточний план:",
      paymentYourFuturePlan: "Ваш новий план:",
      paymentPlanLeadsLimit: "Ліміт лідів на місяць:",
      paymentPlanLeadsLimitForTest: "Ваш ліміт для тесту квізів:",
      paymentPlanLeadsLeft: "Залишилося лідів:",
      paymentPlanPrice: "Ціна:",
      paymentPlanRenew: "Дата продовження тарифу:",
      addNewSectionHeader: "Додати новий розділ",
      addNewSectionList: "Список",
      addNewSectionCustom: "Поле вводу",
      addNewSectionCard: "Карта",
      addNewSectionWithEmoji: "Список з емодзі",
      addNewSectionTags: "Теги",
      addNewSectionSlider: "Повзунок",
      templatesBackToQuiz: "Повернутися до воркборда",
      constructorStepsSaved: "Всі дані квіза успішно збережено",
      constructorDesignSaved: "Дизайн квіза успішно збережено",
      azureBlueWhite: "Стандартний",
      azureBlue: "Зимова ніч",
      moderatePurplishRed: "Червоний оксамит",
      violetBlueCrayola: "Зимовий бриз ",
      wetRainforest: "Літній клен",
      lavender: "Космос",
      blueLilac: "Темна лаванда",
      opalGreen: "Карпатській ліс",
      dustyBlue: "Тіффані",
      almondCrayola: "Кораловий риф",
      periwinkleCrayolaLilac: "Бузок",
      periwinkleCrayolaAqua: "Синевір",
      jazzJam: "Джаз",
      slateGrayBlue: "Мгла",
      turquoiseBlueCrayola: "Дія",
      blackPearlescentOpal: "Попіл",
      pearlescentGentianBlue: "Сапфір",
      sapphireBlue: "Темний сапфір",
      errorUserDataUpdate: "Помилка в оновлені даних: ",
      completeUserDataUpdate: "Дані користувача оновлені",
      passwordDoNotCoincidence: "Паролі не співпадають",
      passwordDoNotCoincidenceAgain: "Спробуйте ще раз",
      nonBillingsYet: "У вас ще немає історії платежів.",
      youCanChangePlan: "Ви можете змінити поточний план, натиснувши кнопку «Перейти на PRO»",
      rangeMin: "Мін",
      rangeMax: "Макс",
      rangePositionStart: "Початкова позиція",
      rangePositionEnd: "Кінцева позиція",
      rangeStep: "Крок",
      rangeIsRange: "Діапазон",
      rangeIsRangeTrue: "Увімкнено",
      rangeIsRangeFalse: "Вимкнено",
      recivePromotionNewsletter: "Отримувати імейл розсилки",
      reciveEmailCheckbox: "Чекбокс отримання Імейлів",
      additionalCheckbox: "Додатковий чекбокс",
      contactPrivacyPolicyCheckbox: "Чекбокс згоди з приватністю",
      contactAgreeIAgreeWith: "Я погоджуюсь з",
      contactAgreePolicy: "політикою конфіденційності",
      contactAgreeAnd: "та",
      contactAgreeTermsOfUse: "правилами використання",
      contactAgreeAgreementAdditionalWord: "",
      langEN: "Англійська",
      langDE: "Німецька",
      langSP: "Іспанська",
      langPL: "Польська",
      langIT: "Італійська",
      langKK: "Казахська",
      langRU: "Російська",
      langUA: "Українська",
      langLT: "Литовська",
      langLV: "Латвійська",
      langET: "Естонська",
      langRO: "Румунська",
      leadsLeft: "Залишилось лідів",
      generateDomainTitle: "Введіть ім'я домену",
      generateDomainDescription: "Це унікальна адреса, за якою буде доступна ваша сторінка. Ви можете будь-коли змінити адресу або підключити свою.",
      generateDomainDomainNameLabel: "Доменне ім'я",
      cancelAllAction: "Відмінити",
      confirmAllAction: "Підтвердити",
      falseHasValidCharacters: "Недопустимі символи в домені. Допустимими символами є літери латинської абетки, цифри і дефіси.",
      falseHasValidLength: "Довжина домену повинна бути від 6 до 20 буквено-цифрових символів.",
      falseDoesNotStartOrEndWithSpecialChar: "Домен не повинен починатися або закінчуватися спеціальним символом.",
      falseDoesNotHaveConsecutiveSpecialChar: "Домен не повинен містити два або більше підряд спеціальних символа.",
      falseDoesNotHaveConsecutiveSameChar: "Домен не повинен містити три або більше підряд однакових символа.",
      falseDoesNotContainForbiddenSubstrings: "Домен не повинен містити заборонені підстроки.",
      generateDomainTaken: "Цей домен вже зайнято. Спробуйте інший",
      temporarilyUnavailable: "Тимчасово недоступно",
      csettingsAdmin: "Налаштування для адмінів",
      questionLogicSetupTitle: "Налаштування логіки питання",
      questionLogicSetupDescription: "Додайте умови, щоб налаштувати опитування для кожного респондента і збирати більш точні дані.",
      questionLogicConditionTitle: "Умова",
      questionLogicQuestion: "Питання",
      questionLogicSetupQuestionDisableOption: "Оберіть питання",
      questionLogicSetupAnswer: "Відповідь",
      questionLogicSetupLogicType: "Тип",
      questionLogicSetupAnswerDisableOption: "Оберіть тип умови",
      questionLogicSetupRangeBetween: "Між",
      questionLogicSetupRangeLess: "Менше",
      questionLogicSetupRangeMore: "Більше",
      questionLogicSetupRangeEqualTo: "Дорівнює",
      questionLogicSetupRangeFrom: "Від",
      questionLogicSetupRangeTo: "До",
      questionLogicSetupVariantAll: "Обрані всі",
      questionLogicSetupVariantAny: "Обрано один з",
      questionLogicSetupVariantNot: "Жоден з обраних",
      questionLogicSetupCustomEqual: "Дорівнює",
      questionLogicSetupCustomContains: "Містить",
      questionLogicSetupCustomNotContains: "Не містить",
      questionLogicSetupRangeValue: "Значення",
      questionLogicSetupExpectedAnswer: "Очікувані відповіді",
      questionLogicSetupPlaceholder: "Введіть тут",
      questionLogicAllMandatory: "Всі наведені умови обов'язкові",
      questionLogicAddConditionBtn: "Додати умову",
      questionLogicNotAvailable: "Налаштування логіки недоступні для першого питання",
      questionLogicSaved: "Налаштування логіки відображення кроку збережені",
      billingPaymentInfoTitle: "Тарифна інформація",
      billingPaymentInfoChangeTarrif: "Змінити тариф",
      billingPaymentInfoDescription: "При оплаті  будь-якого платного тарифу ви отримуєте певну кількість заявок які входить в обраний вами пакет. Ці заявки надаються на місяць. Якщо ви не витратили вашу кількість заявок на через місяць  моменту оплати - заявки анулюються.",
      billingGeneralName: "Ваш тариф",
      billingGeneralCost: "Вартість",
      billingMonth: "місяць",
      billingGeneralExpired: "Дійсний до",
      billingGeneralLeadsLeft: "Заявок залишилось",
      billingGeneralAllTarifExpired: "Термін дії тарифів закінчено",
      billingGeneralBonus: "Лідів по промокоду",
      billingGeneralLeadsFromPastTarifs: "Залишок лідів з минулих тарифів",
      billingAcordeonPaymentInfoTitle: "Платіжна інформація",
      billingAcordeonPaymentInfoFormat: "Формат",
      billingAcordeonPaymentInfoSubscription: "Підписка",
      billingAcordeonPaymentInfoPay: "Оплата",
      billingAcordeonPaymentInfoPayMethod: "Платіжний метод",
      billingAcordeonPaymentInfoCancelSubscription: "Скасувати підписку",
      billingAcordeonPromocode: "Промокод",
      billingAcordeonPromocodeLabel: "Введіть промокод",
      billingAcordeonPayHistory: "Історія транзакцій",
      paymentAllTarifInactive: "тариф неактивний",
      subscriptionUnsubscribeModalTitle: "Відписатись",
      subscriptionUnsubscribeModalDescription: "Ви впевнені що хочете відписатись? Нараховані за підпискою ліди ви зможете використовувати протягом місяця з дня останнього списання коштів за підпискою.",
      subscriptionIsNotDeleteTitle: "Підписка не була видалена",
      subscriptionIsNotDeleteDescription: "Зверніться в службу підтримки",
      subscriptionCanceledTitle: "Підписка анулована",
      modalSureUnsavedChangesTitle: "У вас є незбережені зміни",
      modalSureUnsavedChangesDescription: "Перед тим як покинути поточну сторінку, будь ласка, вирішіть що зробити з незбереженими змінами",
      modalSureUnsavedChangesDontSave: "Не зберігати",
      modalSureUnsavedChangesSave: "Зберегти",
      telegramTokenCopied: "Токен квізу скопійовано в буфер обміну",
      cSettingsSettingQuizBackButtonTitle: "Кнопка назад",
      cSettingsSettingQuizBackButtonDescription: "Ви можете увімкнути або вимкнути кнопку повернення на попередній крок. Це надасть можливість змінювати дану раніше відповідь.",
      cSettingsCustomThemePrimaryColor: "Колір акцентів",
      cSettingsCustomThemeSecondaryColor: "Фоновий колір",
      cSettingsCustomThemeAdditionalColor: "Колір тексту",
      cSettingsCustomThemeButtonTextColor: "Текст в кнопках",
      cSettingsCustomThemeModeDark: "Темна",
      cSettingsCustomThemeModeLight: "Світла",
      allstepsPlaceholder: "Плейсхолдер",
      allstepsCustomInput: "Свій варіант",
      allstepsCustomInputOn: "Увімкнути",
      allstepsCustomPlaceholder: "Власний варіант ",
      trashcanTitle: "Кошик",
      trashcanImportant: "Важливо!",
      trashcanDescription: "Квіз буде автоматично видалено назавжди через 30 днів після переміщення в кошик.",
      trashcanClearAll: "Очистити все",
      quizSlotDeletedData: "Видалено",
      workboardLeads: "Ліди",
      workboardDuplicate: "Дублювати",
      leadsTable: "Список лідів",
      exportInfo: "Експорт лідів",
      leadHide: "Сховати",
      leadShowMore: "Детальніше",
      poperTrashcan: "Кошик",
      restoreQuizSendedTitle: "Запущена задача відновлення",
      restoreSuizSendedImportant: "Важливо",
      restoreQuizSendedDescription: "В залежності від того, як багато лідів було у вашому квізі, відновлення квізу може зайняти від декількох секунд до 2-х хвилин. Після відновлення квіз з'явиться на вашому основному воркборді.",
      ok: "Зрозуміло",
      month: "0 місяців | {n} місяць | {n} місяці | {n} місяців",
      discount: "Знижка до",
      saveMoney: "Підписка на рік",
      upProVersionMobile: "На PRO",
      upProVersionDesktop: "Перейти на PRO",



      
      csettingsIntegrationSendPulse: "Інтеграція - Send Pulse",
      csettingsIntegrationMake: "Інтеграція - Make",
      csettingsIntegrationZapier: "Інтеграція - Zapier",
      csettingsIntegrationCRMBitrix: "Інтеграція - CRM Bitrix",
      csettingsIntegrationPipeDrive: "Інтеграція - Pipe Drive",
      csettingsIntegrationRemOnline: "Інтеграція - Rem Online",
      csettingsIntegrationKeyCRM: "Інтеграція - Key CRM",
      
      csettingsIntegrationZapierAbout: "Вставте URL-адресу для відправки данних, надану Zapier, в поле для вводу.",
      csettingsIntegrationMakeAbout: "Вставте URL-адресу для відправки данних, надану Make, в поле для вводу.",
      csettingsIntegrationCRMBitrixAbout: "Вставте URL-адресу для відправки данних, надану CRM Bitrix, в поле для вводу.",
      csettingsIntegrationSendPulseAbout: "Вставте id клієнта, секретний ключ, адресну книгу та дію Send Pulse, у відповідні поля для вводу.",
      csettingsIntegrationKeyCRMAbout: "Вставте API ключ та PipelineID, у відповідні поля для вводу.",
      csettingsIntegrationPipeDriveAbout: "Вставте токен та домен компанії, у відповідні поля для вводу.",
      csettingsIntegrationRemOnlineAbout: "Вставте API ключ Rem Online у відповідне поле для вводу.",
      csettingsIntegrationActiveIntegration: "Активна інтеграція",
      cintegrationIntegrationData: "Дані інтеграції",
      cintegrationIntegrationUrl: "URL інтеграції",
      cintegrationWriteUrl: "Введіть URL",
      cintegrationDataFBError: "Неправильний ідентифікатор пікселя",
      cintegrationDataWebHookError: "Неможлива адреса вебхуку",
      cintegrationZapierDataSaved: "Дані інтеграції з Zapier збережено",
      cintegrationDataZapierError: "Неможлива адреса інтеграції",
      cintegrationAPIxDriveDataSaved: "Дані інтеграції з APIxDrive збережено",
      cintegrationDataAPIxDriveError: "Рядок не є адресою інтеграції APIxDrive ",
      cintegrationMakeDataSaved: "Дані інтеграції з Make збережено",
      cintegrationDataMakeError: "Неможлива адреса інтеграції",
      cintegrationSendPulseDataSaved: "Дані інтеграції з SendPulse збережено",
      cintegrationDataSendPulseError: "Неможливі або порожні дані інтеграції",
      cintegrationPipeDriveDataSaved: "Дані інтеграції з PipeDrive збережено",
      cintegrationDataPipeDriveError: "Неможливі або порожні дані інтеграції",
      cintegrationRemOnlineDataSaved: "Дані інтеграції з RemOnline збережено",
      cintegrationDataRemOnlineError: "Неможливі або порожні дані ключ",
      cintegrationKeyCRMDataSaved: "Дані інтеграції з KeyCRM збережено",
      cintegrationDataKeyCRMError: "Неможливі або порожні дані інтеграції",
      cintegrationInputEmailUrl: "Введіть Email",
      cintegrationEmailDataSaved: "Email збережено",
      cintegrationDataEmailError: "Введений рядок не є Імейлом",
      cintegrationCRMBitrixDataSaved: "Email збережено",
      cintegrationDataCRMBitrixError: "Введений рядок не є Імейлом",
      cintegrationTelegramBotDataSaved: "Налаштування Telegram інтеграції збережено",
      cintegrationDataTelegramBotError: "Помилка збереження",
      cintegrationGTMDataSaved: "Ідентифікатор Google Tag Manager збережено",
      cintegrationDataGTMError: "Невірний ідентифікатор",
      cintegrationDataGTMErrorDescription: "Ідентифікатор повинен мати вигляд GTM-XXXXXX",
      cintegrationGoogleAnalyticsDataSaved: "Ідентифікатор Google Analytics збережено",
      cintegrationDataGoogleAnalyticsError: "Неправильний ідентифікатор",
      cintegrationDataGoogleAnalyticsErrorDescription: 'Ідентифікатор повинен мати вигляд "UA-XXXXXXX-X" або "G-XXXXXXXXXX-XX"',
      cintegrationTikTokDataSaved: "Tik-Tok піксель збережено",
      cintegrationDataTikTokError: "Введений рядок не є Tik-Tok пікселем",
      cintegrationIntegrationToken: "Токен інтеграції",
      cintegrationWriteToken: "Введіть токен інтеграції",
      cintegrationDataYourAPIKey: "Введіть ваш API ключ",
      viewQuizInMobile: "Переглянути квіз",
      notificationCSRFTokenMismatch: "Невідповідність CSRF токену",
      notificationReloadPage: "Перезавантажте сторінку та спробуйте ще раз",
      csettingsIntegrationKeyRemOnline: "Вставте токен та ідентифікатор каналу у відповідні поля",
      cintegrationDataYourIntegrationToken: "API ключ",
      cintegrationDataLeadTypeId: "Ідентифікатор каналу",
      send_utm: "Відправляти UTM мітки",
      send_quiz: "Відправляти дані квізу",
      send_answers: "Відправляти питання і відповіді",
      csettingsIntegrationKeyCrm: "Інтеграція KeyCRM",
      cintegrationSendPulseClientId: "ID клієнта",
      cintegrationSendPulseClientSecret: "Secret",
      cintegrationSendPulseAddressBookId: "ID адресної книги",
      cintegrationSendPulseAction: "Дія з контактом",
      sendpulseActionAddContact: "Додати контакт",
      sendpulseActionDeleteContact: "Видалити контакт",
      cintegrationPipeDriveToken: "API токен",
      cintegrationPipeDriveDomain: "Ваш унікальний субдомен",
      cintegrationKeyCrmApiKey: "API ключ",
      cintegrationKeyCrmPipelineId: "Ідентифікатор воронки",
      cintegrationMenuGoogleTagManager:"Google Tag Manager",
      cintegrationMenuGoogleAnalytics:"Google Analytics",
      cintegrationMenuFacebookPixel:"Facebook pixel",
      cintegrationMenuTikTokPixel:"TikTok Pixel",
      cintegrationMenuWebHook:"Webhook",
      cintegrationMenuZapier:"Zapier",
      cintegrationMenuAPIxDrive:"APIx Drive",
      cintegrationMenuMake:"Make",
      cintegrationMenuSendPulse:"Send Pulse",
      cintegrationMenuEmail:"Email",
      cintegrationMenuTelegramBot:"Telegram-бот",
      cintegrationMenuPipeDrive:"PipeDrive",
      cintegrationMenuRemOnline:"Rem Online",
      cintegrationMenuKeyCRM:"Key CRM",
      cintegrationMenuCRMBitrix:"CRM Bitrix",
      trashcanRestoreQuiz: "Відновити квіз",
      cformValuePromotionAccepted: "Погоджуюсь на емейл розсилку",


      constructorAddIntegration: "Додати нову інтеграцію",
      cintegrationKeyCrmSourceId: "Ідентифікатор джерела (необов'язково)",
      cintegrationKeyCrmManagerId: "Ідентифікатор менеджера (необов'язково)",
      cSettingsSettingQuizSpamFilterTitle: "Захист від спаму",
      cSettingsSettingQuizSpamFilterDescription: "Функціонал за замовчуванням вимкнутий. Якщо ви зіштовхнулись з проблемою спамових лідів - увімкніть захист. Після цього, один користувач не зможе відправити більше вказаної кількості лідів на цей квіз.",
      cSettingsSettingQuizSpamFilterLimitPlaceholder: "Кількість лідів",
      langNO: "Норвезька",
      loadMoreLeads: "Переглянути більше лідів",
      userAdditionalSettingsHeader: "Додаткові налаштування",
      userAdditionalSettingsDescription1: "Тут ви можете налаштувати нові додаткові параметри профілю.",
      userAdditionalSettingsPagination: "Пагінація на воркборді",
      
      benefitType: "Тип бонусу",
      benefitLabelPlaceholder: "Текст бонусу",
      benefitDescriptionPlaceholderDiscount: "Промокод або інструкція",
      benefitDescriptionPlaceholderVideo: "Посилання на відео",
      benefitDescriptionPlaceholderTextfile: "Посилання на текстовий файл",
      benefitDescriptionPlaceholderGift: "Розшифровка бонусу",
      benefitDescriptionPlaceholderPDF: "Посилання на PDF",
      benefitDescriptionPlaceholderLink: "Посилання",
      benefitDescriptionPlaceholderFile: "Посилання на файл",
      benefitLabelDiscount: "Знижка",
      benefitLabelVideo: "Відео",
      benefitLabelTextfile: "Текстовий файл",
      benefitLabelGift: "Подарунок",
      benefitLabelPDF: "PDF файл",
      benefitLabelLink: "Посилання",
      benefitLabelFile: "Файл",
      addBenefit: "Додати бонус",
      saveBenefit: "Зберегти бонус",
      cfinishSocialNetworks: "Соціальні мережі",
      
      saveSocialNet: "Зберегти соціальну мережу",
      networkLabelFacebook: "Facebook",
      networkLabelInstagram: "Instagram",
      networkLabelTelegram: "Telegram",
      networkLabelTiktok: "Tik-Tok",
      networkLabelYoutube: "Youtube",
      networkLabelLinkedin: "LinkedIn",
      networkLabelWhatsapp: "WhatsApp",
      socialNetDescriptionPlaceholderFacebook: "нікнейм",
      socialNetDescriptionPlaceholderInstagram: "нікнейм",
      socialNetDescriptionPlaceholderTelegram: "нікнейм / канал",
      socialNetDescriptionPlaceholderTiktok: "нікнейм",
      socialNetDescriptionPlaceholderYoutube: "нікнейм_каналу",
      socialNetDescriptionPlaceholderLinkedin: "in/name or company/name",
      socialNetDescriptionPlaceholderWhatsapp: "+15555551234",

      addNewSectionEmptyPage: "Текстова сторінка",
      csanyMakeImpasse: "Зробити тупиковим кроком",
      impaseIsImpaseTrue: "Увімкнено",
      impaseIsImpaseFalse: "Вимкнено",
      defaultNewEmptyQuestionDescription: "Тут можна вставляти та форматувати довгий текст (ми не рекомендуємо вставляти більше 3000 символів).",
      cSettingsSettingQuizTimezoneTitle: "Таймзона квізу",
      cSettingsSettingQuizTimezoneDescription: "Впишіть таймзону відповідно якої буде вказуватись час заповнення лідів. Це зручно використовувати, особливо тоді, коли ви знаходитесь в одному часовому поясі, а клієнт в іншому. В такому разі, ви можете вказати часовий пояс клієнта і в лідах які будуть приходити, буде вказано час згідно часового поясу клієнта.",
      cSettingsSettingQuizTimezonePlaceholder: "Часовий пояс",
      userAdditionalSettingsTimezone: "Часовий пояс користувача",
      userAdditionalSettingsTimezonePlaceholder: "Часовий пояс",
      langHE: "Іврит",
      langCS: "Чеська",
      cintegrationCAPIFBInstruction: "Щоб налаштувати інтеграцію з Facebook Conversion API, вам необхідно ввести ідентифікатор пікселя та маркер доступу. Ці дані можна отримати в налаштуваннях Facebook Business Manager. Після введення необхідної інформації збережіть інтеграцію.",
      csettingsIntegrationFbCapiAbout: "Інтеграція з Facebook Conversion API дозволяє передавати дані про взаємодії користувачів безпосередньо в Facebook для підвищення ефективності рекламних кампаній.",
      cintegrationInputCapiAccessToken: "Введіть маркер доступу",
      cintegrationInputCapiCustomparameter: "Введіть кастомні параметри подій",
      cintegrationDataFbCapiSaved: "Інтеграція з Facebook Conversion API успішно збережена",
      cintegrationComonRenovateHubInstructionTitle: "Інструкція з налаштування інтеграції з RenovateHub",
      cintegrationComonRenovateHubInstructionDescription: "Для налаштування інтеграції з RenovateHub вкажіть URL вебхука, на який будуть надсилатися дані. Переконайтеся, що URL правильний і доступний для прийому даних.",
      cintegrationComonRenovateHubInstructionShort: "Інтеграція з RenovateHub дозволяє передавати дані в CRM через вебхук для автоматизації процесів.",
      cintegrationRenovateHubUrl: "Введіть URL RenovateHub",
      cintegrationInputRenovateHubUrl: "Вкажіть URL вебхука для RenovateHub",
      cintegrationRenovateHubDataSaved: "Інтеграція з RenovateHub успішно збережена",
      cintegrationDataRenovateHubError: "Помилка при збереженні інтеграції з RenovateHub. Перевірте правильність введених даних.",
      cintegrationMenuFacebookConvAPI: "Facebook Conversions API",
      cintegrationMenuRenovateHub: "RenovateHub",
      csettingsIntegrationRenovateHub: "Інтеграція з RenovateHub",
      csettingsIntegrationCAPIFB: "Інтеграція з Facebook Conversions API",
      paymentPlanEnd: "Дата закінчення тарифу",
      paymentPlanLeadsLimitForYear: "Ліміт лідів на рік:",
      paymentPlanLeadsLimitForPeriod: "Ліміт лідів на період:",
      EditTags: "Змінити теги",
      quizzesTemplatesNoTags: "Без тегів",      Monday: "Понеділок",
      Tuesday: "Вівторок",
      Wednesday: "Середа",
      Thursday: "Четвер",
      Friday: "П’ятниця",
      Saturday: "Субота",
      Sunday: "Неділя",
      datetimeDisplayMode: "Тип вибору",
      datetimeDisplayModeSelect: "Оберіть тип",
      datetimeDisplayModeDate: "Дата",
      datetimeDisplayModeTime: "Час",
      datetimeDisplayModeDatetime: "Дата і час",
      datetimeMinDate: "Мін. дата",
      datetimeMinDateCustom: "Вкажіть мін. дату",
      datetimeMaxDate: "Макс. дата",
      datetimeMaxDateCustom: "Вкажіть макс. дату",
      datetimeProhibitDaysOfWeek: "Обмежити дні",
      datetimeIsRange: "Діапазон",
      datetimeIsRangeTrue: "Діапазон увімкнено",
      datetimeIsRangeFalse: "Діапазон вимкнено",
      datetimeMinTime: "Мін. час",
      datetimeMaxTime: "Макс. час",
      datetimeIs24: "Формат 24 години",
      datetimeIs24True: "Формат 24 год увімкнено",
      datetimeIs24False: "Формат 24 год вимкнено",
      csanyIsExactDate: "Як ви бажаете налаштовувати дату",
      csanyIsExactDateTrue: "Точна дата",
      csanyIsExactDateFalse: "Відносна дата",
      csanyDatetimeMinMaxNotExactPlaceholder: '"x" або "-x" днів від сьогодні',
      addNewSectionDatetime: "Дата/час",

    },
    EN: {
      madeInUkr: "made in Ukraine",
      upProVersion: "Switch to PRO",
      getLeads: "Get Leads",
      poperBillings: "Billings",
      poperTemplates: "Templates",
      poperPartnership: "Partnership",
      poperProfile: "Profile",
      poperLogout: "Log Out",
      defaultNewQuestion: "New Question",
      defaultNewQuestionDescription: "Here you can insert a short description of the question (up to 80 characters)",
      defaultNewVariant: "New Option",
      defaultBtnText: "Start",
      navBillings: "Billings",
      navPartnership: "Partnership",
      navProfile: "Profile",
      welcomeBack: "Welcome Back!",
      loginHeader: "Sign In",
      loginHeader2Part: "to Continue",
      loginEmailPlaceholder: "E-mail",
      loginPasswordlPlaceholder: "○ ○ ○ ○ ○ ○ ○ ○ ○",
      loginBtnValue: "Sign In",
      loginWith: "Login with",
      passwordAreForgetPassword: "Forgot your password?",
      passwordResetPasswordHeader: "Password reset",
      passwordResetPassword: "Reset password",
      passwordWeSentReset: "Follow the instructions in the email we send you to reset your password",
      passwordInputNewPassword: "Enter a new password",
      passwordConfirmPassword: "Confirm password",
      passwordChangePassword: "Save password",
      passwordResetedPasswordCompleted: "Your password has been successfully changed!",
      passwordGoToWorkboard: "Go to Workboard",
      loginSignup: "Sign up",
      helloHeader: "Hello!",
      signupHeader: "Sign up",
      signupHeader2Part: "to Continue",
      signupNamePlaceholder: "Oleksander",
      signupPhonePlaceholder: "phone number",
      signupEmailPlaceholder: "E-mail",
      signupPasswordlPlaceholder: "○ ○ ○ ○ ○ ○ ○ ○ ○",
      signupBtnValue: "Create an account",
      signupWith: "Sign up with",
      signupLogin: "Login to an account",
      signupTariffHeader: "Tarif",
      signupTariffFree: "Free.",
      signupTariffPro: "Pro.",
      signupTariffFreeDescription: "Unlimited number of creation of new quizzes (web pages). Ability to generate up to 300 applications per month for free.",
      signupTariffProPrice: "$20/month",
      signupTariffProDescription: "Unlimited number of creation of new quizzes (web pages). Ability to generate ",
      signupTariffProDescriptionBold: "up to 300 applications per month.",
      userBackToQuiz: "Back to Workboard",
      userBackToSettings: "Back to Settings",
      billingYourTariff: "Your plan",
      billingYourPro: "Pro",
      billingYorFree: "Free",
      billingFreeLeads: "Free leads",
      billingInfinity: "unlimited",
      billingTariffRenewData: "Tariff renew date",
      billingTariffEndData: "End date of the plan",
      billingOtherTariffFree: "Free.",
      billingOtherTariffPro: "Pro.",
      billingTariffFreeDescription: "Unlimited number of creation of new quizzes (web pages). Ability to generate up to 300 applications per month for free.",
      billingYouHavePro: "If you're currently on the Pro version, why take a step back?",
      billingYouHasFreeLeads: "Free leads",
      billingYouHasLeads: "Leads left",
      billingTariffProDescription: "Unlimited number of new quizzes (web pages). Ability to generate ",
      billingTariffProDescriptionBold: "more than 300 applications.",
      billingYourFutureTariff: "Your future plan",
      billingBtnChangeToPro: "switch to ",
      billingBtnRenewPro: "Renew",
      billingBillings: "Billings",
      billingYouHaveNotBillings: "You have no payments yet",
      billingPaymentsMethod: "Payment method",
      billingPaymentCard: "Card",
      billingChangeData: "Change data",
      billingDeletePayMethod: "Delete",
      billingModalDeleteShure: "Are you sure you want to remove this payment method?",
      billingModalDeleteCancel: "Cancel",
      billingModalDeleteOk: "Delete",
      billingModalDeleteSuccess: "You have successfully deleted your card",
      billingModalRegularPaymentDeleteSuccess: "Regular payments are cancelled",
      billingModalRegularPaymentDeleteOk: "ОК",
      billingYourMonthPlan: "Your monthly tarif",
      billingLeads: "leads",
      billingChangePlan: "Change the tarif",
      billingChangePlanDescription: "The price will correspond to the desired number of applications from all quizzes in your account.",
      billingSelectPeriod: "Select period",
      billingChangePlanDescriptionImportant: "Important:",
      billingChangePlanDescriptionImportantMonth: "Leads can be used 1 month after purchase.",
      subscriptionTarifLable: "Subscribe for our automatic monthly subscription. You can change your plan at any time.",
      billingChangePlanWarning: "",
      billingTariffMonth: "mon.",
      billingChangePlanPay: "Change the Plan",
      profileName: "Name",
      profilePhone: "Phone number",
      profileEmail: "E-mail",
      profileStatus: "Status",
      profileStatusAgency: "Agency",
      profileStatusPlaceholder: "What described you better?",
      profileStatusFreelancer: "Freelancer",
      profileStatusBuisines: "Business owner",
      profilePassword: "Пароль",
      profileChangePassword: "Change password",
      profileNewPassword: "New password",
      profileSaveNewData: "Update data",
      workboardAddNewQuiz: "Create Project",
      workboardModalGiveQuizName: "Give a name to the quiz",
      workboardModalQuizNamePlaceholder: "New quiz",
      workboardModalNewQuizBtnValue: "add",
      workboardAlertGiveAnyName: "Enter any name",
      workboardAlertNextTimeAddQuiz: "Well, next time, dare)",
      workboardChangeQuizName: "Change the name",
      workboardQuizLink: "link",
      workboardQuizLinkCopied: "The link is copied to the clipboard",
      workboardEditQuiz: "Edit",
      workboardCopyQuiz: "Copy the quiz",
      workboardQuizCopied: "Quiz successfully copied",
      workboardDeleteQuiz: "Delete",
      workboardModalDeleteQuizIrreversible: "This action is irreversible!",
      workboardModalDeleteQuizSure: "Are you sure you want to remove this quiz?",
      workboardModalDeleteQuizCancel: "Cancel",
      workboardModalDeleteQuizDelete: "Delete",
      workboardModalDeleteQuizSuccess: "You have successfully deleted this quiz",
      workboardModalQuizDeleteOk: "ОК",
      workboardStatsTitle: "Statistics",
      workboardModalLeadsAnswersCount: "Answers to questions",
      workboardModalLeadsLeadSerial: "№",
      workboardModalLeadsLeadDate: "Date",
      workboardModalLeadsLeadEmail: "Email",
      workboardModalLeadsLeadName: "Name",
      workboardModalLeadsLeadPhone: "Phone",
      workboardModalLeadsLeadActions: "Actions",
      workboardModalLeadsLeadShowMore: "See more",
      workboardModalLeadsLeadHideMore: "Hide",
      workboardModalLeadsLeadAnswerScipped: "Skipped",
      workboardModalNoLeads: "Пока лидов нет",
      constructorLink: "link",
      constructorLinkCopied: "Copied to clipboard:",
      constructorNavSteps: "Steps",
      constructorNavDesign: "Design",
      constructorNavSettings: "Settings",
      constructorStep: "Step",
      cancel: "Cancel",
      constructorCancel: "Cancel",
      constructorSave: "Save",
      constructorextraNavStart: "S",
      constructorextraNavContact: "C",
      constructorextraNavFinish: "F",
      cstepsSection: "Sections",
      cstepsStartPage: "Start page",
      cstepsContactPage: "Contact form",
      cstepsFinishPage: "Final page",
      cstepsEdit: "Edit",
      cstepsMove: "Move",
      cstepsDeleteStep: "Delete step",
      cstepsAddSection: "Add section",
      cdesignColorTheme: "Color Theme",
      cdesignCustomTheme: "Custom Color Theme",
      cdesignDefaultThemes: "Our Color Themes",
      cdesignThemeStandart: "Default",
      cdesignThemeDark: "Dark",
      cdesignThemeNud: "Nude",
      cdesignThemeGradient: "Gradient",
      cdesignShapeDesign: "Design form",
      cdesignShapeRound: "Rounded",
      cdesignShapeSquare: "Angular",
      csettingsIntegration: "Integration",
      csettingsOweDomain: "Connecting own domain",
      csettingsDocs: "Legal documents",
      cintegrationIntegration: "Integration",
      cintegrationShareLeads: "Share Leads",
      cintegrationTitleEmail: "E-MAIL",
      cintegrationInstruction: "Instruction",
      cintegrationInstructionDetail: "Details",
      cintegrationEmailInstruction: "Trigger an email to your respondent and notify your team. Collaborate with your team.",
      cintegrationPublishAfterEdit: "Save the quiz after editing",
      cintegrationSendNewLeadsTo: "Send messages about new leads to:",
      cintegrationInputEmail: "Input Email",
      cintegrationAddEmail: "Add Email",
      cintegrationEmailSaved: "Email Saved",
      cintegrationEmailDeleted: "Email Deleted",
      cintegrationTitleFB: "FB pixel",
      cintegrationIDPixel: "Pixel ID",
      cintegrationFBInstruction1: "Go to the Facebook Ads advertising cabinet and copy the Facebook Pixel ID in the Event Manager section.",
      cintegrationFBInstruction2: "Don't copy the whole code, just the 15-16 digit ID.",
      cintegrationFBInstruction3: "Copy and paste into the box below.",
      cintegrationFBInstruction: 'The event for which you need to configure the conversion is "Lead". This event will be called as soon as the user submits an application in the quiz.',
      cintegrationInputIDPixel: "Input Pixel ID",
      cintegrationDataFBSaved: "Pixel FB data saved",
      cintegrationAccesToken: "ACCES TOKEN",
      cintegrationTitleGA: "Google analytics",
      cintegrationGAInstructionDescription: "Learn how people are finding and interacting with your quiz. Get the data you need to analyze campaigns, improve conversions, and more.",
      cintegrationGAInstruction: 'When a quiz application is received in Google Analytics, the "generate_lead" event will be triggered.',
      cintegrationIDFolow: "Tracking ID",
      cintegrationInputID: "Input ID",
      cintegrationDataGASaved: "Google analytics data saved",
      cintegrationTitleGTM: "Google Tag Manager",
      cintegrationGTMInstructionDescription: "Description for Google Tag Manager.",
      cintegrationGTMInstruction: "Copy the Google Tag Manager id and paste it into the field below.",
      cintegrationDataGTMSaved: "Google Tag Manager data saved",
      cintegrationTitleTG: "Telegram",
      cintegrationHowReciveTGLeadBot: "How to get leads in Telegram?",
      cintegrationTGInstructionBot1: "1. Copy the token in the input below.",
      cintegrationTGInstructionBot2: '2. Follow the link to the bot, click the "START" button.',
      cintegrationTGInstructionBot3: "3. Send the copied token as a message to our bot.",
      cintegrationHowReciveTGLeadChat: "How to get applications in Telegram chat?",
      cintegrationTGInstructionChat1: "1. Add our bot to the desired chat and set the bot to administrator status.",
      cintegrationTGInstructionChat2: "2. Copy the token in the field below and send it to the desired chat.",
      cintegrationTGInstructionChat3: "3. Wait for the answer `OK! Your quiz token was saved!”",
      cintegrationTGInstructionChat4: "4. Leads will now be sent to this chat.",
      cintegrationTGInstructionWarning: "Importantly:",
      cintegrationTGInstructionWarningText: "Please note that this should be a group, not a Telegram channel. Leads will not be sent to the Telegram channel.",
      cintegrationTGBotLink: "chatbot link",
      cintegrationTGTitleToken: "TOKEN",
      cintegrationInputTGToken: "Enter Telegram chatbot token",
      cintegrationAddIntegration: "Add integration",
      cintegrationDataTGSaved: "Telegram integration data saved",
      cintegrationTitleTT: "TT pixel",
      cintegrationTTInstruction: "To track events in your Tik-Tok ad account, copy the pixel ID and paste it into the box below. ",
      cintegrationDataTTSaved: "Pixel TT data saved",
      cintegrationTitleWH: "WebHook",
      cintegrationWHInstruction1: "You can notify third-party applications about incoming leads. To do this, specify the URL to which the WebHook will be sent.",
      cintegrationWHInstruction2: "We have prepared",
      cintegrationWHInstruction3: "video instruction",
      cintegrationWHInstruction4: "with a detailed description of this functionality.",
      cintegrationWHInstruction5: "View video.",
      cintegrationWHInstruction6: "In this video, we will look at passing a hook based on sending data to a Google spreadsheet and sending data to a Trello CRM system.",
      cintegrationWHInstruction7: "We recommend using the service for this purpose.",
      cintegrationWHInstruction8: "When registering using this link, for our users, there is a bonus - 10,000 free actions for any integrations.",
      cintegrationWHInstruction9: "Link",
      cintegrationWHInstruction10: "to register with a bonus.",
      cintegrationWHUrl: "The webhook URL's",
      cintegrationInputWHUrl: "Input the webhook URL",
      cintegrationAddWH: "Add a Web-hook",
      cintegrationWebHookDataSaved: "WebHook data saved",


      cintegrationComonWHInstructionShort: "You can notify third-party applications about incoming leads. Specify the webhook URL for data transmission.",
      cintegrationComonWHInstruction1Title: "1. Insert the webhook URL into the input field:",
      cintegrationComonWHInstruction1Description: "Paste the webhook URL provided by your service or application into the input field.",
      cintegrationComonWHInstruction2Title: "2. Save changes:",
      cintegrationComonWHInstruction2Description: 'Click the "Save Changes" button to save the webhook settings.',
      cintegrationComonWHInstruction3Title: "3. Done:",
      cintegrationComonWHInstruction3Description: "Now your webhook is configured to send data to the service you have selected.",
      cintegrationComonWHInstructionAdditional: "Data will be sent to the webhook with each lead received. POST method is used for transmission. An example of the data that will be sent is located ",
      cintegrationComonWHInstructionAdditionalLink: "here",
      cintegrationComonWHInstructionVideo: "Detailed video instruction",
      
      cintegrationApixDriveWHInstructionShort: "You can notify ApixDrive about incoming leads. To do this, specify the URL to which the WebHook will be sent.",
      cintegrationApixDriveWHInstructionShort1: "Upon registration through ",
      cintegrationApixDriveWHInstructionShort2: "this link",
      cintegrationApixDriveWHInstructionShort3: " our users receive a bonus - 10,000 free actions for any integrations.",
      cintegrationApixDriveWHInstruction1Title: "1. Register or open your ApixDrive account:",
      cintegrationApixDriveWHInstruction1Description1: "Link ",
      cintegrationApixDriveWHInstruction1Description2: "for registration with a bonus.",
      cintegrationApixDriveWHInstruction2Title: "2. Get the URL address for the webhook:",
      cintegrationApixDriveWHInstruction2Description1: "Go to the settings of the necessary connection and copy the address for sending the webhook:",
      cintegrationApixDriveWHInstruction2Description2: 'You can create a new connection by clicking the "Create Connection" button and at the system selection step, choose "ADSQuiz".',
      cintegrationApixDriveWHInstruction3Title: "3. Insert the webhook URL:",
      cintegrationApixDriveWHInstruction3Description: "Paste the webhook URL provided by ApixDrive into the input field.",
      cintegrationApixDriveWHInstruction4Title: "4. Save changes:",
      cintegrationApixDriveWHInstruction4Description: 'Click the "Save Changes" button to save the webhook settings.',
      cintegrationApixDriveWHInstruction5Title: "5. Done:",
      cintegrationApixDriveWHInstruction5Description: "Now your webhook is configured to send data to ApixDrive.",
      cintegrationApixDriveWHInstructionAdditional: "Data will be sent to the webhook with each lead received. POST method is used for transmission. An example of the data that will be sent is located ",
      cintegrationApixDriveWHInstructionAdditionalLink: "here",
      cintegrationApixDriveWHInstructionVideo: "Detailed video instruction",
      



      cintegrationConectOweDomain: "Connecting your own domain",
      cintegrationOweDomainInstruction1: "1. To place your quiz on the required domain, log into your hosting account. Go to DNS settings and create an A record with the IP parameter: 185.25.117.21",
      cintegrationOweDomainInstruction2: "2. Input your domain above and save the quiz.",
      cintegrationDomain: "Domain",
      cintegrationInputYourDomain: "Input your domain",
      cintegrationCheckDomenIsCorrect: "Check that the entered domain is correct",
      cintegrationDomainWhichCorrect: "Enter a domain without protocols. For example: some.site.com",
      cintegrationInsertedDefaultDomain: "The domain is set by default",
      cintegrationDomainDataSaved: "Domain data saved",
      cintegrationPrivacyPolicy: "Privacy policy",
      cintegrationPrivacyPolicyByDefault: "By default, we use our standard privacy policy.  We recommend adding your privacy policy that is relevant to your business.",
      cintegrationPrivacyPolicyAdventage: "This will make it clear to your potential customers how you use the received contact information.  This is also required by some advertising platforms.",
      cintegrationPrivacyPolicyUrl: "Link to documents",
      cintegrationPrivacyPolicyInputYourDocsLink: "Insert link to documents",
      cintegrationDocsDataSaved: "Link to your documents saved",
      allstepsUpploadPhoto: "Upload a image",
      allstepsChangePhoto: "Change the image",
      allstepsDeletePhoto: "Delete image",
      allstepsTitle: "Title",
      allstepsDescription: "Description",
      allstepsButtonText: "Button text",
      allstepsOtherSettings: "Other settings",
      allstepsIsSaveBeforeRoute: "Save data before changing route?",
      cfirstFormat: "Format",
      cfirstHeaderStartText: "Your headline should be here. Motivate users to complete the quiz to the end",
      cfirstAddMediafile: "Add media (image)",
      cfirstBonus: "Bonus",
      cfirstAddBonus: "Add bonus",
      cfirstStartPageDataUpdated: "The start page data has been updated",
      csanyAnswerType: "Question type",
      csanyATCard: "card",
      csanyATList: "list",
      csanyATCustom: "free field",
      csanyQuestion: "Question",
      csanyAnswers: "Answers",
      csanyAddAnswer: "Add option",
      csanyRequiredStep: "Required step",
      csanyAlowMultiple: "Allow multiple answers",
      csanyAutoRedirect: "Auto next question",
      csanyQuestionLogic: "Question logic setup",
      csanyDataStepUpdated: "Data Step Updated",
      cformForm: "Form",
      cformValueName: "Name",
      cformValueSurname: "Surname",
      cformValuePhone: "Phone number",
      cformValueEmail: "E-mail",
      cformDataContactsPageUpdated: "Data Contacts Page Updated",
      cformRequiredInput: "Mandatory",
      cfinishFinishPageTitle: "Finish Page",
      cfinishText: "Text",
      cfinishDescription: "Description",
      cfinishAddImage: "Add an image",
      cfinishImageAdded: "Image Added",
      cfinishURL: "URL",
      cfinishButtonText: "Button Text",
      cfinishInsertNikname: "Insert Nikname",
      cfinishCheckDataIsCorrect: "Check that the entered id is correct",
      cfinishFbWhichCorrect: "Enter a id without oter adress. For example: some.name",
      cfinishIgWhichCorrect: "Enter a id without oter adress. For example: some.name",
      cfinishTgWhichCorrect: "Enter a id without oter adress. For example: some.name",
      cfinishFinishPageDataUpdated: "The finish page data has been updated",
      adminUsersReg: "Users in the system",
      adminNewUsers: "New users per month",
      adminProAccounts: "PRO accounts",
      adminLTV: "LTV",
      adminSharePro: "PRO relative to all",
      adminReciveLeads: "Leads received",
      adminLeadsForOne: "Leads Per user",
      adminMadeQuiz: "Made Quizzes",
      adminQuizForOne: "Quiz Per user",
      adminAvarageUsed: "Average usage months",
      adminProfitMonth: "Profit Month",
      adminProfitAlltime: "Earned for all time",
      adminUsersList: "User base",
      notificationErrorLogin: "Authorization error:",
      notificationLogin: "Login...",
      notificationWrongLoginData: "Incorrect login details",
      notificationNetworkError: "Check your internet connection",
      notificationInputLoginData: "Input login data",
      notificationCheckInputEmail: "Verify that your email address is correct",
      notificationCheckInputPhone: "Check that the phone number is correct",
      notificationInputAllInputs: "Fill in all fields for registration",
      notificationAgreePrivacyPolicy: "Agree to the privacy policy",
      adminEditorForPartnership: "Affiliate program editor",
      developmentPage: "Section is under development",
      signupAgreeIAgreeWith: "I agree with",
      signupAgreePolicy: "the privacy policy",
      signupAgreeAnd: "and",
      signupAgreeOffer: "the public offer",
      signupAgreeService: "of the service",
      workboardModalLeadsLeadReferrer: "Unclear",
      workboardModalLeadsLeadReferrerClarification: "Referrer data blocked by source or undefined",
      workboardModalLeadsLeadEmailCopied: "Email copied to clipboard",
      workboardModalLeadsLeadPhoneCopied: "Phone copied to clipboard",
      workboardModalLeadsHaveNotSended: "You have the not-sended leads",
      workboardModalLeadsLeadReload: "Recive",
      workboardModalLeadsLeadReloadedTitle: "Request recived",
      workboardModalLeadsLeadReloaded: "",
      cfirstStartPagePhotoUpdated: "The start page photo has been updated",
      allstepsPhotoTooBig: "File is too big!",
      allstepsPhotoMustBeLess: "Must be less than",
      cfirstStartPageBonusPhotoUpdated: "The bonus photo has been updated",
      cstepsQuestionsOrderSaved: "Question order saved",
      csanyAnswerOrderSaved: "Answer order saved",
      allstepsPhotoAdaptation: "Photo adaptation for devices",
      allstepsPhotoFit: "Aspect ratio",
      constructorYouHideStep: "You are hidden this step",
      cstepsHiddenStep: "Hide Step",
      cstepsShowStep: "Show Step",
      cfinishFinishPageHiddenUpdated: "Фінішна сторінка прихована.",
      cfinishFinishPageHiddenUpdatedGoContact: "Перейдіть на сторінку Форми контактів",
      cfinishFinishPageHiddenUpdatedSetRedirect: "та налаштуйте редирект після відправки даних",
      cformActionAfterContactRecive: "Follow-up on Contact Information",
      cformActionAfterContactThankyou: "Thank you Page",
      cformActionAfterContactRedirect: "Redirect",
      cformAdditionalSettings: "Additional settings",
      cformRedirectURL: "URL",
      cintegrationOweDomainInstructionImportant: "Important: The address in your hosting account and the field above must match. The time of applying changes to DNS settings and extracting the SSL certificate is up to 3 hours after saving.",
      cintegrationOweDomainInstructionVideo: "Watch video instruction",
      workboardModalLeadsLeadLimit: "Your lead limit has been reached.",
      workboardModalLeadsLeadLimitInstruction1: "To see new leads,",
      workboardModalLeadsLeadLimitInstruction2: "switch to a another tariff",
      workboardModalLeadsLeadLimitInstruction3: "renew the current tariff",
      workboardModalLeadsLeadLimitInstruction4: '(does not apply to "Free") or wait for the tariff to be updated if you have an auto-renewal of the tariff connected',
      billingBonusesTitle: 'Promo code',
      billingBonusesDescription: 'Enter the promo code you received in the next field',
      billingBonusesPlaceholder: 'Promo code',
      billingBonusesBtn: 'Activate',
      billingBonusesActivated: 'Promo code activated!',
      billingBonusesLeadIncreased: 'The number of available leads has been increased.',
      billingBonusesInvalid: 'Wrong promo code',
      billingBonusesNotAvailable: 'Promo code is no longer available',
      billingBonusesAlreadyActivated: 'The bonuses of this promo code have already been granted',
      createQuizSPDescription: "There is place for the short description",
      createQuizCPTitle: "How can we contact with you?",
      createQuizCPDescription: "Please fill the form below",
      createQuizCPButton: "Done!",
      createQuizFPTitle: "Thank you!",
      createQuizFPDescription: "Now we looking for a best solution for you!",
      createQuizFPButton: "Show me something!",
      selectInternationalPrefix: "Select international prefix",
      autoInternationalPrefix: "Determine the country automatically",
      dovnloadCsvTitle: "Export data for the selected period",
      dovnloadCsvSelectAllPeriod: "Choose for the entire period",
      dovnloadCsvCancel: "Cancel",
      dovnloadCsvExport: "Export",
      
      
      
      
      
      widgetEmbedVidgetToSite: "Embed quiz to the own website",
      widgetEmbedVidgetSelectEmbedVariant: "Select embed variant",
      widgetEmbedVidgetSitecode: "In the site code",
      widgetEmbedVidgetPopup: "Pop-up",
      widgetEmbedVidgetChat: "Quiz-button",
      widgetEmbedVidgetInProgres: "Under development",
      widgetEmbedVidgetToSiteCode: "Embed quiz to the website",
      widgetSiteCodeInstruction0: "To embed the quiz on your site, add the code from the first field to the `<head>` tag on your site or in Google Tag Manager if it is integrated into your site. If after you have already embeded the quiz on your site you change the domain of the quiz you will need to update the integration code on your site.",
      widgetSiteCodeInstruction1: "In the place where you want to integrate the quiz, insert the code from the second field.",
      widgetSiteCodeInstruction2: "The quiz can be integrated into a container of any size, even into sidebars! The maximum size of the quiz, as well as when taking it on a separate domain, is 900x552 pixels. It can be added to larger containers, in which case the quiz will be centered.",
      widgetSiteCodeInstruction3: "Do not be shy - experiment!",
      widgetSiteCodeCopyToHead: "Copy and paste into the `<head>` of your site",
      widgetSiteCodeCopyToBody: "Paste the code where the quiz should be",
      workboardModalAddQuizHeader: "Create new Quiz",
      workboardModalGiveQuizNameAndMethod: "Come up with a name for your quiz and choose the way you want to create it",
      workboardModalNewQuizFromStart: "Create from blank",
      workboardModalNewQuizFromTemplates: "Ready templates",
      QuizTemplatesSearchInput: "Name of the template and/or categories",
      QuizTemplatesSearchBtn: "Search",
      QuizTemplatesReadyTemplates: "Ready templates",
      QuizTemplateView: "View",
      QuizTemplateSelect: "Choose",
      widgetPopupInstruction0: "To install a quiz on your site, add the code from the code box to the `<head>` tag on your site or in Google Tag Manager if it is integrated into your site. If, after you have already inserted a quiz on your site, you change the quiz domain or open triggers (scroll percentage or timing), you will need to update the integration code on your site.",
      widgetPopupInstruction1: "Just before the closing `</footer>` tag, paste the code from the second field.",
      widgetPopupInstruction2: "For programmers or advanced users",
      widgetPopupInstruction3: "If, for some reason, you are uncomfortable inserting a quiz before the closing `</footer>` tag, you can paste the code from the second field in any convenient place, but keep in mind that the container in which you paste the code from the second field must be on top of all windows and highly desirable, should have the maximum height and width possible for the display.",
      widgetPopupCopyToHead: "Copy and paste into the `<head>` of your site",
      widgetPopupCopyToBody: "Paste the code before the closing `</footer>` tag",
      widgetPopupTimeToOpen: "A period of time",
      secondReduction: "sec",
      widgetPopupTimeClarification: "The popup will appear with a delay of the above time in seconds",
      widgetPopupScrollToOpen: "Site scrolling percentage",
      widgetPopupScrollClarification: "The popup will appear after the scrolling percentage of your site specified above",
      quizzesTemplatesAllTags: "All Templates",
      widgetEmbedVidgetButton: "Button constructor",
      widgetButtonInstruction0: "You can set a quiz on your website that will open when you click on the button.",
      widgetButtonInstruction1: "Add a border, shadow, and text to the button. Also, you can change the color of the button and the text inside.",
      widgetButtonInstruction2: "Copy the script placed in the first code box and paste it before the closing tag `</head>`.",
      widgetButtonInstruction3: "Copy the generated button code from the second code field and paste it anywhere on your site.",
      widgetButtonInstruction4: 'You can skip the code from the second button field and simply add `id="button_open_quiz_popup"` to any `<input type="button">` or `<button></button>` element on the site.',
      widgetRealTimeChange: "You can see the changes in real time on the right side of the screen",
      widgetButtonCornerRound: "Button corner rounding",
      widgetButtonShadow: "Button shadow",
      widgetButtonBgColor: "Button color",
      widgetButtonTextColor: "Text color",
      widgetButtonTextValue: "Button text",
      openPopupQuizText: "Start Quiz",
      widgetButtonInsertBeforeAfterBody: "Insert the quiz code immediately after the opening `<body>` tag or immediately before the closing `</body>` tag",
      widgetSiteCodeCopyButton: "Paste the button code anywhere on the site",
      footerContactUs: "Have questions? Feel free to contact us:",
      enterYourName: "Enter your name",
      enterYourPhone: "Enter your phone number",
      enterYourEmail: "Enter your email",
      enterYourPassword: "Enter your password",
      selectOption: "Select option",
      createNewGroup: "Create new group",
      dontHaveAccount: "Don’t have an account?",
      resetPasswordTip: "If you have any further issues or questions, please don't hesitate to contact our customer support team. We're here to help.",
      getBackAccount: "Get Back to Your Account",
      inMinutes: "in Minutes",
      alreadyHaveAccount: "Already have an account?",
      quizSlotLastEdit: "Last edit",
      workboardQuizzesTitle: "My Quizzes",
      allstepsBack: "Back",
      constructorGetLink: "Share Quiz",
      allOr: "or",
      allAnd: "and",
      acceptedFileType: "Accepted formats: ",
      conjunctionAnd: "and",
      acceptedFileSize: "Maximum size: ",
      acceptedFileSizeMeasure: "MB",
      cfirstBonusDisabled: "Disabled",
      cfirstBonusAvailable: "Available",
      allstepsResetInHeader: "Reset",
      placeholderText: "Type here",
      constructorSaveChanges: "Save Changes",
      cformInputAvailable: "Available",
      cformInputDisabled: "Disabled",
      constructorBack: "Back",
      videoInstruction: "Video Instruction",
      csettingsIntegrationFB: "Integration - Facebook Pixel",
      csettingsIntegrationTT: "Integration - TikTok",
      csettingsIntegrationGA: "Integration - Google Analytics",
      csettingsIntegrationGTM: "Integration - Google Tag Manager",
      csettingsIntegrationWH: "Integration - WebHook",
      csettingsIntegrationTG: "Integration - Telegram",
      csettingsIntegrationApixDrive: "Integration - APIxDrive",
      csettingsIntegrationEmail: "Integration - Email",
      csettingsIntegrationAbout: "About",
      csettingsIntegrationFBAbout: "Go to the Facebook Ads advertising cabinet and copy the Facebook Pixel ID in the Event Manager section.Don't copy the whole code, just the 15-16 digit ID Copy and paste into the box below.",
      csettingsIntegrationTTAbout: "To track events in your Tik-Tok ad account, copy the pixel ID and paste it into the box below.",
      csettingsIntegrationGAAbout: "Copy the Google Analytics id and paste it into the field below.",
      csettingsIntegrationGTMAbout: "Copy the Google Tag Manager id and paste it into the field below.",
      csettingsIntegrationWHAbout1: "You can notify third-party applications about incoming leads. To do this, specify the URL to which the WebHook will be sent.We recommend using the service for this purpose.",
      csettingsIntegrationWHAboutApix: "Apix-Drive",
      csettingsIntegrationWHAbout2: "When registering using this link, for our users, there is a bonus - 10,000 free actions for any integrations.",
      csettingsIntegrationTGAbout: "Importantly: Please note that this should be a group, not a Telegram channel. Leads will not be sent to the Telegram channel.",
      csettingsIntegrationADAbout: "Something about APIxDrive",
      csettingsIntegrationEmailAbout: "Trigger an email to your respondent and notify your team. Collaborate with your team.",
      cSettingsIntegrationNeedHelp: "Need Help?",
      cSettingsIntegrationRefreshToken: "Refresh Token",
      csettingsSettingASettings: "Advanced settings",
      cSettingsSettingQuizLangTitle: "Quiz Language",
      cSettingsSettingQuizLangDescription: "To ensure you have the best experience, please select your desired quiz language from the options below. System notifications and some other constant texts will be displayed in this language when passing the quiz.",
      selectQuizLanguage: "Select Quiz language",
      cSettingsSettingQuizHideLabelTitle: "Hide label",
      cSettingsSettingQuizHideLabelDescription: 'By enabling this option, you can enjoy an interface free of the "made by adsquiz" label, allowing you and your visitors to focus solely on the questions.',
      cSettingDataSaved: "Additional settings are saved",
      userAccountInfo: "Account info",
      userGeneralInfoHeader: "General info",
      userGeneralInfoDescription1: "Your user profile is where you can manage your account and settings.",
      userGeneralInfoDescription2: "Update your personal information, change your password, and adjust your notification preferences.",
      profileNameFirst: "First Name",
      profileNameLast: "Last Name",
      userSecuritySettingsHeader: "Security settings",
      userSecuritySettingsDescription: "To change your password, simply enter your new password twice to confirm.",
      profileEmailCurent: "Current Email Address",
      profileEmailNew: "New Email Address",
      profileNewPasswordConfirm: "Confirm New Password",
      profileDeleteAccount: "Delete account",
      profileDeleteAccountPopupHeader: "Delete Account?",
      profileDeleteAccountPopupDescription: 'Are you sure you want to delete your account? This action cannot be undone. To confirm deletion, please type the word "DELETE" in the field below.',
      profileDeleteAccountCheckedWord: "DELETE",
      profileDeleteAccountPopupPlaceholder: "Type here",
      notificationSuccess: "Success!",
      notificationSuccessSaveAccount: "Your password has been successfully changed! Use a new password to log in again",
      notificationSuccessDeleteAccount: "Your password has been successfully changed! Use a new password to log in again",
      cardPanUnspecified: "Unspecified",
      userBillingsDate: "Date",
      userBillingsCost: "Cost",
      userBillingsId: "ID",
      userBillingsMethod: "Payment Method",
      paymentSwitchToProHeader: "Switch to Pro",
      paymentSwitchToProDescription: "Find the plan that's right for you! Upgrade or downgrade at any time - we make it easy to get the plan you need.",
      paymentYourCurrentPlan: "Your current plan:",
      paymentYourFuturePlan: "Your new plan:",
      paymentPlanLeadsLimit: "Limit of leads per month:",
      paymentPlanLeadsLimitForTest: "Your quiz test limit:",
      paymentPlanLeadsLeft: "Leads left:",
      paymentPlanPrice: "Price:",
      paymentPlanRenew: "Tarif renew date:",
      addNewSectionHeader: "Add New Section",
      addNewSectionList: "List",
      addNewSectionCustom: "Input field",
      addNewSectionCard: "Card",
      addNewSectionWithEmoji: "List with emoji",
      addNewSectionTags: "Tags",
      addNewSectionSlider: "Slider",
      templatesBackToQuiz: "Back to Workboard",
      constructorStepsSaved: "All quiz data has been successfully saved",
      constructorDesignSaved: "The quiz design has been successfully saved",
      azureBlueWhite: "Standard",
      azureBlue: "Winter night",
      moderatePurplishRed: "Red velvet",
      violetBlueCrayola: "Winter breeze",
      wetRainforest: "Summer maple",
      lavender: "Space",
      blueLilac: "Dark lavender",
      opalGreen: "Carpathian forest",
      dustyBlue: "Tiffany",
      almondCrayola: "Coral reef",
      periwinkleCrayolaLilac: "Lilac",
      periwinkleCrayolaAqua: "Synevir",
      jazzJam: "Jazz",
      slateGrayBlue: "Mist",
      turquoiseBlueCrayola: "Diia",
      blackPearlescentOpal: "Ash",
      pearlescentGentianBlue: "Sapphire",
      sapphireBlue: "Dark sapphire",
      errorUserDataUpdate: "Error in updated data: ",
      completeUserDataUpdate: "User data updated",
      passwordDoNotCoincidence: "Passwords do not match",
      passwordDoNotCoincidenceAgain: "Try again",
      nonBillingsYet: "You don't have any billing history yet. ",
      youCanChangePlan: "You can change current plan by clicking “Switch to PRO” button",
      rangeMin: "Min",
      rangeMax: "Max",
      rangePositionStart: "Position Start",
      rangePositionEnd: "Position End",
      rangeStep: "Step",
      rangeIsRange: "Range",
      rangeIsRangeTrue: "Available",
      rangeIsRangeFalse: "Disable",
      recivePromotionNewsletter: "Receive email newsletters",
      reciveEmailCheckbox: "Receive Email Checkbox",
      additionalCheckbox: "Additional Checkbox",
      contactPrivacyPolicyCheckbox: "Contact Privacy Policy Checkbox",
      contactAgreeIAgreeWith: "I agree with",
      contactAgreePolicy: "the privacy policy",
      contactAgreeAnd: "and",
      contactAgreeTermsOfUse: "the terms of use",
      contactAgreeAgreementAdditionalWord: "",
      langDE: "German",
      langEN: "English",
      langIT: "Italian",
      langKK: "Kazakh",
      langPL: "Polish",
      langRU: "Russian",
      langSP: "Spanish",
      langUA: "Ukrainian",
      langLT: "Lithuanian",
      langLV: "Latvian",
      langET: "Estonian",
      langRO: "Romanian",
      leadsLeft: "Leads left",
      generateDomainTitle: "Enter the domain name",
      generateDomainDescription: "This is the unique address where your page will be accessible. You can change the address at any time or connect your own one.",
      generateDomainDomainNameLabel: "Domain name",
      cancelAllAction: "Cancel",
      confirmAllAction: "Confirm",
      falseHasValidCharacters: "Invalid characters in the domain. Valid characters are letters, numbers and hyphens.",
      falseHasValidLength: "Domain length should be between 6 and 20 alphanumeric characters.",
      falseDoesNotStartOrEndWithSpecialChar: "Domain should not start or end with a special character.",
      falseDoesNotHaveConsecutiveSpecialChar: "Domain should not contain two or more consecutive special characters.",
      falseDoesNotHaveConsecutiveSameChar: "Domain should not contain three or more consecutive identical characters.",
      falseDoesNotContainForbiddenSubstrings: "Domain should not contain forbidden substrings.",
      generateDomainTaken: "This domain is already taken. Try another one",
      temporarilyUnavailable: "Temporarily unavailable",
      csettingsAdmin: "Settings for admins",
      questionLogicSetupTitle: "Question logic setup",
      questionLogicSetupDescription: "Add conditions to help you tailor the survey to each respondent and collect more accurate data.",
      questionLogicConditionTitle: "Condition",
      questionLogicQuestion: "Question",
      questionLogicSetupQuestionDisableOption: "Select Question",
      questionLogicSetupAnswer: "Answer",
      questionLogicSetupLogicType: "Type",
      questionLogicSetupAnswerDisableOption: "Select type of condition",
      questionLogicSetupRangeBetween: "Between",
      questionLogicSetupRangeLess: "Less",
      questionLogicSetupRangeMore: "More",
      questionLogicSetupRangeEqualTo: "Equal to",
      questionLogicSetupRangeFrom: "From",
      questionLogicSetupRangeTo: "To",
      questionLogicSetupVariantAll: "All",
      questionLogicSetupVariantAny: "Any",
      questionLogicSetupVariantNot: "None",
      questionLogicSetupCustomEqual: "Equal",
      questionLogicSetupCustomContains: "Contains",
      questionLogicSetupCustomNotContains: "Not contains",
      questionLogicSetupRangeValue: "Value",
      questionLogicSetupExpectedAnswer: "Expected answers",
      questionLogicSetupPlaceholder: "Type here",
      questionLogicAllMandatory: "All of the above conditions are mandatory",
      questionLogicAddConditionBtn: "Add Condition",
      questionLogicNotAvailable: "Logic settings are not available for the first question",
      questionLogicSaved: "Step display logic settings are saved",
      billingPaymentInfoTitle: "Tariff Information",
      billingPaymentInfoChangeTarrif: "Change tariff",
      billingPaymentInfoDescription: "When you pay for any paid tariff, you receive a certain number of leads included in the package you have chosen. These leads are provided for a month. If you haven't used your leads within a month from the moment of payment, they will be canceled.",
      billingGeneralName: "Your Tariff",
      billingGeneralCost: "Cost",
      billingMonth: "month",
      billingGeneralExpired: "Valid until",
      billingGeneralLeadsLeft: "Leads left",
      billingGeneralAllTarifExpired: "Tariff expiration date",
      billingGeneralBonus: "Leads with a promo code",
      billingGeneralLeadsFromPastTarifs: "Leads from previous tariffs",
      billingAcordeonPaymentInfoTitle: "Payment information",
      billingAcordeonPaymentInfoFormat: "Format",
      billingAcordeonPaymentInfoSubscription: "Subscription",
      billingAcordeonPaymentInfoPay: "Payment",
      billingAcordeonPaymentInfoPayMethod: "Payment Method",
      billingAcordeonPaymentInfoCancelSubscription: "Cancel Subscription",
      billingAcordeonPromocode: "Promo Code",
      billingAcordeonPromocodeLabel: "Enter promo code",
      billingAcordeonPayHistory: "Transaction History",
      paymentAllTarifInactive: "tariff is inactive",
      subscriptionUnsubscribeModalTitle: "Unsubscribe",
      subscriptionUnsubscribeModalDescription: "Are you sure you want to unsubscribe? Leads accrued for the subscription will be available to you for a month from the last billing date.",
      subscriptionIsNotDeleteTitle: "Subscription Not cancelled",
      subscriptionIsNotDeleteDescription: "Please contact customer support",
      subscriptionCanceledTitle: "Subscription cancelled",
      modalSureUnsavedChangesTitle: "You have unsaved changes",
      modalSureUnsavedChangesDescription: "Before leaving the current page, please decide what to do with the unsaved changes",
      modalSureUnsavedChangesDontSave: "Don't Save",
      modalSureUnsavedChangesSave: "Save",
      telegramTokenCopied: "The quiz token has been copied to the clipboard",
      cSettingsSettingQuizBackButtonTitle: "Back button",
      cSettingsSettingQuizBackButtonDescription: "You can enable or disable the back button. This will provide an opportunity to change the previously given answer.",
      cSettingsCustomThemePrimaryColor: "Color of accents",
      cSettingsCustomThemeSecondaryColor: "Background color",
      cSettingsCustomThemeAdditionalColor: "Text color",
      cSettingsCustomThemeButtonTextColor: "Text in button",
      cSettingsCustomThemeModeDark: "Dark",
      cSettingsCustomThemeModeLight: "Light",
      allstepsPlaceholder: "Placeholder",
      allstepsCustomInput: "Custom input",
      allstepsCustomInputOn: "Available",
      allstepsCustomPlaceholder: "Other",
      trashcanTitle: "Trashcan",
      trashcanImportant: "Important!",
      trashcanDescription: "The quiz will be automatically deleted permanently 30 days after it is moved to the trashcan.",
      trashcanClearAll: "Clear all",
      quizSlotDeletedData: "Deleted at",
      workboardLeads: "Leads",
      workboardDuplicate: "Duplicate",
      leadsTable: "Leads List",
      exportInfo: "Export Leads",
      leadHide: "Hide",
      leadShowMore: "Show More",
      poperTrashcan: "Trashcan",
      restoreQuizSendedTitle: "A recovery task is running",
      restoreSuizSendedImportant: "Important",
      restoreQuizSendedDescription: "Depending on how many leads there were in your quiz, restoring the quiz may take from a few seconds to 2 minutes. After restoration, the quiz will appear on your main workboard.",
      ok: "Okay",
      month: "0 month | {n} month | {n} months",
      discount: "Discount up to",
      saveMoney: "Subscription for a year",
      upProVersionMobile: "To PRO",
      upProVersionDesktop: "Switch to PRO",




      csettingsIntegrationSendPulse: "Integration - Send Pulse",
      csettingsIntegrationMake: "Integration - Make",
      csettingsIntegrationZapier: "Integration - Zapier",
      csettingsIntegrationCRMBitrix: "Integration - CRM Bitrix",
      csettingsIntegrationPipeDrive: "Integration - Pipe Drive",
      csettingsIntegrationRemOnline: "Integration - Rem Online",
      csettingsIntegrationKeyCRM: "Integration - Key CRM",
            
      csettingsIntegrationZapierAbout: "Paste the data sending URL provided by Zapier into the input field.",
      csettingsIntegrationMakeAbout: "Paste the data sending URL provided by Make into the input field.",
      csettingsIntegrationCRMBitrixAbout: "Paste the data sending URL provided by CRM Bitrix into the input field.",
      csettingsIntegrationSendPulseAbout: "Paste the client ID, secret key, address book, and Send Pulse action into the respective input fields.",
      csettingsIntegrationKeyCRMAbout: "Paste the API key and PipelineID into the respective input fields.",
      csettingsIntegrationPipeDriveAbout: "Paste the token and company domain into the respective input fields.",
      csettingsIntegrationRemOnlineAbout: "Paste the Rem Online API key into the respective input field.",
      csettingsIntegrationActiveIntegration: "Active Integration",
      cintegrationIntegrationData: "Integration Data",
      cintegrationIntegrationUrl: "Integration URL",
      cintegrationWriteUrl: "Enter URL",
      cintegrationDataFBError: "Incorrect pixel identifier",
      cintegrationDataWebHookError: "Invalid webhook address",
      cintegrationZapierDataSaved: "Zapier integration data saved",
      cintegrationDataZapierError: "Invalid integration address",
      cintegrationAPIxDriveDataSaved: "APIxDrive integration data saved",
      cintegrationDataAPIxDriveError: "The string is not an APIxDrive integration address",
      cintegrationMakeDataSaved: "Make integration data saved",
      cintegrationDataMakeError: "Invalid integration address",
      cintegrationSendPulseDataSaved: "SendPulse integration data saved",
      cintegrationDataSendPulseError: "Invalid or empty integration data",
      cintegrationPipeDriveDataSaved: "PipeDrive integration data saved",
      cintegrationDataPipeDriveError: "Invalid or empty integration data",
      cintegrationRemOnlineDataSaved: "RemOnline integration data saved",
      cintegrationDataRemOnlineError: "Invalid or empty integration data",
      cintegrationKeyCRMDataSaved: "KeyCRM integration data saved",
      cintegrationDataKeyCRMError: "Invalid or empty integration data",
      cintegrationInputEmailUrl: "Enter Email",
      cintegrationEmailDataSaved: "Email saved",
      cintegrationDataEmailError: "The entered string is not an Email",
      cintegrationCRMBitrixDataSaved: "Email saved",
      cintegrationDataCRMBitrixError: "The entered string is not an Email",
      cintegrationTelegramBotDataSaved: "Telegram integration settings saved",
      cintegrationDataTelegramBotError: "Save error",
      cintegrationGTMDataSaved: "Google Tag Manager ID saved",
      cintegrationDataGTMError: "Invalid ID",
      cintegrationDataGTMErrorDescription: "The ID should have the format GTM-XXXXXX",
      cintegrationGoogleAnalyticsDataSaved: "Google Analytics ID saved",
      cintegrationDataGoogleAnalyticsError: "Incorrect ID",
      cintegrationDataGoogleAnalyticsErrorDescription: 'The ID should have the format "UA-XXXXXXX-X" or "G-XXXXXXXXXX-XX"',
      cintegrationTikTokDataSaved: "Tik-Tok pixel saved",
      cintegrationDataTikTokError: "The entered string is not a Tik-Tok pixel",
      cintegrationIntegrationToken: "Integration Token",
      cintegrationWriteToken: "Enter integration token",
      cintegrationDataYourAPIKey: "Enter your API key",
      viewQuizInMobile: "View Quiz",
      notificationCSRFTokenMismatch: "CSRF Token Mismatch",
      notificationReloadPage: "Please reload the page and try again",
      csettingsIntegrationKeyRemOnline: "Paste the token and channel ID in the appropriate fields",
      cintegrationDataYourIntegrationToken: "API key",
      cintegrationDataLeadTypeId: "Channel ID",
      send_utm: "Send UTM tags",
      send_quiz: "Send quiz data",
      send_answers: "Send answers",
      csettingsIntegrationKeyCrm: "KeyCRM integration",
      cintegrationSendPulseClientId: "Client ID",
      cintegrationSendPulseClientSecret: "Secret",
      cintegrationSendPulseAddressBookId: "Address book ID",
      cintegrationSendPulseAction: "Action with contact",
      sendpulseActionAddContact: "Add contact",
      sendpulseActionDeleteContact: "Delete contact",
      cintegrationPipeDriveToken: "API token",
      cintegrationPipeDriveDomain: "Your unique subdomain",
      cintegrationKeyCrmApiKey: "API key",
      cintegrationKeyCrmPipelineId: "Pipeline ID",
      cintegrationMenuGoogleTagManager:"Google Tag Manager",
      cintegrationMenuGoogleAnalytics:"Google Analytics",
      cintegrationMenuFacebookPixel:"Facebook pixel",
      cintegrationMenuTikTokPixel:"TikTok Pixel",
      cintegrationMenuWebHook:"Webhook",
      cintegrationMenuZapier:"Zapier",
      cintegrationMenuAPIxDrive:"APIx Drive",
      cintegrationMenuMake:"Make",
      cintegrationMenuSendPulse:"Send Pulse",
      cintegrationMenuEmail:"Email",
      cintegrationMenuTelegramBot:"Telegram-bot",
      cintegrationMenuPipeDrive:"PipeDrive",
      cintegrationMenuRemOnline:"Rem Online",
      cintegrationMenuKeyCRM:"Key CRM",
      cintegrationMenuCRMBitrix:"CRM Bitrix",
      trashcanRestoreQuiz: "Restore Quiz",
      cformValuePromotionAccepted: "I agree to the email newsletter",


      constructorAddIntegration: "Add a new integration",
      cintegrationKeyCrmSourceId: "Source ID (optional)",
      cintegrationKeyCrmManagerId: "Manager ID (optional)",
      cSettingsSettingQuizSpamFilterTitle: "Spam Protection",
      cSettingsSettingQuizSpamFilterDescription: "This feature is disabled by default. If you encounter spam leads, enable protection. After that, a single user will not be able to submit more than the specified number of leads for this quiz.",
      cSettingsSettingQuizSpamFilterLimitPlaceholder: "Number of leads",
      langNO: "Norwegian",
      loadMoreLeads: "See more leads",
      userAdditionalSettingsHeader: "Additionat settings",
      userAdditionalSettingsDescription1: "Here you can configure new advanced profile settings.",
      userAdditionalSettingsPagination: "Pagination on the workboard",

      benefitType: "Benefit Type",
      benefitLabelPlaceholder: "Bonus text",
      benefitDescriptionPlaceholderDiscount: "Promo code or instruction",
      benefitDescriptionPlaceholderVideo: "Link to video",
      benefitDescriptionPlaceholderTextfile: "Link to text file",
      benefitDescriptionPlaceholderGift: "Bonus description",
      benefitDescriptionPlaceholderPDF: "Link to PDF",
      benefitDescriptionPlaceholderLink: "Link",
      benefitDescriptionPlaceholderFile: "Link to file",
      benefitLabelDiscount: "Discount",
      benefitLabelVideo: "Video",
      benefitLabelTextfile: "Textfile",
      benefitLabelGift: "Gift",
      benefitLabelPDF: "PDF file",
      benefitLabelLink: "Link",
      benefitLabelFile: "File",
      addBenefit: "Add benefit",
      saveBenefit: "Save bonus",
      cfinishSocialNetworks: "Social networks",

      saveSocialNet: "Save Social Network",
      networkLabelFacebook: "Facebook",
      networkLabelInstagram: "Instagram",
      networkLabelTelegram: "Telegram",
      networkLabelTiktok: "Tik-Tok",
      networkLabelYoutube: "Youtube",
      networkLabelLinkedin: "LinkedIn",
      networkLabelWhatsapp: "WhatsApp",
      socialNetDescriptionPlaceholderFacebook: "profile_nikname",
      socialNetDescriptionPlaceholderInstagram: "profile_nikname",
      socialNetDescriptionPlaceholderTelegram: "profile/chanel_nikname",
      socialNetDescriptionPlaceholderTiktok: "profile_nikname",
      socialNetDescriptionPlaceholderYoutube: "chanel_name",
      socialNetDescriptionPlaceholderLinkedin: "in/name or company/name",
      socialNetDescriptionPlaceholderWhatsapp: "+15555551234",

      addNewSectionEmptyPage: "Simple Text Page",
      csanyMakeImpasse: "Impasse",
      impaseIsImpaseTrue: "ON",
      impaseIsImpaseFalse: "OFF",
      defaultNewEmptyQuestionDescription: "You can paste and format long text here (we do not recommend pasting more than 3000 characters).",
      cSettingsSettingQuizTimezoneTitle: "Quiz Timezone",
      cSettingsSettingQuizTimezoneDescription: "Enter the timezone in which the time for lead submission will be recorded. This is especially useful when you are in one timezone, and the client is in another. In this case, you can set the client's timezone, and the leads you receive will show the time according to the client's timezone.",
      cSettingsSettingQuizTimezonePlaceholder: "Timezone",
      userAdditionalSettingsTimezone: "User's time zone",
      userAdditionalSettingsTimezonePlaceholder: "Timezone",
      langHE: "Hebrew",
      langCS: "Czech",
      cintegrationCAPIFBInstruction: "To set up integration with Facebook Conversion API, you need to enter the pixel ID and access token. These details can be obtained in the Facebook Business Manager settings. After entering the required information, save the integration.",
      csettingsIntegrationFbCapiAbout: "Integration with Facebook Conversion API allows you to send user interaction data directly to Facebook to improve the effectiveness of advertising campaigns.",
      cintegrationInputCapiAccessToken: "Enter access token",
      cintegrationInputCapiCustomparameter: "Enter custom event parameters",
      cintegrationDataFbCapiSaved: "Facebook Conversion API integration successfully saved",
      cintegrationComonRenovateHubInstructionTitle: "Instructions for setting up integration with RenovateHub",
      cintegrationComonRenovateHubInstructionDescription: "To set up integration with RenovateHub, provide the webhook URL where the data will be sent. Make sure the URL is correct and accessible for receiving data.",
      cintegrationComonRenovateHubInstructionShort: "Integration with RenovateHub allows you to send data to CRM through a webhook for process automation.",
      cintegrationRenovateHubUrl: "Enter RenovateHub URL",
      cintegrationInputRenovateHubUrl: "Specify the webhook URL for RenovateHub",
      cintegrationRenovateHubDataSaved: "RenovateHub integration successfully saved",
      cintegrationDataRenovateHubError: "Error saving integration with RenovateHub. Please check the entered data for accuracy.",
      cintegrationMenuFacebookConvAPI: "Facebook Conversions API",
      cintegrationMenuRenovateHub: "RenovateHub",
      csettingsIntegrationRenovateHub: "RenovateHub integration",
      csettingsIntegrationCAPIFB: "Facebook Conversions API integration",
      paymentPlanEnd: "End date of the plan",
      paymentPlanLeadsLimitForYear: "Limit of leads per year:",
      paymentPlanLeadsLimitForPeriod: "Limit of leads per period:",
      EditTags: "Edit tags",
      quizzesTemplatesNoTags: "No Tags",
      Monday: "Monday",
      Tuesday: "Tuesday",
      Wednesday: "Wednesday",
      Thursday: "Thursday",
      Friday: "Friday",
      Saturday: "Saturday",
      Sunday: "Sunday",
      datetimeDisplayMode: "Picker type",
      datetimeDisplayModeSelect: "Select type",
      datetimeDisplayModeDate: "Date",
      datetimeDisplayModeTime: "Time",
      datetimeDisplayModeDatetime: "Date & Time",
      datetimeMinDate: "Min. date",
      datetimeMinDateCustom: "Select min. date",
      datetimeMaxDate: "Max. date",
      datetimeMaxDateCustom: "Select max. date",
      datetimeProhibitDaysOfWeek: "Restrict days",
      datetimeIsRange: "Enable range",
      datetimeIsRangeTrue: "Range enabled",
      datetimeIsRangeFalse: "Range disabled",
      datetimeMinTime: "Min. time",
      datetimeMaxTime: "Max. time",
      datetimeIs24: "24-hour format",
      datetimeIs24True: "24-hour enabled",
      datetimeIs24False: "24-hour disabled",
      csanyIsExactDate: "How would you like to set the date",
      csanyIsExactDateTrue: "Exact Date",
      csanyIsExactDateFalse: "Relative Date",
      csanyDatetimeMinMaxNotExactPlaceholder: '"x" or "-x" days from today',
      addNewSectionDatetime: "Date/time",

    },
    RU: {
      madeInUkr: "made in Ukraine",
      upProVersion: "Перейти на PRO",
      getLeads: "Купить Лиды",
      poperBillings: "Платежи",
      poperTemplates: "Шаблоны",
      poperPartnership: "Партнерская программа",
      poperProfile: "Профиль",
      poperLogout: "Выход",
      defaultNewQuestion: "Новый вопрос",
      defaultNewQuestionDescription: "Сюда можно вставить краткое описание вопроса (до 80 символов)",
      defaultNewVariant: "Новый вариант",
      defaultBtnText: "Начать",
      navBillings: "Платежи",
      navPartnership: "Партнерская программа",
      navProfile: "Профиль",
      welcomeBack: "С возвращением!",
      loginHeader: "Войти",
      loginHeader2Part: "чтобы продолжить",
      loginEmailPlaceholder: "E-mail",
      loginPasswordlPlaceholder: "○ ○ ○ ○ ○ ○ ○ ○ ○",
      loginBtnValue: "Войти",
      loginWith: "Войти с помощью",
      loginSignup: "Регистрация",
      passwordAreForgetPassword: "Забыли пароль?",
      passwordResetPasswordHeader: "Восстановление пароля",
      passwordResetPassword: "Восстановить пароль",
      passwordWeSentReset: "Мы отправили вам ссылку на восстановление пароля по электронной почте.",
      passwordInputNewPassword: "Введите новый пароль",
      passwordConfirmPassword: "Подтвердите пароль",
      passwordChangePassword: "Изменить пароль",
      passwordResetedPasswordCompleted: "Ваш пароль успешно изменен!",
      passwordGoToWorkboard: "Перейти в акаунт",
      helloHeader: "Здравствуйте!",
      signupHeader: "Зарегистрироваться",
      signupHeader2Part: "чтобы продолжить",
      signupNamePlaceholder: "Александр",
      signupPhonePlaceholder: "номер телефона",
      signupEmailPlaceholder: "E-mail",
      signupPasswordlPlaceholder: "○ ○ ○ ○ ○ ○ ○ ○ ○",
      signupBtnValue: "Создать аккаунт",
      signupWith: "Регистрация с помощью",
      signupLogin: "Войти в мой аккаунт",
      signupTariffHeader: "Тарифы",
      signupTariffFree: "Free.",
      signupTariffPro: "Pro.",
      signupTariffFreeDescription: "Безлимитное количество новых квизов (веб страниц). Возможность генерировать до 300 заявок в месяц бесплатно.",
      signupTariffProPrice: "$20/месяц",
      signupTariffProDescription: "Безлимитное количество новых квизов (веб страниц). Возможность генерировать",
      signupTariffProDescriptionBold: "более 300 заявок в месяц бесплатно.",
      userBackToQuiz: "Вернуться на воркборд",
      userBackToSettings: "Вернуться к настройкам",
      billingYourTariff: "Ваш тарифный план",
      billingYourPro: "Pro",
      billingYorFree: "Free",
      billingFreeLeads: "Бесплатных заявок",
      billingInfinity: "безлимит",
      billingTariffRenewData: "Дата обновлення тарифа",
      billingTariffEndData: "Дата окончания тарифа",
      billingOtherTariffFree: "Free.",
      billingOtherTariffPro: "Pro.",
      billingTariffFreeDescription: "Безлимитное количество новых квизов (веб страниц). Возможность генерировать до 300 заявок в месяц бесплатно.",
      billingYouHavePro: "Теперь вы на Pro версии. Зачем делать шаг назад?",
      billingYouHasFreeLeads: "Бесплатных заявок",
      billingYouHasLeads: "Осталось заявок",
      billingTariffProDescription: "Безлимитное количество новых квизов (веб страниц). Возможность генерировать ",
      billingTariffProDescriptionBold: "более 300 заявок.",
      billingYourFutureTariff: "Ваш будущий тариф",
      billingBtnChangeToPro: "Перейти",
      billingBtnRenewPro: "Обновить",
      billingBillings: "Оплаты",
      billingYouHaveNotBillings: "У вас пока нет оплат",
      billingPaymentsMethod: "Способ оплаты",
      billingPaymentCard: "Карта",
      billingChangeData: "Изменить данные",
      billingDeletePayMethod: "Удалить",
      billingModalDeleteShure: "Вы действительно хотите удалить этот способ оплаты?",
      billingModalDeleteCancel: "Отменить",
      billingModalDeleteOk: "Удалить",
      billingModalDeleteSuccess: "Вы успешно удалили свою карту",
      billingModalRegularPaymentDeleteSuccess: "Регулярные платежи отменены",
      billingModalRegularPaymentDeleteOk: "ОК",
      billingYourMonthPlan: "Ваш ежемесячный план",
      billingLeads: "заявок",
      billingChangePlan: "Изменить план",
      billingChangePlanDescription: "Прайс будет соответствующим к желаемому количеству заявок со всех квизов в вашом аккаунте.",
      billingSelectPeriod: "Выбрать период",
      billingChangePlanDescriptionImportant: "Важно:",
      billingChangePlanDescriptionImportantMonth: "Лиды можно использовать 1 месяц после покупки.",
      subscriptionTarifLable: "Подпишитесь на нашу автоматическую ежемесячную подписку. Вы можете изменить свой план в любое время.",
      billingChangePlanWarning: "",
      billingTariffMonth: "мес.",
      billingChangePlanPay: "Изменить тариф",
      profileName: "Имя",
      profilePhone: "Номер телефона",
      profileEmail: "E-mail",
      profileStatus: "Статус",
      profileStatusAgency: "Агенство",
      profileStatusPlaceholder: "Что описывает вас лучше всего?",
      profileStatusFreelancer: "Фрилансер",
      profileStatusBuisines: "Владелец бизнеса",
      profilePassword: "Password",
      profileChangePassword: "Изменить пароль",
      profileNewPassword: "Новый пароль",
      profileSaveNewData: "Обновить данные",
      workboardAddNewQuiz: "Создать проект",
      workboardModalGiveQuizName: "Дайте название квиза",
      workboardModalQuizNamePlaceholder: "Новый квиз",
      workboardModalNewQuizBtnValue: "добавить",
      workboardAlertGiveAnyName: "Введите хотя бы какое-нибудь название",
      workboardAlertNextTimeAddQuiz: "Что ж, в следующий раз решитесь)",
      workboardChangeQuizName: "Сменить название",
      workboardQuizLink: "ссылка",
      workboardQuizLinkCopied: "Ссылка скопирована в буфер",
      workboardEditQuiz: "Редактировать",
      workboardCopyQuiz: "Скопировать квиз",
      workboardQuizCopied: "Квиз успешно скопирован",
      workboardDeleteQuiz: "Удалить",
      workboardModalDeleteQuizIrreversible: "Это действие необратимо!",
      workboardModalDeleteQuizSure: "Вы действительно хотите удалить этот квиз?",
      workboardModalDeleteQuizCancel: "Отменить",
      workboardModalDeleteQuizDelete: "Удалить",
      workboardModalDeleteQuizSuccess: "Квиз успешно удалён",
      workboardModalQuizDeleteOk: "ОК",
      workboardStatsTitle: "Статистика",
      workboardModalLeadsAnswersCount: "Ответы на квиз",
      workboardModalLeadsLeadSerial: "№",
      workboardModalLeadsLeadDate: "Дата",
      workboardModalLeadsLeadEmail: "Email",
      workboardModalLeadsLeadName: "Имя",
      workboardModalLeadsLeadPhone: "Телефон",
      workboardModalLeadsLeadActions: "Действия",
      workboardModalLeadsLeadShowMore: "Показать больше",
      workboardModalLeadsLeadHideMore: "Скрыть",
      workboardModalLeadsLeadAnswerScipped: "Пропущено",
      workboardModalNoLeads: "Пока заявок нет",
      constructorLink: "ссылка",
      constructorLinkCopied: "Скопировано в буфер обмена:",
      constructorNavSteps: "Шаги",
      constructorNavDesign: "Дизайн",
      constructorNavSettings: "Настройка",
      constructorStep: "Шаг",
      cancel: "Отменить",
      constructorCancel: "Отменить",
      constructorSave: "Сохранить",
      constructorextraNavStart: "С",
      constructorextraNavContact: "К",
      constructorextraNavFinish: "Ф",
      cstepsSection: "Секции",
      cstepsStartPage: "Стартовая страница",
      cstepsContactPage: "Форма контактов",
      cstepsFinishPage: "Финишная страница",
      cstepsEdit: "Редактировать",
      cstepsMove: "Переместить",
      cstepsDeleteStep: "Удалить шаг",
      cstepsAddSection: "Добавить секцию",
      cdesignColorTheme: "Цветовая схема",
      cdesignCustomTheme: "Пользовательская цветовая схема",
      cdesignDefaultThemes: "Готовые цветовые схемы",
      cdesignThemeStandart: "Стандарт",
      cdesignThemeDark: "Дарк",
      cdesignThemeNud: "Нюд",
      cdesignThemeGradient: "Градиент",
      cdesignShapeDesign: "Форма дизайна",
      cdesignShapeRound: "Закругленная",
      cdesignShapeSquare: "Угловая",
      csettingsIntegration: "Интеграция",
      csettingsOweDomain: "Подключение личного домена",
      csettingsDocs: "Юридические документы",
      cintegrationIntegration: "Интеграция",
      cintegrationShareLeads: "Передача заявок",
      cintegrationTitleEmail: "E-MAIL",
      cintegrationInstruction: "Инструкция",
      cintegrationInstructionDetail: "Детали",
      cintegrationEmailInstruction: "Вставьте в поле снизу нужную электронную почту, на которую необходимо отправлять информацию. Каждая заявка с этого квизу будет дублироваться на указанную вами почту.",
      cintegrationPublishAfterEdit: "Сохраните квиз после редактирования",
      cintegrationSendNewLeadsTo: "Отправлять сообщения о новых заявках на:",
      cintegrationInputEmail: "Введите адрес электронной почты",
      cintegrationAddEmail: "Добавить email",
      cintegrationEmailSaved: "Данные Email интеграции сохранены",
      cintegrationEmailDeleted: "Данные Email интеграции удалены",
      cintegrationTitleFB: "FB pixel",
      cintegrationIDPixel: "Pixel ID",
      cintegrationFBInstruction1: "Зайдите в рекламный кабинете Facebook Ads и в разделе Event Manager скопируйте ID Facebook Pixel. ",
      cintegrationFBInstruction2: "Не копируйте весь код, только идентификатор из 15-16 цифр. ",
      cintegrationFBInstruction3: "Скопируйте и вставьте в поле ниже.",
      cintegrationFBInstruction: 'Событие, на которое нужно настраивать конверсию это "Lead". Это событие будет вызвано как только пользователь отправит заявку в квизе.',
      cintegrationInputIDPixel: "Введите ID пикселя",
      cintegrationDataFBSaved: "Данные FB пикселя сохранены",
      cintegrationAccesToken: "ACCES TOKEN",
      cintegrationTitleGA: "Google analytics",
      cintegrationGAInstructionDescription: "Узнайте, как люди находят ваш квиз и взаимодействуют с ним.  ",
      cintegrationGAInstruction: 'При получении заявки в квизе, в Google Analitics будет вызвано событие "generate_lead".',
      cintegrationIDFolow: "ID отслеживания",
      cintegrationInputID: "Ввести ID",
      cintegrationDataGASaved: "Данные Google Tag Manager сохранены",
      cintegrationTitleGTM: "Google Tag Manager",
      cintegrationGTMInstructionDescription: "Узнайте, как люди находят ваш квиз и взаимодействуют с ним.  ",
      cintegrationGTMInstruction: "Скопируйте идентификатор Google Tag Manager и вставьте его в поле ниже.",
      cintegrationDataGTMSaved: "Данные Google Tag Manager сохранены",
      cintegrationTitleTG: "Telegram",
      cintegrationHowReciveTGLeadBot: "Как получить заявки в Телеграм?",
      cintegrationTGInstructionBot1: "1. Скопируйте token находящийся в поле ниже.",
      cintegrationTGInstructionBot2: "2. Перейдите по ссылке в бот, нажмите кнопку «СТАРТ».",
      cintegrationTGInstructionBot3: "3. Скопированный token отправьте сообщением в наш бот.",
      cintegrationHowReciveTGLeadChat: "Как получить заявки в чат-телеграм?",
      cintegrationTGInstructionChat1: "1. Разместите наш бот в нужный чат и выставьте боту статус администратора.",
      cintegrationTGInstructionChat2: "2. Скопируйте token в поле ниже и отправьте его в нужный чат.",
      cintegrationTGInstructionChat3: "3. Дождитесь ответа `OK! Your quiz token was saved!”",
      cintegrationTGInstructionChat4: "4. Тепер заявки будут приходить в этот чат.",
      cintegrationTGInstructionWarning: "Важно:",
      cintegrationTGInstructionWarningText: "Обратите внимание, что это должна быть именно группа, а не Telegram канал. В Telegram канал заявки поступать не будут.",
      cintegrationTGBotLink: "ссылка на чатбот",
      cintegrationTGTitleToken: "TOKEN",
      cintegrationInputTGToken: "Введите телеграмм токен чат",
      cintegrationAddIntegration: "Добавить интеграцию",
      cintegrationDataTGSaved: "Данные Telegram интеграции сохранены",
      cintegrationTitleTT: "TT pixel",
      cintegrationTTInstruction: "Для отслеживания событий в рекламном кабинете Tik-Tok Ads, скопируйте идентификатор пикселя и вставьте в поле ниже. ",
      cintegrationDataTTSaved: "Данные TT пикселя сохранены",
      cintegrationTitleWH: "WebHook",
      cintegrationWHInstruction1: "Вы можете уведомлять посторонние приложения о поступивших заявках. Для этого укажите URL, на который будет отправлен WebHook.",
      cintegrationWHInstruction2: "Мы подготовили",
      cintegrationWHInstruction3: "видео инструкцию",
      cintegrationWHInstruction4: "с подробным описанием данного функционала.",
      cintegrationWHInstruction5: "Просмотреть видео.",
      cintegrationWHInstruction6: "В этом видео мы рассмотрим передачу хука на основе отправки данных в Google таблицу и отправку данных в CRM-систему Trello.",
      cintegrationWHInstruction7: "Мы рекомендуем использовать для этих целей сервис",
      cintegrationWHInstruction8: "При регистрации по данной ссылки, для наших пользователей, действуют бонус - 10 000 бесплатных действия для любых интеграций.",
      cintegrationWHInstruction9: "Ссылка",
      cintegrationWHInstruction10: "для регистрации с бонусом.",
      cintegrationWHUrl: "Адреса вебхука",
      cintegrationInputWHUrl: "Введите адрес вебхука",
      cintegrationAddWH: "Добавить Web-hook",
      cintegrationWebHookDataSaved: "WebHook данные сохранены",


      cintegrationComonWHInstructionShort: "Вы можете уведомлять сторонние приложения о поступающих лидах. Укажите URL-адрес вебхука для отправки данных.",
      cintegrationComonWHInstruction1Title: "1. Вставьте URL-адрес вебхука в поле для ввода:",
      cintegrationComonWHInstruction1Description: "Вставьте URL-адрес вебхука, предоставленный вашим сервисом или приложением, в поле для ввода.",
      cintegrationComonWHInstruction2Title: "2. Сохраните изменения:",
      cintegrationComonWHInstruction2Description: 'Нажмите кнопку "Сохранить изменения" для сохранения настроек вебхука.',
      cintegrationComonWHInstruction3Title: "3. Готово:",
      cintegrationComonWHInstruction3Description: "Теперь ваш вебхук настроен для отправки данных на выбранный вами сервис.",
      cintegrationComonWHInstructionAdditional: "Данные будут отправлятся на вебхук при каждом получении лида. Метод отправки POST. Пример данных которые будут отправлены находится ",
      cintegrationComonWHInstructionAdditionalLink: "тут",
      cintegrationComonWHInstructionVideo: "Подробная видео-инструкция",

      cintegrationApixDriveWHInstructionShort: "Вы можете уведомлять ApixDrive о поступающих лидах. Для этого укажите URL-адрес, на который будет отправлен WebHook.",
      cintegrationApixDriveWHInstructionShort1: "При регистрации по ",
      cintegrationApixDriveWHInstructionShort2: "этой ссылке",
      cintegrationApixDriveWHInstructionShort3: " наши пользователи получают бонус - 10 000 бесплатных действий для любых интеграций.",
      cintegrationApixDriveWHInstruction1Title: "Зарегистрируйтесь или откройте ваш акаунт ApixDrive:",
      cintegrationApixDriveWHInstruction1Description1: "Ссылка ",
      cintegrationApixDriveWHInstruction1Description2: "для регистрации с бонусом.",
      cintegrationApixDriveWHInstruction2Title: "Получите URL адрес для вебхука:",
      cintegrationApixDriveWHInstruction2Description1: "Зайдите в настройки необходимой связи и скопируйте адрес для отправки вебхука:",
      cintegrationApixDriveWHInstruction2Description2: 'Вы можете создать новую связь нажав кнопку "Создать связь" и на шаге выбора системы выберите "ADSQuiz".',
      cintegrationApixDriveWHInstruction3Title: "Вставьте URL-адрес вебхука:",
      cintegrationApixDriveWHInstruction3Description: "Вставьте URL-адрес вебхука, предоставленный ApixDrive, в поле для ввода.",
      cintegrationApixDriveWHInstruction4Title: "Сохраните изменения:",
      cintegrationApixDriveWHInstruction4Description: 'Нажмите кнопку "Сохранить изменения" для сохранения настроек вебхука.',
      cintegrationApixDriveWHInstruction5Title: "Готово:",
      cintegrationApixDriveWHInstruction5Description: "Теперь ваш вебхук настроен для отправки данных на ApixDrive.",
      cintegrationApixDriveWHInstructionAdditional: "Данные будут отправлятся на вебхук при каждом получении лида. Метод отправки POST. Пример данных которые будут отправлены находится ",
      cintegrationApixDriveWHInstructionAdditionalLink: "тут",
      cintegrationApixDriveWHInstructionVideo: "Подробная видео-инструкция",



      cintegrationConectOweDomain: "Подключение личного домена",
      cintegrationOweDomainInstruction1: "1. Чтобы разместить ваш квиз на необходимом домене, зайдите в аккаунт вашего хостинга. Выделите DNS-настройки и создайте A-запись с параметром IP: 185.25.117.21",
      cintegrationOweDomainInstruction2: "2. Укажите домен в поле выше и сохраните квиз.",
      cintegrationDomain: "Домен",
      cintegrationInputYourDomain: "Введите ваш домен",
      cintegrationCheckDomenIsCorrect: "Проверьте правильность введенного домена",
      cintegrationDomainWhichCorrect: "Введите домен без протоколов. Например: some.site.com",
      cintegrationInsertedDefaultDomain: "Домен установлен по умолчанию",
      cintegrationDomainDataSaved: "Данные домена сохранены",
      cintegrationPrivacyPolicy: "Политика конфиденциальности",
      cintegrationPrivacyPolicyByDefault: "По умолчанию мы используем нашу стандартную политику конфиденциальности. Мы рекомендует добавить вашу политику конфиденциальности, которая будет актуальна для вашего бизнеса. ",
      cintegrationPrivacyPolicyAdventage: "Это даст понять вашим потенциальным клиентам как вы используете полученные контактные данные. Также этого требуют некоторые рекламные площадки.",
      cintegrationPrivacyPolicyUrl: "Ссылка на документы",
      cintegrationPrivacyPolicyInputYourDocsLink: "Вставте ссылку на документы",
      cintegrationDocsDataSaved: "Ссылка на ваши документы сохранена",
      allstepsUpploadPhoto: "Загрузить фото",
      allstepsChangePhoto: "Изменить фото",
      allstepsDeletePhoto: "Удалить фото",
      allstepsTitle: "Заголовок",
      allstepsDescription: "Описание",
      allstepsButtonText: "Текст кнопки",
      allstepsOtherSettings: "Другие настройки",
      allstepsIsSaveBeforeRoute: "Сохранить данные перед переходом?",
      cfirstFormat: "Формат",
      cfirstHeaderStartText: "Ваш заголовок должен быть здесь. Мотивируйте пользователей пройти тест до конца",
      cfirstAddMediafile: "Добавить медиафайл (фото)",
      cfirstBonus: "Бонус",
      cfirstAddBonus: "Добавить бонус",
      cfirstStartPageDataUpdated: "Данные стартовой страницы обновлены",
      csanyAnswerType: "Тип вопроса",
      csanyATCard: "карта",
      csanyATList: "список",
      csanyATCustom: "свободное поле",
      csanyQuestion: "Вопрос",
      csanyAnswers: "Ответы",
      csanyAddAnswer: "Добавить",
      csanyRequiredStep: "Обязательный шаг",
      csanyAlowMultiple: "Разрешить несколько ответов",
      csanyAutoRedirect: "Автопереход далее",
      csanyQuestionLogic: "Настройки логики вопросов",
      csanyDataStepUpdated: "Данные страницы с вопросом обновлены",
      cformForm: "Форма",
      cformValueName: "Имя",
      cformValueSurname: "Фамилия",
      cformValuePhone: "Номер телефона",
      cformValueEmail: "E-mail",
      cformDataContactsPageUpdated: "Данные контактной страницы обновлены",
      cformRequiredInput: "Обязательный",
      cfinishFinishPageTitle: "Финишная страница",
      cfinishText: "Текст",
      cfinishDescription: "Описание",
      cfinishAddImage: "Добавить изображение",
      cfinishImageAdded: "Изображение добавлено",
      cfinishURL: "URL",
      cfinishButtonText: "Текст кнопки",
      cfinishInsertNikname: "Вставьте здесь ваш никнейм",
      cfinishCheckDataIsCorrect: "Проверьте правильность введенного идентификатора",
      cfinishFbWhichCorrect: "Введите идентификатор (никнейм) без остального адреса. Нпример: some.name",
      cfinishIgWhichCorrect: "Введите идентификатор (никнейм) без остального адреса. Нпример: some.name",
      cfinishTgWhichCorrect: "Введите идентификатор (никнейм) без остального адреса. Нпример: some.name",
      cfinishFinishPageDataUpdated: "Данные  финишной страницы обновлены",
      adminUsersReg: "Пользователей в системе",
      adminNewUsers: "Новых пользователей за месяц",
      adminProAccounts: "PRO accounts",
      adminLTV: "LTV",
      adminSharePro: "PRO сравнительно со всеми",
      adminReciveLeads: "Получено заявок",
      adminLeadsForOne: "На одного пользователя",
      adminMadeQuiz: "Сделано квизов",
      adminQuizForOne: "На одного пользователя",
      adminAvarageUsed: "Среднее количество месяцев использования",
      adminProfitMonth: "Заработан за месяц",
      adminProfitAlltime: "Заработано за все время",
      adminUsersList: "База пользователей",
      notificationErrorLogin: "Ошибка авторизации:",
      notificationLogin: "Вход...",
      notificationWrongLoginData: "Неправильные данные входа",
      notificationNetworkError: "Проверьте подключение к Интернету",
      notificationInputLoginData: "Введите данные для входа",
      notificationCheckInputEmail: "Проверьте правильность электронной почты",
      notificationCheckInputPhone: "Проверьте правильность номера телефона",
      notificationInputAllInputs: "Заполните все поля для регистрации",
      notificationAgreePrivacyPolicy: "Согласитесь с политикой конфиденциальности",
      adminEditorForPartnership: "Редактор партнерской программы",
      developmentPage: "Раздел находится в разработке",
      signupAgreeIAgreeWith: "Я согласен с",
      signupAgreePolicy: "политикой конфиденциальности",
      signupAgreeAnd: "и",
      signupAgreeOffer: "публичной офертой",
      signupAgreeService: "сервиса",
      workboardModalLeadsLeadReferrer: "Не определено",
      workboardModalLeadsLeadReferrerClarification: "Данные об источнике перехода заблокированы или не определены",
      workboardModalLeadsLeadEmailCopied: "Email скопирован в буфер",
      workboardModalLeadsLeadPhoneCopied: "Номер телефона скопирован в буфер",
      workboardModalLeadsHaveNotSended: "У вас есть неполученные лиды",
      workboardModalLeadsLeadReload: "Получить",
      workboardModalLeadsLeadReloadedTitle: "Запрос получен",
      workboardModalLeadsLeadReloaded: "If you have available leads in your tarif - the requested leads will be loaded at the top of the list and sent to your integrations",
      cfirstStartPagePhotoUpdated: "Фото стартовой страницы обновлено",
      allstepsPhotoTooBig: "Файл слишком большой",
      allstepsPhotoMustBeLess: "Фото должно быть меньше",
      cfirstStartPageBonusPhotoUpdated: "Фото бонуса обновлено",
      cstepsQuestionsOrderSaved: "Порядок вопросов сохранён",
      csanyAnswerOrderSaved: "Порядок ответов сохранён",
      allstepsPhotoAdaptation: "Адаптация фото под устройства",
      allstepsPhotoFit: "Соотношение сторон",
      constructorYouHideStep: "Вы спрятали этот шаг",
      cstepsHiddenStep: "Спрятать шаг",
      cstepsShowStep: "Отобразить шаг",
      cfinishFinishPageHiddenUpdated: "Фінішна сторінка прихована.",
      cfinishFinishPageHiddenUpdatedGoContact: "Перейдіть на сторінку Форми контактів",
      cfinishFinishPageHiddenUpdatedSetRedirect: "та налаштуйте редирект після відправки даних",
      cformActionAfterContactRecive: "Действия после отправки формы",
      cformActionAfterContactThankyou: "Страница благодарности",
      cformActionAfterContactRedirect: "Переадресация",
      cformAdditionalSettings: "Дополнительные настройки",
      cformRedirectURL: "URL",
      cintegrationOweDomainInstructionImportant: "Важно: Адрес в вашем хостинг аккаунте и поле ниже, должны совпадать. Время применения изменений DNS-настроек и выписки SSL сертификата - до 3-х часов после сохранения.",
      cintegrationOweDomainInstructionVideo: "Посмотреть видео инструкцию",
      workboardModalLeadsLeadLimit: "Лимит ваших лидов исчерпан.",
      workboardModalLeadsLeadLimitInstruction1: "Чтобы увидеть новые лиды,",
      workboardModalLeadsLeadLimitInstruction2: "перейдите на другой тариф",
      workboardModalLeadsLeadLimitInstruction3: "обновите текущий тариф",
      workboardModalLeadsLeadLimitInstruction4: '(не касается "Free") или дождитесь обновления тарифа, если у вас подключено автопродление тарифа',
      billingBonusesTitle: 'Промокод',
      billingBonusesDescription: 'Введите полученный промокод в поле рядом',
      billingBonusesPlaceholder: 'Промокод',
      billingBonusesBtn: 'Активировать',
      billingBonusesActivated: 'Промокод активирован!',
      billingBonusesLeadIncreased: 'Количество доступных лидов увеличено.',
      billingBonusesInvalid: 'Неправильный промокод',
      billingBonusesNotAvailable: 'Промокод больше недоступен',
      billingBonusesAlreadyActivated: 'Бонусы этого промокода уже были начислены',
      createQuizSPDescription: "Это место для краткого описания",
      createQuizCPTitle: "Как мы можем связаться с вами?",
      createQuizCPDescription: "Пожалуйста, заполните форму ниже",
      createQuizCPButton: "Готово!",
      createQuizFPTitle: "Спасибо!",
      createQuizFPDescription: "Теперь мы подберём лучшее решение для вас!",
      createQuizFPButton: "Покажите мне что-нибудь!",
      selectInternationalPrefix: "Выбрать международный префикс",
      autoInternationalPrefix: "Определять страну автоматически",
      dovnloadCsvTitle: "Экспортировать данные за вибраный период",
      dovnloadCsvSelectAllPeriod: "Выбрать за весь период",
      dovnloadCsvCancel: "Отменить",
      dovnloadCsvExport: "Экспортировать",
      
      
      
      
      widgetEmbedVidgetToSite: "Установка квиза на сайте",
      widgetEmbedVidgetSelectEmbedVariant: "Выберите вариант интеграции",
      widgetEmbedVidgetSitecode: "В коде сайта",
      widgetEmbedVidgetPopup: "Поп-ап",
      widgetEmbedVidgetChat: "Квиз-кнопка",
      widgetEmbedVidgetInProgres: "В разработке",
      widgetEmbedVidgetToSiteCode: "Установка квиза в код сайта",
      widgetSiteCodeInstruction0: "Чтобы установить квиз на вашем сайте, добавьте код с первого поля в тег `<head>` на вашем сайте или в Google Tag Manager, если он интегрирован в ваш сайт. Если после того как вы уже вставите квиз на сайт вы измените домен у квиза Вам нужно будет обновить код интеграции на вашем сайте.",
      widgetSiteCodeInstruction1: "В место куда вы хотите интегрировать квиз, вставьте код со второго поля.",
      widgetSiteCodeInstruction2: "Квиз можно интегрировать в контейнер любого размера, даже в сайдбары! Максимальный размер квиза, также как и при прямом входе на домен - 900х552 пикселя. Его можно добавлять в контейнеры и большего размера, в таком случае квиз будет отцентрирован.",
      widgetSiteCodeInstruction3: "Не стесняйтесь – экспериментируйте!",
      widgetSiteCodeCopyToHead: "Скопируйте и вставьте в `<head>` вашего сайта",
      widgetSiteCodeCopyToBody: "Вставьте код в место, где должен быть квиз",
      workboardModalAddQuizHeader: "Создание нового Quiz",
      workboardModalGiveQuizNameAndMethod: "Придумайте название вашего квиза и виберите желаемый способ создания",
      workboardModalNewQuizFromStart: "Создать с нуля",
      workboardModalNewQuizFromTemplates: "Готовые шаблоны",
      QuizTemplatesSearchInput: "Название шаблона и/или категорий",
      QuizTemplatesSearchBtn: "Поиск",
      QuizTemplatesReadyTemplates: "Готовые шаблоны",
      QuizTemplateView: "Посмотреть",
      QuizTemplateSelect: "Выбрать",
      widgetPopupInstruction0: "Чтобы установить квиз на вашем сайте, добавьте код из поля с кодом в тег `<head>` на вашем сайте или в Google Tag Manager, если он интегрирован в ваш сайт. Если после того как вы уже вставите квиз на сайт вы измените домен у квиза или тригеры открытия (процент скрола или тайминг), вам нужно будет обновить код интеграции на вашем сайте.",
      widgetPopupInstruction1: "Сразу перед закрывающим тегом </footer> вставьте код из второго поля.",
      widgetPopupInstruction2: "Для программистов или продвинутых пользователей",
      widgetPopupInstruction3: "Если вам, по каким-либо причинам, неудобно вставлять квиз перед закрывающим тегом `</footer>` вы можете вставить код из второго поля в любое удобное место, но учитывайте, что контейнер в который вы вставляете код из второго поля, должен быть поверх всех окон и крайне желательно, должен иметь высоту и ширину максимально возможную для дисплея.",
      widgetPopupCopyToHead: "Скопируйте и вставьте в `<head>` вашего сайта",
      widgetPopupCopyToBody: "Скопируйте и вставьте код перед закрывающим тегом `</footer>`",
      widgetPopupTimeToOpen: "Промежуток времени",
      secondReduction: "сек",
      widgetPopupTimeClarification: "Попап появится с задержкой указанного выше времени в секундах",
      widgetPopupScrollToOpen: "Процент пролистывания сайта",
      widgetPopupScrollClarification: "Попап появится после указанного выше процента пролистывания вашего сайта",
      quizzesTemplatesAllTags: "Все шаблоны",
      widgetEmbedVidgetButton: "Конструктор кнопки",
      widgetButtonInstruction0: "Ви можете установить на вашем сайте квиз который будет открываться при клике по кнопке.",
      widgetButtonInstruction1: "Додайте заокруглення, тінь, та текст кнопки. Також, ви можете змінити колір кнопки та тексту всередині.",
      widgetButtonInstruction2: "Скопируйте скрипт, расположенный в первом поле с кодом, и вставьте его перед закрывающим тегом `</head>`.",
      widgetButtonInstruction3: "Скопируйте сгенерированный код кнопки со второго поля с кодом и вставьте в любое место вашего сайта.",
      widgetButtonInstruction4: 'Вы можете не использовать код со второго поля с кнопкой, а просто добавить `id="button_open_quiz_popup"` к любому элементу `<input type="button">` или элементу `<button></button>` на сайте.',
      widgetRealTimeChange: "Вы можете видеть изменения в реальном времени в правой части экрана",
      widgetButtonCornerRound: "Скругления кнопки",
      widgetButtonShadow: "Тень кнопки",
      widgetButtonBgColor: "Цвет кнопки",
      widgetButtonTextColor: "Цвет текста",
      widgetButtonTextValue: "Текст кнопки",
      openPopupQuizText: "Начать квиз",
      widgetButtonInsertBeforeAfterBody: "Вставьте код квиза сразу после открывающего тега `<body>` или перед сразу закрывающим тегом `</body>`",
      widgetSiteCodeCopyButton: "Вставьте код кнопки в любое место на сайте",
      footerContactUs: "Возникли вопросы? Вы можете связаться с нами:",
      enterYourName: "Введите ваше имя",
      enterYourPhone: "Введите номер телефона",
      enterYourEmail: "Введите ваш email",
      enterYourPassword: "Введите ваш пароль",
      selectOption: "Выберите вариант",
      createNewGroup: "Создать новую групу",
      dontHaveAccount: "У вас нету акаунта?",
      resetPasswordTip: "Если у вас есть какие-либо дополнительные проблемы или вопросы, пожалуйста, не стесняйтесь обращаться в нашу службу поддержки клиентов. Мы здесь, чтобы помочь.",
      getBackAccount: "Вернитесь к своей учетной записи",
      inMinutes: "за минуту",
      alreadyHaveAccount: "Уже есть акаунт?",
      quizSlotLastEdit: "Последнее изменение",
      workboardQuizzesTitle: "Мои квизы",
      allstepsBack: "Назад",
      constructorGetLink: "Ссылка на квиз",
      allOr: "или",
      allAnd: "и",
      acceptedFileType: "Допустимые форматы: ",
      conjunctionAnd: "и",
      acceptedFileSize: "Максимальный размер: ",
      acceptedFileSizeMeasure: "МБ",
      cfirstBonusDisabled: "Отключено",
      cfirstBonusAvailable: "Доступно",
      allstepsResetInHeader: "Сбросить",
      placeholderText: "Введите здесь",
      constructorSaveChanges: "Сохранить изменения",
      cformInputAvailable: "Доступно",
      cformInputDisabled: "Отключено",
      constructorBack: "Назад",
      videoInstruction: "Видеоинструкция",
      csettingsIntegrationFB: "Интеграция - Facebook Pixel",
      csettingsIntegrationTT: "Интеграция - TikTok",
      csettingsIntegrationGA: "Интеграция - Google Analytics",
      csettingsIntegrationGTM: "Интеграция - Google Tag Manager",
      csettingsIntegrationWH: "Интеграция - WebHook",
      csettingsIntegrationTG: "Интеграция - Telegram",
      csettingsIntegrationApixDrive: "Интеграция - APIxDrive",
      csettingsIntegrationEmail: "Интеграция - Эл. почта",
      csettingsIntegrationAbout: "Описание",
      csettingsIntegrationFBAbout: "Перейдите в рекламный кабинет Facebook Ads и скопируйте идентификатор пикселя Facebook в разделе Менеджер событий. Не копируйте весь код, только 15-16-значный идентификатор. Скопируйте и вставьте его в поле ниже.",
      csettingsIntegrationTTAbout: "Для отслеживания событий в вашей учетной записи Tik-Tok скопируйте идентификатор пикселя и вставьте его в поле ниже.",
      csettingsIntegrationGAAbout: "Скопируйте идентификатор Google Analytics и вставьте его в поле ниже.",
      csettingsIntegrationGTMAbout: "Скопируйте идентификатор Google Tag Manager и вставьте его в поле ниже.",
      csettingsIntegrationWHAbout1: "Вы можете уведомлять сторонние приложения о поступающих лидах. Для этого укажите URL-адрес, на который будет отправлен WebHook. Мы рекомендуем использовать эту службу для этой цели.",
      csettingsIntegrationWHAboutApix: "Apix-Drive",
      csettingsIntegrationWHAbout2: "При регистрации по этой ссылке наши пользователи получают бонус - 10 000 бесплатных действий для любых интеграций.",
      csettingsIntegrationTGAbout: "Важно: обратите внимание, что это должна быть группа, а не канал Telegram. Лиды не будут отправляться на канал Telegram.",
      csettingsIntegrationADAbout: "Что-то о APIxDrive",
      csettingsIntegrationEmailAbout: "Инициируйте отправку электронной почты вашему респонденту и уведомьте свою команду. Сотрудничайте с вашей командой.",
      cSettingsIntegrationNeedHelp: "Нужна помощь?",
      cSettingsIntegrationRefreshToken: "Обновить токен",
      csettingsSettingASettings: "Расширенные настройки",
      cSettingsSettingQuizLangTitle: "Язык квиза",
      cSettingsSettingQuizLangDescription: "Чтобы обеспечить наилучший опыт, пожалуйста, выберите нужный язык квиза из вариантов ниже. На этом языке отображаются системные оповещения и некоторые другие неизменные тексты при прохождении квиза.",
      selectQuizLanguage: "Выберите язык квиза",
      cSettingsSettingQuizHideLabelTitle: "Спрятать метку",
      cSettingsSettingQuizHideLabelDescription: 'Включив этот параметр, вы сможете наслаждаться свободным от метки "made by adsquiz" интерфейсом, который позволит вам и вашим посетителям сосредоточиться исключительно на вопросах.',
      cSettingDataSaved: "Дополнительные настройки сохранены",
      userAccountInfo: "Информация об учетной записи",
      userGeneralInfoHeader: "Общая информация",
      userGeneralInfoDescription1: "Ваш профиль пользователя - это место, где вы можете управлять своей учетной записью и настройками.",
      userGeneralInfoDescription2: "Обновите свои личные данные, смените пароль и настройте предпочтения по уведомлениям.",
      profileNameFirst: "Имя",
      profileNameLast: "Фамилия",
      userSecuritySettingsHeader: "Настройки безопасности",
      userSecuritySettingsDescription: "Чтобы изменить пароль, просто введите ваш новый пароль дважды для подтверждения.",
      profileEmailCurent: "Текущий адрес эл. почты",
      profileEmailNew: "Новый адрес эл. почты",
      profileNewPasswordConfirm: "Подтвердите новый пароль",
      profileDeleteAccount: "Удалить учетную запись",
      profileDeleteAccountPopupHeader: "Удалить учетную запись?",
      profileDeleteAccountPopupDescription: 'Вы уверены, что хотите удалить свою учетную запись? Это действие нельзя отменить. Для подтверждения удаления введите слово "УДАЛИТЬ" в поле ниже.',
      profileDeleteAccountCheckedWord: "УДАЛИТЬ",
      profileDeleteAccountPopupPlaceholder: "Введите здесь",
      notificationSuccess: "Успех!",
      notificationSuccessSaveAccount: "Ваш пароль успешно изменен! Используйте новый пароль для входа",
      notificationSuccessDeleteAccount: "Ваш пароль успешно изменен! Используйте новый пароль для входа",
      cardPanUnspecified: "Не указано",
      userBillingsDate: "Дата",
      userBillingsCost: "Стоимость",
      userBillingsId: "ID",
      userBillingsMethod: "Способ оплаты",
      paymentSwitchToProHeader: "Переключиться на Pro",
      paymentSwitchToProDescription: "Найдите подходящий для вас план! Повышайте или понижайте уровень в любое время - мы упрощаем получение нужного плана.",
      paymentYourCurrentPlan: "Ваш текущий план:",
      paymentYourFuturePlan: "Ваш новый план:",
      paymentPlanLeadsLimit: "Лимит лидов на месяц:",
      paymentPlanLeadsLimitForTest: "Ваш лимит для теста квизов:",
      paymentPlanLeadsLeft: "Осталось лидов:",
      paymentPlanPrice: "Цена:",
      paymentPlanRenew: "Дата продления тарифа:",
      addNewSectionHeader: "Добавить новый раздел",
      addNewSectionList: "Список",
      addNewSectionCustom: "Поле ввода",
      addNewSectionCard: "Карточка",
      addNewSectionWithEmoji: "Список с эмодзи",
      addNewSectionTags: "Теги",
      addNewSectionSlider: "Ползунок",
      templatesBackToQuiz: "Вернуться к воркборду",
      constructorStepsSaved: "Все данные квиза успешно сохранены",
      constructorDesignSaved: "Дизайн квиза успешно сохранен",
      azureBlueWhite: "Стандартный",
      azureBlue: "Зимняя ночь",
      moderatePurplishRed: "Красный бархат",
      violetBlueCrayola: "Зимний бриз",
      wetRainforest: "Летний клен",
      lavender: "Космос",
      blueLilac: "Темная лаванда",
      opalGreen: "Карпатский лес",
      dustyBlue: "Тиффани",
      almondCrayola: "Кораловий риф",
      periwinkleCrayolaLilac: "Сирень",
      periwinkleCrayolaAqua: "Синевир",
      jazzJam: "Джаз",
      slateGrayBlue: "Мгла",
      turquoiseBlueCrayola: "Дия",
      blackPearlescentOpal: "Зола",
      pearlescentGentianBlue: "Сапфир",
      sapphireBlue: "Темный сапфир",
      errorUserDataUpdate: "Ошибка в обновленных данных: ",
      completeUserDataUpdate: "Данные пользователя обновлены",
      passwordDoNotCoincidence: "Пароли не совпадают",
      passwordDoNotCoincidenceAgain: "Спробуйте ще раз",
      nonBillingsYet: "У вас пока нет истории платежей",
      youCanChangePlan: "Вы можете изменить текущий план, нажав кнопку «Переключиться на PRO»",
      rangeMin: "Мин",
      rangeMax: "Макс",
      rangePositionStart: "Начальное положение",
      rangePositionEnd: "Конечное положение",
      rangeStep: "Шаг",
      rangeIsRange: "Диапазон",
      rangeIsRangeTrue: "Включено",
      rangeIsRangeFalse: "Выключено",
      recivePromotionNewsletter: "Получать имейл рассылки",
      reciveEmailCheckbox: "Чекбокс получения электронной почты",
      additionalCheckbox: "Дополнительный чекбокс",
      contactPrivacyPolicyCheckbox: "Чекбокс политики конфиденциальности",
      contactAgreeIAgreeWith: "Я согласен с",
      contactAgreePolicy: "политикой конфиденциальности",
      contactAgreeAnd: "и",
      contactAgreeTermsOfUse: "правилами использования",
      contactAgreeAgreementAdditionalWord: "",
      langEN: "Английский",
      langDE: "Немецкий",
      langSP: "Испанский",
      langPL: "Польский",
      langIT: "Итальянский",
      langKK: "Казахский",
      langRU: "Русский",
      langUA: "Украинский",
      langLT: "Литовский",
      langLV: "Латвийский",
      langET: "Эстонский",
      langRO: "Румынский",
      leadsLeft: "Осталось лидов",
      generateDomainTitle: "Введите доменное имя",
      generateDomainDescription: "Это уникальный адрес, по которому будет доступна ваша страница. Вы можете изменить адрес в любой момент или подключить свой.",
      generateDomainDomainNameLabel: "Доменное имя",
      cancelAllAction: "Отменить",
      confirmAllAction: "Подтвердить",
      falseHasValidCharacters: "Недопустимые символы в домене. Допустимыми символами являются буквы латинского алфавита, цифры и дефисы.",
      falseHasValidLength: "Длина домена должна быть от 6 до 20 буквенно-цифровых символов.",
      falseDoesNotStartOrEndWithSpecialChar: "Домен не должен начинаться или заканчиваться специальным символом.",
      falseDoesNotHaveConsecutiveSpecialChar: "Домен не должен содержать два или более подряд специальных символа.",
      falseDoesNotHaveConsecutiveSameChar: "Домен не должен содержать три или более подряд одинаковых символа.",
      falseDoesNotContainForbiddenSubstrings: "Домен не должен содержать запрещенные подстроки.",
      generateDomainTaken: "Этот домен уже занят. Попробуйте другой",
      temporarilyUnavailable: "Временно недоступно",
      csettingsAdmin: "Настройки для админов",
      questionLogicSetupTitle: "Настройка логики вопроса",
      questionLogicSetupDescription: "Добавьте условия, чтобы настроить опрос для каждого респондента и собирать более точные данные.",
      questionLogicConditionTitle: "Условие",
      questionLogicQuestion: "Вопрос",
      questionLogicSetupQuestionDisableOption: "Выберите вопрос",
      questionLogicSetupAnswer: "Ответ",
      questionLogicSetupLogicType: "Тип",
      questionLogicSetupAnswerDisableOption: "Выберите тип условия",
      questionLogicSetupRangeBetween: "Между",
      questionLogicSetupRangeLess: "Меньше",
      questionLogicSetupRangeMore: "Больше",
      questionLogicSetupRangeEqualTo: "Равно",
      questionLogicSetupRangeFrom: "От",
      questionLogicSetupRangeTo: "До",
      questionLogicSetupVariantAll: "Все из выбранных",
      questionLogicSetupVariantAny: "Один из",
      questionLogicSetupVariantNot: "Ни одного из",
      questionLogicSetupCustomEqual: "Равно",
      questionLogicSetupCustomContains: "Содержит",
      questionLogicSetupCustomNotContains: "Не содержит",
      questionLogicSetupRangeValue: "Значение",
      questionLogicSetupExpectedAnswer: "Ожидаемые ответы",
      questionLogicSetupPlaceholder: "Введите здесь",
      questionLogicAllMandatory: "Все указанные условия обязательны",
      questionLogicAddConditionBtn: "Добавить условие",
      questionLogicNotAvailable: "Настройки логики недоступны для первого вопроса",
      questionLogicSaved: "Настройки логики отображения шага сохранены",
      billingPaymentInfoTitle: "Информация о тарифе",
      billingPaymentInfoChangeTarrif: "Сменить тариф",
      billingPaymentInfoDescription: "При оплате любого платного тарифа вы получаете определенное количество лидов, включенных в выбранный вами пакет. Эти лиды предоставляются на месяц. Если вы не использовали свои лиды в течение месяца с момента оплаты, они будут аннулированы.",
      billingGeneralName: "Ваш тариф",
      billingGeneralCost: "Стоимость",
      billingMonth: "месяц",
      billingGeneralExpired: "Действителен до",
      billingGeneralLeadsLeft: "Лидов осталось",
      billingGeneralAllTarifExpired: "Срок действия тарифов истек",
      billingGeneralBonus: "Лиды по промокоду",
      billingGeneralLeadsFromPastTarifs: "Лиды с предыдущих тарифов",
      billingAcordeonPaymentInfoTitle: "Платежная информация",
      billingAcordeonPaymentInfoFormat: "Формат",
      billingAcordeonPaymentInfoSubscription: "Подписка",
      billingAcordeonPaymentInfoPay: "Оплата",
      billingAcordeonPaymentInfoPayMethod: "Способ оплаты",
      billingAcordeonPaymentInfoCancelSubscription: "Отменить подписку",
      billingAcordeonPromocode: "Промокод",
      billingAcordeonPromocodeLabel: "Введите промокод",
      billingAcordeonPayHistory: "История транзакций",
      paymentAllTarifInactive: "тариф неактивен",
      subscriptionUnsubscribeModalTitle: "Отписаться",
      subscriptionUnsubscribeModalDescription: "Вы уверены, что хотите отписаться? Начисленные лиды по подписке будут доступны вам в течение месяца с момента последнего списания средств по подписке.",
      subscriptionIsNotDeleteTitle: "Подписка не удалена",
      subscriptionIsNotDeleteDescription: "Пожалуйста, обратитесь в службу поддержки",
      subscriptionCanceledTitle: "Подписка аннулирована",
      modalSureUnsavedChangesTitle: "У вас есть несохраненные изменения",
      modalSureUnsavedChangesDescription: "Прежде чем покинуть текущую страницу, пожалуйста, решите, что делать с несохраненными изменениями",
      modalSureUnsavedChangesDontSave: "Не сохранять",
      modalSureUnsavedChangesSave: "Сохранить",
      telegramTokenCopied: "Токен квиза скопирован в буфер обмена",
      cSettingsSettingQuizBackButtonTitle: "Кнопка назад",
      cSettingsSettingQuizBackButtonDescription: "Вы можете включить или выключить кнопку возврата на предыдущий шаг. Это позволит изменять данный ранее ответ.",
      cSettingsCustomThemePrimaryColor: "Цвет акцентов",
      cSettingsCustomThemeSecondaryColor: "Фоновый цвет",
      cSettingsCustomThemeAdditionalColor: "Цвет текста",
      cSettingsCustomThemeButtonTextColor: "Текст в кнопках",
      cSettingsCustomThemeModeDark: "Тёмная",
      cSettingsCustomThemeModeLight: "Светлая",
      allstepsPlaceholder: "Плейсхолдер",
      allstepsCustomInput: "Свой вариант",
      allstepsCustomInputOn: "Включить",
      allstepsCustomPlaceholder: "Введите свой вариант",
      trashcanTitle: "Корзина",
      trashcanImportant: "Важно!",
      trashcanDescription: "Квиз будет автоматически удалена без возможности восстановления через 30 дней после того, как он был перемещён в корзину.",
      trashcanClearAll: "Очистить всё",
      quizSlotDeletedData: "Удалено",
      workboardLeads: "Лиды",
      workboardDuplicate: "Дублировать",
      leadsTable: "Список лидов",
      exportInfo: "Экспорт лидов",
      leadHide: "Скрыть",
      leadShowMore: "Подробнее",
      poperTrashcan: "Корзина",
      restoreQuizSendedTitle: "Запущена задача восстановления",
      restoreSuizSendedImportant: "Важно",
      restoreQuizSendedDescription: "В зависимости от того, как много лидов было в вашем квизе, восстановление квиза, может занять от нескольких секунд до 2-х минут. После восстановления квиз появится на вашем основном воркборде.",
      ok: "Хорошо",
      month: "0 месяцев | {n} месяц | {n} месяца | {n} месяцев",
      discount: "Скидка до",
      saveMoney: "Подписка на год",
      upProVersionMobile: "На PRO",
      upProVersionDesktop: "Перейти на PRO",




      csettingsIntegrationSendPulse: "Интеграция - Send Pulse",
      csettingsIntegrationMake: "Интеграция - Make",
      csettingsIntegrationZapier: "Интеграция - Zapier",
      csettingsIntegrationCRMBitrix: "Интеграция - CRM Bitrix",
      csettingsIntegrationPipeDrive: "Интеграция - Pipe Drive",
      csettingsIntegrationRemOnline: "Интеграция - Rem Online",
      csettingsIntegrationKeyCRM: "Интеграция - Key CRM",
            
      csettingsIntegrationZapierAbout: "Вставьте URL-адрес для отправки данных, предоставленный Zapier, в поле для ввода.",
      csettingsIntegrationMakeAbout: "Вставьте URL-адрес для отправки данных, предоставленный Make, в поле для ввода.",
      csettingsIntegrationCRMBitrixAbout: "Вставьте URL-адрес для отправки данных, предоставленный CRM Bitrix, в поле для ввода.",
      csettingsIntegrationSendPulseAbout: "Вставьте id клиента, секретный ключ, адресную книгу и действие Send Pulse, в соответствующие поля для ввода.",
      csettingsIntegrationKeyCRMAbout: "Вставьте API ключ и PipelineID, в соответствующие поля для ввода.",
      csettingsIntegrationPipeDriveAbout: "Вставьте токен и домен компании, в соответствующие поля для ввода.",
      csettingsIntegrationRemOnlineAbout: "Вставьте API ключ Rem Online в соответствующее поле для ввода.",
      csettingsIntegrationActiveIntegration: "Активная интеграция",
      cintegrationIntegrationData: "Данные интеграции",
      cintegrationIntegrationUrl: "URL интеграции",
      cintegrationWriteUrl: "Введите URL",
      cintegrationDataFBError: "Неверный идентификатор пикселя",
      cintegrationDataWebHookError: "Неверный адрес вебхука",
      cintegrationZapierDataSaved: "Данные интеграции с Zapier сохранены",
      cintegrationDataZapierError: "Неверный адрес интеграции",
      cintegrationAPIxDriveDataSaved: "Данные интеграции с APIxDrive сохранены",
      cintegrationDataAPIxDriveError: "Строка не является адресом интеграции APIxDrive ",
      cintegrationMakeDataSaved: "Данные интеграции с Make сохранены",
      cintegrationDataMakeError: "Неверный адрес интеграции",
      cintegrationSendPulseDataSaved: "Данные интеграции с SendPulse сохранены",
      cintegrationDataSendPulseError: "Невозможные или пустые данные интеграции",
      cintegrationPipeDriveDataSaved: "Данные интеграции с PipeDrive сохранены",
      cintegrationDataPipeDriveError: "Невозможные или пустые данные интеграции",
      cintegrationRemOnlineDataSaved: "Данные интеграции с RemOnline сохранены",
      cintegrationDataRemOnlineError: "Невозможные или пустые данные интеграции",
      cintegrationKeyCRMDataSaved: "Данные интеграции с KeyCRM сохранены",
      cintegrationDataKeyCRMError: "Невозможные или пустые данные интеграции",
      cintegrationInputEmailUrl: "Введите Email",
      cintegrationEmailDataSaved: "Email сохранен",
      cintegrationDataEmailError: "Введенная строка не является Email",
      cintegrationCRMBitrixDataSaved: "Email сохранен",
      cintegrationDataCRMBitrixError: "Введенная строка не является Email",
      cintegrationTelegramBotDataSaved: "Настройки Telegram интеграции сохранены",
      cintegrationDataTelegramBotError: "Ошибка сохранения",
      cintegrationGTMDataSaved: "Идентификатор Google Tag Manager сохранен",
      cintegrationDataGTMError: "Неверный идентификатор",
      cintegrationDataGTMErrorDescription: "Идентификатор должен иметь вид GTM-XXXXXX",
      cintegrationGoogleAnalyticsDataSaved: "Идентификатор Google Analytics сохранен",
      cintegrationDataGoogleAnalyticsError: "Неверный идентификатор",
      cintegrationDataGoogleAnalyticsErrorDescription: 'Идентификатор должен иметь вид "UA-XXXXXXX-X" или "G-XXXXXXXXXX-XX"',
      cintegrationTikTokDataSaved: "Tik-Tok пиксель сохранен",
      cintegrationDataTikTokError: "Введенная строка не является Tik-Tok пикселем",
      cintegrationIntegrationToken: "Токен интеграции",
      cintegrationWriteToken: "Введите токен интеграции",
      cintegrationDataYourAPIKey: "Введите ваш API ключ",
      viewQuizInMobile: "Просмотреть квиз",
      notificationCSRFTokenMismatch: "Несоответствие CSRF токена",
      notificationReloadPage: "Перезагрузите страницу и попробуйте снова",
      csettingsIntegrationKeyRemOnline: "Вставьте токен и идентификатор канала в соответствующие поля",
      cintegrationDataYourIntegrationToken: "API ключ",
      cintegrationDataLeadTypeId: "Идентификатор канала",
      send_utm: "Отправлять UTM метки",
      send_quiz: "Отправлять данные квиза",
      send_answers: "Отправлять ответы",
      csettingsIntegrationKeyCrm: "Интеграция KeyCRM",
      cintegrationSendPulseClientId: "ID клиента",
      cintegrationSendPulseClientSecret: "Secret",
      cintegrationSendPulseAddressBookId: "ID адресной книги",
      cintegrationSendPulseAction: "Действия с контактом",
      sendpulseActionAddContact: "Добавить контакт",
      sendpulseActionDeleteContact: "Удалить контакт",
      cintegrationPipeDriveToken: "API токен",
      cintegrationPipeDriveDomain: "Ваш уникальный субдомен",
      cintegrationKeyCrmApiKey: "API ключ",
      cintegrationKeyCrmPipelineId: "Идентификатор воронки",
      cintegrationMenuGoogleTagManager:"Google Tag Manager",
      cintegrationMenuGoogleAnalytics:"Google Analytics",
      cintegrationMenuFacebookPixel:"Facebook pixel",
      cintegrationMenuTikTokPixel:"TikTok Pixel",
      cintegrationMenuWebHook:"Webhook",
      cintegrationMenuZapier:"Zapier",
      cintegrationMenuAPIxDrive:"APIx Drive",
      cintegrationMenuMake:"Make",
      cintegrationMenuSendPulse:"Send Pulse",
      cintegrationMenuEmail:"Email",
      cintegrationMenuTelegramBot:"Telegram-бот",
      cintegrationMenuPipeDrive:"PipeDrive",
      cintegrationMenuRemOnline:"Rem Online",
      cintegrationMenuKeyCRM:"Key CRM",
      cintegrationMenuCRMBitrix:"CRM Bitrix",
      trashcanRestoreQuiz: "Восстановить квиз",
      cformValuePromotionAccepted: "Соглашаюсь на Email рассылку",


      constructorAddIntegration: "Добавить новую интеграцию",
      cintegrationKeyCrmSourceId: "Идентификатор источника (необязательно)",
      cintegrationKeyCrmManagerId: "Идентификатор менеджера (необязательно)",
      cSettingsSettingQuizSpamFilterTitle: "Защита от спама",
      cSettingsSettingQuizSpamFilterDescription: "Функция по умолчанию отключена. Если вы столкнулись с проблемой спамовых лидов, включите защиту. После этого один пользователь не сможет отправить больше указанного количества лидов на этот квиз.",
      cSettingsSettingQuizSpamFilterLimitPlaceholder: "Количество лидов",
      langNO: "Норвежский",
      loadMoreLeads: "Загрузить больше лидов",
      userAdditionalSettingsHeader: "Дополнительные настройки",
      userAdditionalSettingsDescription1: "Здесь можно настроить новые дополнительные параметры профиля.",
      userAdditionalSettingsPagination: "Пагинация на воркборде",

      benefitType: "Тип бонуса",
      benefitLabelPlaceholder: "Текст бонуса",
      benefitDescriptionPlaceholderDiscount: "Промокод или инструкция",
      benefitDescriptionPlaceholderVideo: "Ссылка на видео",
      benefitDescriptionPlaceholderTextfile: "Ссылка на текстовый файл",
      benefitDescriptionPlaceholderGift: "Описание бонуса",
      benefitDescriptionPlaceholderPDF: "Ссылка на PDF",
      benefitDescriptionPlaceholderLink: "Ссылка",
      benefitDescriptionPlaceholderFile: "Ссылка на файл",
      benefitLabelDiscount: "Скидка",
      benefitLabelVideo: "Видео",
      benefitLabelTextfile: "Текстовый файл",
      benefitLabelGift: "Подарок",
      benefitLabelPDF: "PDF файл",
      benefitLabelLink: "Ссылка",
      benefitLabelFile: "Файл",
      addBenefit: "Добавить бонус",
      saveBenefit: "Сохранить бонус",
      cfinishSocialNetworks: "Социальные сети",

      saveSocialNet: "Сохранить социальную сеть",
      networkLabelFacebook: "Facebook",
      networkLabelInstagram: "Instagram",
      networkLabelTelegram: "Telegram",
      networkLabelTiktok: "Tik-Tok",
      networkLabelYoutube: "Youtube",
      networkLabelLinkedin: "LinkedIn",
      networkLabelWhatsapp: "WhatsApp",
      socialNetDescriptionPlaceholderFacebook: "имя_профиля",
      socialNetDescriptionPlaceholderInstagram: "имя_профиля",
      socialNetDescriptionPlaceholderTelegram: "имя_профиля/канала",
      socialNetDescriptionPlaceholderTiktok: "имя_профиля",
      socialNetDescriptionPlaceholderYoutube: "имя_канала",
      socialNetDescriptionPlaceholderLinkedin: "in/name or company/name",
      socialNetDescriptionPlaceholderWhatsapp: "+15555551234",

      addNewSectionEmptyPage: "Текстовая страница",
      csanyMakeImpasse: "Сделать тупиком",
      impaseIsImpaseTrue: "Включено",
      impaseIsImpaseFalse: "Выключено",
      defaultNewEmptyQuestionDescription: "Сюда можно вставить и отформатировать длинный текст (не рекомендуем вставлять более 3000 символов).",
      cSettingsSettingQuizTimezoneTitle: "Часовой пояс квиза",
      cSettingsSettingQuizTimezoneDescription: "Введите часовой пояс, по которому будет указан время заполнения лидов. Это особенно удобно, если вы находитесь в одном часовом поясе, а клиент — в другом. В таком случае вы можете указать часовой пояс клиента, и в лидах будет указан время по его часовому поясу.",
      cSettingsSettingQuizTimezonePlaceholder: "Часовой пояс",
      userAdditionalSettingsTimezone: "Часовой пояс пользователя",
      userAdditionalSettingsTimezonePlaceholder: "Часовой пояс",
      langHE: "Иврит",
      langCS: "Чешский",
      cintegrationCAPIFBInstruction: "Чтобы настроить интеграцию с Facebook Conversion API, вам необходимо ввести идентификатор пикселя и маркер доступа. Эти данные можно получить в настройках Facebook Business Manager. После того как вы ввели необходимые данные, сохраните интеграцию.",
      csettingsIntegrationFbCapiAbout: "Интеграция с Facebook Conversion API позволяет передавать данные о взаимодействиях пользователей напрямую в Facebook для улучшения эффективности рекламных кампаний.",
      cintegrationInputCapiAccessToken: "Введите маркер доступа",
      cintegrationInputCapiCustomparameter: "Введите кастомные параметры событий",
      cintegrationDataFbCapiSaved: "Интеграция с Facebook Conversion API успешно сохранена",
      cintegrationComonRenovateHubInstructionTitle: "Инструкция по настройке интеграции с RenovateHub",
      cintegrationComonRenovateHubInstructionDescription: "Для настройки интеграции с RenovateHub укажите URL вебхука, на который будут отправляться данные. Убедитесь, что URL правильный и доступен для приёма данных.",
      cintegrationComonRenovateHubInstructionShort: "Интеграция с RenovateHub позволяет передавать данные в СРМ через вебхук для автоматизации процессов.",
      cintegrationRenovateHubUrl: "Введите URL RenovateHub",
      cintegrationInputRenovateHubUrl: "Укажите URL вебхука для RenovateHub",
      cintegrationRenovateHubDataSaved: "Интеграция с RenovateHub успешно сохранена",
      cintegrationDataRenovateHubError: "Ошибка при сохранении интеграции с RenovateHub. Проверьте правильность введённых данных.",
      cintegrationMenuFacebookConvAPI: "Facebook Conversions API",
      cintegrationMenuRenovateHub: "RenovateHub",
      csettingsIntegrationRenovateHub: "Интеграция с RenovateHub",
      csettingsIntegrationCAPIFB: "Интеграция с Facebook Conversions API",
      paymentPlanEnd: "Дата окончания тарифа",
      paymentPlanLeadsLimitForYear: "Лимит лидов на год:",
      paymentPlanLeadsLimitForPeriod: "Лимит лидов на период:",
      EditTags: "Изменить теги",
      quizzesTemplatesNoTags: "Без тегов",


      Monday: "Понедельник",
      Tuesday: "Вторник",
      Wednesday: "Среда",
      Thursday: "Четверг",
      Friday: "Пятница",
      Saturday: "Суббота",
      Sunday: "Воскресенье",
      datetimeDisplayMode: "Тип выбора",
      datetimeDisplayModeSelect: "Выберите тип",
      datetimeDisplayModeDate: "Дата",
      datetimeDisplayModeTime: "Время",
      datetimeDisplayModeDatetime: "Дата и время",
      datetimeMinDate: "Минимальная дата",
      datetimeMinDateCustom: "Укажите минимальную дату",
      datetimeMaxDate: "Максимальная дата",
      datetimeMaxDateCustom: "Укажите максимальную дату",
      datetimeProhibitDaysOfWeek: "Запретить дни",
      datetimeIsRange: "Диапазон",
      datetimeIsRangeTrue: "Диапазон включен",
      datetimeIsRangeFalse: "Диапазон выключен",
      datetimeMinTime: "Минимальное время",
      datetimeMaxTime: "Максимальное время",
      datetimeIs24: "24-часовой формат",
      datetimeIs24True: "Формат 24 ч включен",
      datetimeIs24False: "Формат 24 ч выключен",
      csanyIsExactDate: "Как вы хотите настроить дату",
      csanyIsExactDateTrue: "Точная дата",
      csanyIsExactDateFalse: "Относительная дата",
      csanyDatetimeMinMaxNotExactPlaceholder: '"x" или "-x" дней от сегодня',
      addNewSectionDatetime: "Дата/время",


    },
    DE: {
      madeInUkr: "In Ukraine hergestellt",
      upProVersion: "Wechsel zu Pro",
      getLeads: "Kaufen Sie Leads",
      poperBillings: "Zahlung",
      poperTemplates: "Vorlagen",
      poperPartnership: "Partnerschaft",
      poperProfile: "Profil",
      poperLogout: "Abmelden",
      defaultNewQuestion: "Neue Frage",
      defaultNewQuestionDescription: "Hier können Sie eine kurze Beschreibung der Frage einfügen (bis zu 80 Zeichen)",
      defaultNewVariant: "Neue Option",
      defaultBtnText: "Start",
      navBillings: "Zahlung",
      navPartnership: "Partnerschaft",
      navProfile: "Profil",
      welcomeBack: "Willkommen zurück!",
      loginHeader: "Anmeldung zu meinem Koto",
      loginHeader2Part: "to Continue",
      loginEmailPlaceholder: "E-mail",
      loginPasswordlPlaceholder: "○ ○ ○ ○ ○ ○ ○ ○ ○",
      loginBtnValue: "Anmelden",
      loginWith: "Anmelden mit",
      passwordAreForgetPassword: "Haben Sie Ihr Passwort vergessen?",
      passwordResetPasswordHeader: "Passwort zurücksetzen",
      passwordResetPassword: "Zurücksetzen Passwort",
      passwordWeSentReset: "Wir haben Ihnen eine E-Mail zum Zurücksetzen Ihres Passwortes geschickt.",
      passwordInputNewPassword: "Neues Passwort eingeben",
      passwordConfirmPassword: "Passwort bestätigen",
      passwordChangePassword: "Passwort speichern",
      passwordResetedPasswordCompleted: "Ihr Passwort wurde erfolgreich geändert!",
      passwordGoToWorkboard: "Zum Workboard gehen",
      loginSignup: "Registrieren Sie sich",
      helloHeader: "Hallo!",
      signupHeader: "Registrieren",
      signupHeader2Part: "weitermachen",
      signupNamePlaceholder: "Oleksander",
      signupPhonePlaceholder: "Telefonnummer",
      signupEmailPlaceholder: "E-mail",
      signupPasswordlPlaceholder: "○ ○ ○ ○ ○ ○ ○ ○ ○",
      signupBtnValue: "Ein Konto erstellen",
      signupWith: "Anmelden mit",
      signupLogin: "Anmeldung zum Konto",
      signupTariffHeader: "Tarif",
      signupTariffFree: "Kostenslos.",
      signupTariffPro: "Pro.",
      signupTariffFreeDescription: "Unbegrenzte Anzahl der Erstellung von neuen Quizzen (Webseiten). Die Möglichkeit kostenlos bis zu 300 Anwendungen pro Monat zu erstellen.",
      signupTariffProPrice: "$20/Monat",
      signupTariffProDescription: "Unbegrenzte Anzahl der Erstellung von neuen Quizzen (Webseiten). Die Möglichkeit zum Erstellen",
      signupTariffProDescriptionBold: "von bis zu 300 Anwendungen pro Monat. ",
      userBackToQuiz: "Zurück zur Arbeitstafel",
      userBackToSettings: "Zurück zu den Einstellungen",
      billingYourTariff: "Ihr Plan",
      billingYourPro: "Pro",
      billingYorFree: "Kostenlos",
      billingFreeLeads: "Kostenlose Leads",
      billingInfinity: "unbegrenzt",
      billingTariffRenewData: "Datum der Verlängerung des Tarifs",
      billingTariffEndData: "Enddatum des Plans",
      billingOtherTariffFree: "Kostenlos.",
      billingOtherTariffPro: "Pro.",
      billingTariffFreeDescription: "Unbegrenzte Anzahl der Erstellung von neuen Quizzen (Webseiten). Die Möglichkeit kostenlos bis zu 300 Anwendungen pro Monat zu erstellen.",
      billingYouHavePro: "Wenn Sie derzeit die Pro-Version verwenden, warum sollten Sie einen Schritt zurück gehen?",
      billingYouHasFreeLeads: "Kostenlose Leads",
      billingYouHasLeads: "Verbleibende Leads",
      billingTariffProDescription: "Unbegrenzte Anzahl der Erstellung von neuen Quizzen (Webseiten). Die Möglichkeit zum Erstellen",
      billingTariffProDescriptionBold: "von mehr als 300 Anwendungen",
      billingYourFutureTariff: "Ihr zukünftiger Plan",
      billingBtnChangeToPro: "Wechsel zu Pro",
      billingBtnRenewPro: "Verlängern",
      billingBillings: "Zahlung",
      billingYouHaveNotBillings: "Sie haben noch keine Zahlung",
      billingPaymentsMethod: "Zahlungsweise",
      billingPaymentCard: "Karte",
      billingChangeData: "Daten ändern",
      billingDeletePayMethod: "Löschen",
      billingModalDeleteShure: "Sind Sie sicher, dass Sie diese Zahlungsmethode entfernen möchten?",
      billingModalDeleteCancel: "Abbrechen",
      billingModalDeleteOk: "Löschen",
      billingModalDeleteSuccess: "Wir haben Ihre Karte erfolgreich gelöscht",
      billingModalRegularPaymentDeleteSuccess: "Regelmäßige Zahlungen werden eingestellt",
      billingModalRegularPaymentDeleteOk: "Ok",
      billingYourMonthPlan: "Dein monatlicher Tarif",
      billingLeads: "Leads",
      billingChangePlan: "Ändere den Tarif",
      billingChangePlanDescription: "Der Preis richtet sich nach der gewünschten Anzahl von Bewerbern aus alle Quizzen Ihres Kontos",
      billingSelectPeriod: "Zeitraum auswählen",
      billingChangePlanDescriptionImportant: "Wichtig:",
      billingChangePlanDescriptionImportantMonth: "Die Leads können 1 Monat nach dem Kauf verwendet werden. ",
      subscriptionTarifLable: "Abonnieren Sie unser automatisches Monatsabonnement. Sie können Ihren Plan jederzeit ändern.",
      billingChangePlanWarning: "",
      billingTariffMonth: "mon.",
      billingChangePlanPay: "Tarif ändern",
      profileName: "Name",
      profilePhone: "Telefonnummer",
      profileEmail: "E-Mails",
      profileStatus: "Status",
      profileStatusAgency: "Agentur",
      profileStatusPlaceholder: "Was beschreibt Sie besser?",
      profileStatusFreelancer: "Freiberufler",
      profileStatusBuisines: "Geschäftsinhaber",
      profilePassword: "Passwort",
      profileChangePassword: "Passwort ändern",
      profileNewPassword: "Neues Passwort",
      profileSaveNewData: "Daten aktualisieren",
      workboardAddNewQuiz: "Projekt erstellen",
      workboardModalGiveQuizName: "Geben Sie dem Quiz einen Namen",
      workboardModalQuizNamePlaceholder: "Neues Quiz",
      workboardModalNewQuizBtnValue: "Hinzufügen",
      workboardAlertGiveAnyName: "Tragen Sie einen beliebigen Namen ein",
      workboardAlertNextTimeAddQuiz: "Na ja, nächstes Mal sollten Sie sich trauen.",
      workboardChangeQuizName: "Ändern Sie den Namen ",
      workboardQuizLink: "Link",
      workboardQuizLinkCopied: "Der Link wird in die Zwischenablage kopiert",
      workboardEditQuiz: "Bearbeiten",
      workboardCopyQuiz: "Quiz kopieren",
      workboardQuizCopied: "Quiz erfolgreich kopiert",
      workboardDeleteQuiz: "Löschen",
      workboardModalDeleteQuizIrreversible: "Diese Aktion ist unumkehrbar!",
      workboardModalDeleteQuizSure: "Sind Sie sicher, dass Sie dieses Quiz entfernen möchten?",
      workboardModalDeleteQuizCancel: "Abbrechen",
      workboardModalDeleteQuizDelete: "Löschen",
      workboardModalDeleteQuizSuccess: "Sie haben das Quiz erfolgreich gelöscht",
      workboardModalQuizDeleteOk: "Ok",
      workboardStatsTitle: "Statistik",
      workboardModalLeadsAnswersCount: "Antworten auf die Frage",
      workboardModalLeadsLeadSerial: "№",
      workboardModalLeadsLeadDate: "Datum",
      workboardModalLeadsLeadEmail: "Email",
      workboardModalLeadsLeadName: "Namen",
      workboardModalLeadsLeadPhone: "Telefonnummer",
      workboardModalLeadsLeadActions: "Aktionen",
      workboardModalLeadsLeadShowMore: "Mehr sehen",
      workboardModalLeadsLeadHideMore: "Ausblenden",
      workboardModalLeadsLeadAnswerScipped: "Übersprungen",
      workboardModalNoLeads: "Bsiher keine Hinweise",
      constructorLink: "Link",
      constructorLinkCopied: "In die Zwischenablage kopiert: ",
      constructorNavSteps: "Schritte",
      constructorNavDesign: "Entwurf",
      constructorNavSettings: "Einstellungen",
      constructorStep: "Schritte",
      cancel: "Abbrechen",
      constructorCancel: "Abbrechen",
      constructorSave: "Sichern",
      constructorextraNavStart: "S",
      constructorextraNavContact: "C",
      constructorextraNavFinish: "F",
      cstepsSection: "Abschnitte",
      cstepsStartPage: "Startseite",
      cstepsContactPage: "Kontaktformular",
      cstepsFinishPage: "Letzte Seite",
      cstepsEdit: "Bearbeiten",
      cstepsMove: "Verschieben",
      cstepsDeleteStep: "Schritt löschen",
      cstepsAddSection: "Abschnitt hinzufügen",
      cdesignColorTheme: "Farb Design",
      cdesignCustomTheme: "Kundenfarbthema",
      cdesignDefaultThemes: "Unsere Farbthemen",
      cdesignThemeStandart: "Standard",
      cdesignThemeDark: "Schwarz",
      cdesignThemeNud: "Hautfarbe",
      cdesignThemeGradient: "Farbverlauf",
      cdesignShapeDesign: "Design Vorlage",
      cdesignShapeRound: "Gerundet",
      cdesignShapeSquare: "Eckig",
      csettingsIntegration: "Integration",
      csettingsOweDomain: "Eigene Domain verknüpfen",
      csettingsDocs: "Rechtliche Dokumente",
      cintegrationIntegration: "Integration",
      cintegrationShareLeads: "Leads teilen",
      cintegrationTitleEmail: "E-Mail",
      cintegrationInstruction: "Anleitung",
      cintegrationInstructionDetail: "Einzelheiten",
      cintegrationEmailInstruction: "Schicken Sie eine E-Mail an Ihren Befrgaten aus und banachrichtigenb Sie Ihr Team. Arbeiten Sie mit Ihrem Team zusammen.",
      cintegrationPublishAfterEdit: "Speichern Sie das Quiz nach der Bearbeitung",
      cintegrationSendNewLeadsTo: "Sende Sie eine Nachricht über neue Leads an: ",
      cintegrationInputEmail: "Eingabe E-Mail",
      cintegrationAddEmail: "E-Mail hinzufügen",
      cintegrationEmailSaved: "E-Mail sichern",
      cintegrationEmailDeleted: "E-Mail löschen",
      cintegrationTitleFB: "FB pixel ",
      cintegrationIDPixel: "Pixel ID",
      cintegrationFBInstruction1: "Gehen Sie zum Facebook Ads-Bereich und kopieren Sie die Facebook Pixel-ID in den Event Manager-Bereich.",
      cintegrationFBInstruction2: "Kopieren Sie nicht den gesamten Code, sondern nur die 15-16-stellige ID.",
      cintegrationFBInstruction3: "Kopieren und in das unten stehende Feld einfügen. ",
      cintegrationFBInstruction: 'Das Ereignis, für das Sie die Konvertierung konfigurieren müssen, ist "Lead". Diese Veranstaltung wird aufgerufen, sobald der Benutzer eine Bewerbung im Quiz abgibt.',
      cintegrationInputIDPixel: "Eingabe Pixel-ID",
      cintegrationDataFBSaved: "Pixel FB-Daten gespeichert",
      cintegrationAccesToken: "Zugangstoken",
      cintegrationTitleGA: "Google Analytics",
      cintegrationGAInstructionDescription: "Erfahren Sie, wie Nutzer Ihr Quiz finden und damit interagieren. Erhalten Sie hierzu die Daten, die Sie benötigen, um Ihre Kampagnen zu analysieren, Konversionen zu verbessern und vieles mehr.",
      cintegrationGAInstruction: 'Wenn eine Quiz-Bewerbung in Google Analytics eingeht, wird das Ereignis "generate_lead" ausgelöst.',
      cintegrationIDFolow: "Tracking-ID",
      cintegrationInputID: "Eingabe-ID",
      cintegrationDataGASaved: "Gespeicherte Google-Analytics-Daten",
      cintegrationTitleGTM: "Google Tag Manager",
      cintegrationGTMInstructionDescription: "Beschreibung für Google Tag Manager.",
      cintegrationGTMInstruction: "Kopieren Sie die Google Tag Manager-ID und fügen Sie diese in das unten stehende Feld ein.",
      cintegrationDataGTMSaved: "Google Tag Manager Daten gespeichert",
      cintegrationTitleTG: "Telegram",
      cintegrationHowReciveTGLeadBot: "Wie bekommt man Leads in Telegram?",
      cintegrationTGInstructionBot1: "1. Kopieren Sie das Token in das unten stehende Eingabefeld.",
      cintegrationTGInstructionBot2: '2. Folgen Sie dem Link zum Bot, klicken Sie auf den "Start" Button.',
      cintegrationTGInstructionBot3: "3. Senden Sie das kopierte Token als Nachricht an unseren Bot.",
      cintegrationHowReciveTGLeadChat: "Wie bekommen Sie Bewerbungen im Telegram-Chat?",
      cintegrationTGInstructionChat1: "1. Fügen Sie unseren Bot zum gewünschten Chat hinzu und setzen Sie den Bot auf den Administratorstatus.",
      cintegrationTGInstructionChat2: "2. Kopieren Sie den Token in das unten stehende Feld und senden Sie ihn an den gewünschten Chat.",
      cintegrationTGInstructionChat3: '3. Warten Sie auf die Antwort "OK! Ihr Quiz-Token wurde gespeichert!"',
      cintegrationTGInstructionChat4: "4. Die Leads werden nun an diesen Chat gesendet.",
      cintegrationTGInstructionWarning: "Wichtig:",
      cintegrationTGInstructionWarningText: "Bitte beachten Sie, dass es sich um eine Gruppe und nicht um einen Telegram-Kanal handeln sollte. Leads werden nicht an den Telegram-Kanal gesendet.",
      cintegrationTGBotLink: "Chatbot Link",
      cintegrationTGTitleToken: "Token",
      cintegrationInputTGToken: "Telegram Chatbot Token eingeben",
      cintegrationAddIntegration: "Integration hinzufügen",
      cintegrationDataTGSaved: "Telegram-Integrationsdaten gespeichert",
      cintegrationTitleTT: "TT Pixel",
      cintegrationTTInstruction: "Um Ereignisse in Ihrem Tik-Tok-Konto zu verfolgen, kopieren Sie die Pixel-ID und fügen Sie diese in das unten sthendde Feld ein.",
      cintegrationDataTTSaved: "Pixel TT-Daten gespeichert",
      cintegrationTitleWH: "WebHook",
      cintegrationWHInstruction1: "Sie können Anwendungen von Drittanbeitern über eingehende Leads benachrichtigen. Geben Sie dazu die URL an, an die der WebHooker gesendet werden soll. ",
      cintegrationWHInstruction2: "Wir haben vorbereitet",
      cintegrationWHInstruction3: "Video Anleitung",
      cintegrationWHInstruction4: "mit einer ausführlichen Beschreibung dieser Funktionalität. ",
      cintegrationWHInstruction5: "Siehe Video.",
      cintegrationWHInstruction6: "In diesem Video sehen wir die Übergabe eines Hooks, der afu dem Senden von Daten an ein Google Spreadsheet und dem Senden von Daten an ein Trello CRM-System basiert.",
      cintegrationWHInstruction7: "Wir empfehlen, den Dienst zu diesem Zweck zu nutzen.",
      cintegrationWHInstruction8: "Wenn Sie sich über diesen Link registrieren, gibt es für Sie als Nutzer einen Bonus von 10.000 kostenlosen Aktionen für alle Integrationen. ",
      cintegrationWHInstruction9: "Link",
      cintegrationWHInstruction10: "um sich für den Bonus zu registrieren. ",
      cintegrationWHUrl: "Die Webhook-URLs",
      cintegrationInputWHUrl: "Eingabe der Webhook-URL",
      cintegrationAddWH: "Hinzufügen eines Web-Hook",
      cintegrationWebHookDataSaved: "Web-Hook Daten gesichert",


      cintegrationComonWHInstructionShort: "Sie können Drittanbieteranwendungen über eingehende Leads informieren. Geben Sie die Webhook-URL für die Datenübermittlung an.",
      cintegrationComonWHInstruction1Title: "1. Fügen Sie die Webhook-URL in das Eingabefeld ein:",
      cintegrationComonWHInstruction1Description: "Fügen Sie die Webhook-URL, die Ihr Service oder Ihre Anwendung bereitstellt, in das Eingabefeld ein.",
      cintegrationComonWHInstruction2Title: "2. Änderungen speichern:",
      cintegrationComonWHInstruction2Description: 'Klicken Sie auf die Schaltfläche "Änderungen speichern", um die Einstellungen des Webhooks zu speichern.',
      cintegrationComonWHInstruction3Title: "3. Fertig:",
      cintegrationComonWHInstruction3Description: "Ihr Webhook ist jetzt so konfiguriert, dass Daten an den von Ihnen ausgewählten Service gesendet werden.",
      cintegrationComonWHInstructionAdditional: "Daten werden bei jedem empfangenen Lead an den Webhook gesendet. Die Übertragung erfolgt über die POST-Methode. Ein Beispiel für die gesendeten Daten finden Sie ",
      cintegrationComonWHInstructionAdditionalLink: "hier",
      cintegrationComonWHInstructionVideo: "Ausführliche Videoanleitung",
      
      cintegrationApixDriveWHInstructionShort: "Sie können ApixDrive über eingehende Leads informieren. Geben Sie dazu die URL an, an die der WebHook gesendet wird.",
      cintegrationApixDriveWHInstructionShort1: "Bei der Anmeldung über ",
      cintegrationApixDriveWHInstructionShort2: "diesen Link",
      cintegrationApixDriveWHInstructionShort3: " erhalten unsere Benutzer einen Bonus von 10.000 kostenlosen Aktionen für beliebige Integrationen.",
      cintegrationApixDriveWHInstruction1Title: "1. Registrieren Sie sich oder öffnen Sie Ihr ApixDrive-Konto:",
      cintegrationApixDriveWHInstruction1Description1: "Link ",
      cintegrationApixDriveWHInstruction1Description2: "für die Anmeldung mit Bonus.",
      cintegrationApixDriveWHInstruction2Title: "2. Holen Sie sich die URL-Adresse für den Webhook:",
      cintegrationApixDriveWHInstruction2Description1: "Gehen Sie zu den Einstellungen der erforderlichen Verbindung und kopieren Sie die Adresse für den Versand des Webhooks:",
      cintegrationApixDriveWHInstruction2Description2: 'Sie können eine neue Verbindung erstellen, indem Sie auf die Schaltfläche "Verbindung erstellen" klicken und im Schritt zur Auswahl des Systems "ADSQuiz" auswählen.',
      cintegrationApixDriveWHInstruction3Title: "3. Fügen Sie die URL des Webhooks ein:",
      cintegrationApixDriveWHInstruction3Description: "Fügen Sie die vom ApixDrive bereitgestellte URL des Webhooks in das Eingabefeld ein.",
      cintegrationApixDriveWHInstruction4Title: "4. Speichern Sie die Änderungen:",
      cintegrationApixDriveWHInstruction4Description: 'Klicken Sie auf die Schaltfläche "Änderungen speichern", um die Einstellungen des Webhooks zu speichern.',
      cintegrationApixDriveWHInstruction5Title: "5. Fertig:",
      cintegrationApixDriveWHInstruction5Description: "Ihr Webhook ist jetzt so konfiguriert, dass Daten an ApixDrive gesendet werden.",
      cintegrationApixDriveWHInstructionAdditional: "Daten werden bei jedem empfangenen Lead an den Webhook gesendet. Die Übertragung erfolgt über die POST-Methode. Ein Beispiel für die gesendeten Daten finden Sie ",
      cintegrationApixDriveWHInstructionAdditionalLink: "hier",
      cintegrationApixDriveWHInstructionVideo: "Ausführliche Videoanleitung",
      



      cintegrationConectOweDomain: "Veerknüpfung mit Ihrer eigenen Domain",
      cintegrationOweDomainInstruction1: "1. Um Ihr Quiz auf der gewünschten Domain zu platzieren, loggen Sie sich in Ihr Hostiung-Konto ein. Gehen Sie zu den DNS-Einstellung und erstellen Sie einen A-Eintrag mit dem IP-Parameter: 185.25.117.21",
      cintegrationOweDomainInstruction2: "2. Geben Sie die Domain in das Feld oben ein und speichern Sie das Quiz.",
      cintegrationDomain: "Domain",
      cintegrationInputYourDomain: "Eingabe Ihrer Domain",
      cintegrationCheckDomenIsCorrect: "Prüfen Sie, ob die eingegebene Domain korrekt ist",
      cintegrationDomainWhichCorrect: "Geben Sie eine Domain ohne Protokolle ein. Zum Beispiel: some.site.com",
      cintegrationInsertedDefaultDomain: "Die Domain ist standardmäßig eingestellt",
      cintegrationDomainDataSaved: "Domaindaten gesichert",
      cintegrationPrivacyPolicy: "Datenschutzbestimmungen",
      cintegrationPrivacyPolicyByDefault: "Standardmäßig verwenden wir unsere Standard-Datenschutzrichtlinie. Wir empfehlen Ihnen, Ihre eigene Datenschutzrichtlinie hinzuzufügen, die für Ihr Unternehmen relevant ist.",
      cintegrationPrivacyPolicyAdventage: "Dadruch wird Ihren potenziellen Kunden deutlich, wie Sie die erhaltenen Kontaktinformationen verwenden. Dies wird auch von einigen Werbeplattformen verlangt.",
      cintegrationPrivacyPolicyUrl: "Link zu Dokumenten",
      cintegrationPrivacyPolicyInputYourDocsLink: "Link zu Doumenten einfügen ",
      cintegrationDocsDataSaved: "Link zu Ihren Dokumenten speichern",
      allstepsUpploadPhoto: "Laden Sie ein Bild hoch",
      allstepsChangePhoto: "Ändern Sie das Bild",
      allstepsDeletePhoto: "Löschen Sie das Bild",
      allstepsTitle: "Titel",
      allstepsDescription: "Beschreibung",
      allstepsButtonText: "Button Text",
      allstepsOtherSettings: "Andere Einstellungen",
      allstepsIsSaveBeforeRoute: "Daten vor dem Ändern der Route speichern?",
      cfirstFormat: "Format",
      cfirstHeaderStartText: "Hier sollte Ihre Überschrift stehen. Motivieren Sie die Nutzer, das Quiz bis zum Ende auszufüllen ",
      cfirstAddMediafile: "Medium (Bild) hinzufügen",
      cfirstBonus: "Bonus",
      cfirstAddBonus: "Bonus hinzufügen",
      cfirstStartPageDataUpdated: "Die Daten auf Ihrer Startseite wurden aktualisiert",
      csanyAnswerType: "Fragen-Typ",
      csanyATCard: "Karte",
      csanyATList: "Liste",
      csanyATCustom: "Freies Feld",
      csanyQuestion: "Frage ",
      csanyAnswers: "Antworten",
      csanyAddAnswer: "Antworten hinzufügen",
      csanyRequiredStep: "Erforderlicher Schritt",
      csanyAlowMultiple: "Mehrfachantworten erlaubt",
      csanyAutoRedirect: "Automatische nächste Frage",
      csanyQuestionLogic: "Einrichtung der Fragelogik",
      csanyDataStepUpdated: "Datenschritt aktualisieren",
      cformForm: "Formular",
      cformValueName: "Name",
      cformValueSurname: "Nachname",
      cformValuePhone: "Telefonnummer",
      cformValueEmail: "E-Mail",
      cformDataContactsPageUpdated: "Datenkontakte-Seite aktualisiert",
      cformRequiredInput: "Pflichtfeld",
      cfinishFinishPageTitle: "Letzte Seite",
      cfinishText: "Text",
      cfinishDescription: "Beschreibung",
      cfinishAddImage: "Fügen Sie ein Bild hinzu",
      cfinishImageAdded: "Bild hinzugefügt",
      cfinishURL: "URL",
      cfinishButtonText: "Button Text",
      cfinishInsertNikname: "Spitznamen einfügen",
      cfinishCheckDataIsCorrect: "Prüfen Sie, ob die eingegebene ID korrekt ist",
      cfinishFbWhichCorrect: "Geben Sie eine ID ohne weitere Adresse ein. Zum Beispiel: some.name",
      cfinishIgWhichCorrect: "Geben Sie eine ID ohne weitere Adresse ein. Zum Beispiel: some.name",
      cfinishTgWhichCorrect: "Geben Sie eine ID ohne weitere Adresse ein. Zum Beispiel: some.name",
      cfinishFinishPageDataUpdated: "Die Daten der letzten Seite wurden aktualisiert",
      adminUsersReg: "Nutzer im System",
      adminNewUsers: "Neuer Nutzer pro Monat",
      adminProAccounts: "Pro Account",
      adminLTV: "LTV",
      adminSharePro: "Pro im Vergleich zu allen anderen",
      adminReciveLeads: "Erhaltende Leads",
      adminLeadsForOne: "Leads pro Nutzer",
      adminMadeQuiz: "Gemachte Quizze",
      adminQuizForOne: "Quiz pro Nutzer",
      adminAvarageUsed: "Durchschnittliche Nutzer pro Monat",
      adminProfitMonth: "Gewinn im Monat",
      adminProfitAlltime: "Verdienst über die gesamte Zeit",
      adminUsersList: "Nutzerbasis",
      notificationErrorLogin: "Autorisierungsfehler:",
      notificationLogin: "Login…",
      notificationWrongLoginData: "Falsche Anmeldedaten",
      notificationNetworkError: "Überprüfen Sie Ihre Internetverbindung",
      notificationInputLoginData: "Anmeldedaten eingeben",
      notificationCheckInputEmail: "Überprüfen Sie , ob Ihre E-Mail-Adresse korrekt ist",
      notificationCheckInputPhone: "Prüfen Sie, ob Ihre Telefonnummer korrekt ist",
      notificationInputAllInputs: "Füllen Sie alle Felder für die Registrierung aus",
      notificationAgreePrivacyPolicy: "Akzeptieren Sie die Datenschutzbestimmungen",
      adminEditorForPartnership: "Editor für Partnerprogramme",
      developmentPage: "Abschnitt befindet sich in der Entwicklung",
      signupAgreeIAgreeWith: "Ich stimme zu",
      signupAgreePolicy: "die Datenschutzbestimmung",
      signupAgreeAnd: "und",
      signupAgreeOffer: "das öffentliche Angebot",
      signupAgreeService: "des Dienstes",
      workboardModalLeadsLeadReferrer: "Unklar",
      workboardModalLeadsLeadReferrerClarification: "Verweis-Daten durch Quelle blockiert oder undefiniert",
      workboardModalLeadsLeadEmailCopied: "E-Mail in die Zwischenablage kopiert",
      workboardModalLeadsLeadPhoneCopied: "Telefonnummer in die Zwischenablage kopiert",
      workboardModalLeadsHaveNotSended: "Sie haben die nicht gesendeten Leads",
      workboardModalLeadsLeadReload: "Empfangen Sie",
      workboardModalLeadsLeadReloadedTitle: "Anfrage erhalten",
      workboardModalLeadsLeadReloaded: "",
      cfirstStartPagePhotoUpdated: "Das Foto auf der Startseite wurde aktualisiert",
      allstepsPhotoTooBig: "Datei ist zu groß!",
      allstepsPhotoMustBeLess: "Muss kleiner sein als",
      cfirstStartPageBonusPhotoUpdated: "Das Bonusfoto wurde aktualisiert",
      cstepsQuestionsOrderSaved: "Reihenfolge der Frage gespeichert",
      csanyAnswerOrderSaved: "Reihenfolgen der Antworten gespeichert",
      allstepsPhotoAdaptation: "Fotoanpassung für Geräte",
      allstepsPhotoFit: "Seitenverhältnis",
      constructorYouHideStep: "Dieser Schritt wurde übersprungen",
      cstepsHiddenStep: "Schritt überspringen",
      cstepsShowStep: "Schritt zeigen",
      cfinishFinishPageHiddenUpdated: "Die Zielseite ist ausgeblendet.",
      cfinishFinishPageHiddenUpdatedGoContact: "Gehen Sie zur Seite Kontakte",
      cfinishFinishPageHiddenUpdatedSetRedirect: "und eine Umleitung nach dem Senden der Daten einrichten",
      cformActionAfterContactRecive: "Aktion nach Erhalt von Kontakten",
      cformActionAfterContactThankyou: "Dankeschön Seite",
      cformActionAfterContactRedirect: "Umleitung",
      cformAdditionalSettings: "Zusätzliche Einstellungen",
      cformRedirectURL: "URL",
      cintegrationOweDomainInstructionImportant: "Wichtig: Die Adresse in Ihrem Hosting-Konto und das obige Feld müssen übereinstimmen. Die Anwendung der Änderung an den DNS-Einstellungen und das Extrahieren des SSL-Zertifikats kann  bis zu 3 Stunden nach dem Speichern in Anspruch nehmen.",
      cintegrationOweDomainInstructionVideo: "Sehen Sie die Video-Anleitung",
      workboardModalLeadsLeadLimit: "Das Limit für die Anzahl der Anfragen wurden erreicht. ",
      workboardModalLeadsLeadLimitInstruction1: "Um neue Leads zu sehen, ",
      workboardModalLeadsLeadLimitInstruction2: "wechseln Sie zu eienm anderen Tarif",
      workboardModalLeadsLeadLimitInstruction3: "den derzeitigen Tarif verlängern",
      workboardModalLeadsLeadLimitInstruction4: "(gilt nicht für „Kostenlos“) oder warten Sie auf die Aktualisierung des Tarifs, wenn Sie eine automatische Verlängerung des Tarifs angeschlossen haben",
      billingBonusesTitle: "Promo-Code",
      billingBonusesDescription: "Geben Sie den Promo-Code, den Sie erhalten haben, in das Feld daneben ein",
      billingBonusesPlaceholder: 'Promocode',
      billingBonusesBtn: "Aktivieren",
      billingBonusesActivated: "Promo-Code aktiviert",
      billingBonusesLeadIncreased: "Die Zahl der verfügbaren Leads wurde erhöht.",
      billingBonusesInvalid: "Flascher Promo-Code",
      billingBonusesNotAvailable: "Promo-Code ist abgelaufen",
      billingBonusesAlreadyActivated: "Der Bonus dieses Promo-Codes wurde bereits eingelöst",
      createQuizSPDescription: "Hier ist Platz für die Kurzbeschreibung",
      createQuizCPTitle: "Wie können wir mit Ihnen Konatkt aufnehmen?",
      createQuizCPDescription: "Bitte füllen Sie das unten stehende Formular aus",
      createQuizCPButton: "Fertig!",
      createQuizFPTitle: "Vielen Dank!",
      createQuizFPDescription: "Jetzt suchen wir nach der besten Lösung für Sie!",
      createQuizFPButton: "Zeigen Sie mir etwas!",
      selectInternationalPrefix: "Internationale Vorwahl wählen",
      autoInternationalPrefix: "Bestimmen Sie das Land automatisch",
      dovnloadCsvTitle: "Daten für den ausgewählten Zeitraum exportieren",
      dovnloadCsvSelectAllPeriod: "Wählen Sie für den gesamten Zeitraum ",
      dovnloadCsvCancel: "Abbrechen",
      dovnloadCsvExport: "Exportieren",
      
      
      
      
      widgetEmbedVidgetToSite: "Quiz auf der eigenen Webseite einbetten",
      widgetEmbedVidgetSelectEmbedVariant: "Einbettungsvariante auswählen",
      widgetEmbedVidgetSitecode: "Im Code der Website",
      widgetEmbedVidgetPopup: "Pop-Up",
      widgetEmbedVidgetChat: "Quiz-Taste",
      widgetEmbedVidgetInProgres: "In Entwicklung",
      widgetEmbedVidgetToSiteCode: "Quiz auf der Webseite einbetten",
      widgetSiteCodeInstruction0: "Um das Quiz auf Ihrer Website einzubinden, fügen Sie den Code aus dem ersten Feld ind en `<head>`-Tag auf Ihrer Webseite oder in Google Tag Manager ein, wenn auf Ihrer Webseite integriert ist. Wenn Sie das Quiz bereits auf ihrer Webseite eingebettet haben und die Domain des Quizzes ändern, müssen Sie den Integrationscode auf Ihrer Webseite aktualisieren.",
      widgetSiteCodeInstruction1: "Fügen Sie an der Stelle, an der Sie das Quiz integrieren möchten, den Code aus dem zweiten Feld ein. ",
      widgetSiteCodeInstruction2: "Das Quiz kann in einen Container beliebiger Größe integriert werden, sogar in Seitenleisten. Die maximale Größe des Quizzes, auch wenn es auf einer separaten Domain stattfindet, beträgt 900x552 Pixel. Es kann in größere Container eingefügt weerden, in diesem Fall wird das Quiz zentriert.",
      widgetSiteCodeInstruction3: "Seien Sie nicht schüchtern - Experimentieren Sie!",
      widgetSiteCodeCopyToHead: "Kopieren und in den `<head>` Ihrer Webseite einfügen",
      widgetSiteCodeCopyToBody: "Fügen Sie den Code dort ein, wo das Quiz stehen soll",
      workboardModalAddQuizHeader: "Neuen Quiz erstellen",
      workboardModalGiveQuizNameAndMethod: "Überlegen Sie sich einen Namen für Ihr Quiz und wählen Sie, wie Sie es erstellen möchten",
      workboardModalNewQuizFromStart: "Leeres Quiz erstellen",
      workboardModalNewQuizFromTemplates: "Fertige Vorlagen",
      QuizTemplatesSearchInput: "Name der Vorlage und/oder Kategorien",
      QuizTemplatesSearchBtn: "Suche",
      QuizTemplatesReadyTemplates: "Fertige Vorlagen",
      QuizTemplateView: "Anzeigen",
      QuizTemplateSelect: "Auswählen",
      widgetPopupInstruction0: "Um ein Quiz auf Ihrer Website zu installieren, fügen Sie den Code aus dem Codefeld zum Tag `<head>` auf Ihrer Website ein oder in den Google Tag Manager, wenn er in Ihre Website integriert ist. Wenn Sie nach dem Einfügen eines Quiz auf Ihrer Website die Quiz-Domain ändern oder Trigger öffnen (Scroll-Prozentsatz oder Timing), müssen Sie den Integrationscode auf Ihrer Website aktualisieren.",
      widgetPopupInstruction1: "Fügen Sie den Code aus dem zweiten Feld direkt vor dem Abschluss-Tag `</footer>` ein.",
      widgetPopupInstruction2: "Für Programmierer oder fortgeschrittene Benutzer",
      widgetPopupInstruction3: "Falls Sie aus irgendeinem Grund nicht den Code vor dem Abschluss-Tag `</footer>` einfügen möchten, können Sie den Code aus dem zweiten Feld an einer beliebigen passenden Stelle einfügen. Beachten Sie jedoch, dass der Container, in den Sie den Code aus dem zweiten Feld einfügen, sich über allen Fenstern befinden und möglichst die maximale Höhe und Breite für die Anzeige haben sollte.",
      widgetPopupCopyToHead: "Kopieren und in das `<head>` Ihrer Website einfügen",
      widgetPopupCopyToBody: "Fügen Sie den Code vor dem Abschluss-Tag `</footer>` ein",
      widgetPopupTimeToOpen: "Zeitspanne",
      secondReduction: "Sek",
      widgetPopupTimeClarification: "Das Popup erscheint mit einer Verzögerung von oben angegebener Zeit in Sekunden",
      widgetPopupScrollToOpen: "Scroll-Prozentsatz der Website",
      widgetPopupScrollClarification: "Das Popup erscheint nach dem angegebenen Scroll-Prozentsatz Ihrer Website",
      quizzesTemplatesAllTags: "Alle Vorlagen",
      widgetEmbedVidgetButton: "Schaltflächenkonstruktor",
      widgetButtonInstruction0: "Sie können auf Ihrer Website ein Quiz einrichten, das geöffnet wird, wenn Sie auf die Schaltfläche klicken.",
      widgetButtonInstruction1: "Fügen Sie der Schaltfläche einen Rahmen, Schatten und Text hinzu. Außerdem können Sie die Farbe der Schaltfläche und den darin enthaltenen Text ändern.",
      widgetButtonInstruction2: "Kopieren Sie das im ersten Codefeld platzierte Skript und fügen Sie es vor dem schließenden Tag `</head>` ein.",
      widgetButtonInstruction3: "Kopieren Sie den generierten Schaltflächencode aus dem zweiten Codefeld und fügen Sie ihn an einer beliebigen Stelle auf Ihrer Website ein.",
      widgetButtonInstruction4: 'Sie können den Code aus dem zweiten Schaltflächenfeld überspringen und einfach `id="button_open_quiz_popup"` zu jedem `<input type="button">`- oder `<button></button>`-Element auf der Website hinzufügen.',
      widgetRealTimeChange: "Sie können die Änderungen in Echtzeit auf der rechten Seite des Bildschirms sehen",
      widgetButtonCornerRound: "Abrundung der Knopfecken",
      widgetButtonShadow: "Knopfschatten",
      widgetButtonBgColor: "Knopffarbe",
      widgetButtonTextColor: "Textfarbe",
      widgetButtonTextValue: "Schaltflächentext",
      openPopupQuizText: "Start",
      widgetButtonInsertBeforeAfterBody: "Fügen Sie den Quizcode unmittelbar nach dem öffnenden Tag `<body>` oder unmittelbar vor dem schließenden Tag `</body>` ein",
      widgetSiteCodeCopyButton: "Fügen Sie den Schaltflächencode an einer beliebigen Stelle auf der Website ein",
      footerContactUs: "Habe Fragen? Fühlen Sie sich frei uns zu kontaktieren:",
      enterYourName: "Gib deinen Namen ein",
      enterYourPhone: "Gib deine Handynummer ein",
      enterYourEmail: "Geben sie ihre E-Mail Adresse ein",
      enterYourPassword: "Geben Sie Ihr Passwort ein",
      selectOption: "Wähle eine Option",
      createNewGroup: "Neue Gruppe erstellen",
      dontHaveAccount: "Sie haben noch kein Konto?",
      resetPasswordTip: "Wenn Sie weitere Probleme oder Fragen haben, wenden Sie sich bitte an unser Kundensupport-Team. Wir sind hier um zu helfen.",
      getBackAccount: "Gehen Sie zurück zu Ihrem Konto",
      inMinutes: "in Minuten",
      alreadyHaveAccount: "Sie haben bereits ein Konto?",
      quizSlotLastEdit: "Letzte Bearbeitung",
      workboardQuizzesTitle: "Meine Quizze",
      allstepsBack: "Zurück",
      constructorGetLink: "Projektlink",
      allOr: "oder",
      allAnd: "und",
      acceptedFileType: "Akzeptierte Formate: ",
      conjunctionAnd: "und",
      acceptedFileSize: "Maximale Größe: ",
      acceptedFileSizeMeasure: "MB",
      cfirstBonusDisabled: "Deaktiviert",
      cfirstBonusAvailable: "Verfügbar",
      allstepsResetInHeader: "Zurücksetzen",
      placeholderText: "Hier eingeben",
      constructorSaveChanges: "Änderungen speichern",
      cformInputAvailable: "Verfügbar",
      cformInputDisabled: "Deaktiviert",
      constructorBack: "Zurück",
      videoInstruction: "Videoanleitung",
      csettingsIntegrationFB: "Integration - Facebook Pixel",
      csettingsIntegrationTT: "Integration - TikTok",
      csettingsIntegrationGA: "Integration - Google Analytics",
      csettingsIntegrationGTM: "Integration - Google Tag Manager",
      csettingsIntegrationWH: "Integration - WebHook",
      csettingsIntegrationTG: "Integration - Telegram",
      csettingsIntegrationApixDrive: "Integration - APIxDrive",
      csettingsIntegrationEmail: "Integration - E-Mail",
      csettingsIntegrationAbout: "Über",
      csettingsIntegrationFBAbout: "Gehen Sie zum Werbekonto von Facebook Ads und kopieren Sie die Facebook Pixel ID im Bereich Event Manager. Kopieren Sie nur die 15-16-stellige ID und fügen Sie sie unten ein.",
      csettingsIntegrationTTAbout: "Um Ereignisse in Ihrem Tik-Tok-Werbeaccount zu verfolgen, kopieren Sie die Pixel-ID und fügen Sie sie unten ein.",
      csettingsIntegrationGAAbout: "Kopieren Sie die Google Analytics-ID und fügen Sie sie in das Feld unten ein.",
      csettingsIntegrationGTMAbout: "Kopieren Sie die Google Tag Manager-ID und fügen Sie sie in das Feld unten ein.",
      csettingsIntegrationWHAbout1: "Sie können Drittanbieteranwendungen über eingehende Leads informieren. Geben Sie dazu die URL an, an die der WebHook gesendet werden soll. Wir empfehlen, diesen Dienst zu diesem Zweck zu verwenden.",
      csettingsIntegrationWHAboutApix: "Apix-Drive",
      csettingsIntegrationWHAbout2: "Bei der Registrierung über diesen Link erhalten unsere Benutzer einen Bonus von 10.000 kostenlosen Aktionen für beliebige Integrationen.",
      csettingsIntegrationTGAbout: "Wichtig: Beachten Sie, dass es sich hierbei um eine Gruppe handeln sollte, nicht um einen Telegram-Kanal. Leads werden nicht an den Telegram-Kanal gesendet.",
      csettingsIntegrationADAbout: "Etwas über APIxDrive",
      csettingsIntegrationEmailAbout: "Lösen Sie eine E-Mail an Ihren Befragten aus und informieren Sie Ihr Team. Arbeiten Sie mit Ihrem Team zusammen.",
      cSettingsIntegrationNeedHelp: "Hilfe benötigt?",
      cSettingsIntegrationRefreshToken: "Token aktualisieren",
      csettingsSettingASettings: "Erweiterte Einstellungen",
      cSettingsSettingQuizLangTitle: "Quizsprache",
      cSettingsSettingQuizLangDescription: "Um sicherzustellen, dass Sie das beste Erlebnis haben, wählen Sie bitte die gewünschte Quizsprache aus den folgenden Optionen aus. Systembenachrichtigungen und einige andere ständige Texte werden beim Bestehen des Quiz in dieser Sprache angezeigt.",
      selectQuizLanguage: "Wählen Sie die Quizsprache aus",
      cSettingsSettingQuizHideLabelTitle: "Etikett ausblenden",
      cSettingsSettingQuizHideLabelDescription: 'Wenn Sie diese Option aktivieren, können Sie eine Benutzeroberfläche nutzen, die nicht mit dem Label „made by adsquiz“ versehen ist, sodass Sie und Ihre Besucher sich ausschließlich auf die Fragen konzentrieren können.',
      cSettingDataSaved: "Zusätzliche Einstellungen werden gespeichert",
      userAccountInfo: "Konto-Info",
      userGeneralInfoHeader: "Allgemeine Informationen",
      userGeneralInfoDescription1: "Ihr Benutzerprofil ist der Ort, an dem Sie Ihr Konto und Ihre Einstellungen verwalten können.",
      userGeneralInfoDescription2: "Aktualisieren Sie Ihre persönlichen Informationen, ändern Sie Ihr Passwort und passen Sie Ihre Benachrichtigungseinstellungen an.",
      profileNameFirst: "Vorname",
      profileNameLast: "Nachname",
      userSecuritySettingsHeader: "Sicherheitseinstellungen",
      userSecuritySettingsDescription: "Um Ihr Passwort zu ändern, geben Sie einfach Ihr neues Passwort zweimal ein, um es zu bestätigen.",
      profileEmailCurent: "Aktuelle E-Mail-Adresse",
      profileEmailNew: "Neue E-Mail-Adresse",
      profileNewPasswordConfirm: "Neues Passwort bestätigen",
      profileDeleteAccount: "Konto löschen",
      profileDeleteAccountPopupHeader: "Konto löschen?",
      profileDeleteAccountPopupDescription: 'Sind Sie sicher, dass Sie Ihr Konto löschen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden. Um die Löschung zu bestätigen, geben Sie das Wort "LÖSCHEN" in das Feld unten ein.',
      profileDeleteAccountCheckedWord: "LÖSCHEN",
      profileDeleteAccountPopupPlaceholder: "Hier eingeben",
      notificationSuccess: "Erfolg!",
      notificationSuccessSaveAccount: "Ihr Passwort wurde erfolgreich geändert! Verwenden Sie ein neues Passwort, um sich erneut anzumelden",
      notificationSuccessDeleteAccount: "Ihr Passwort wurde erfolgreich geändert! Verwenden Sie ein neues Passwort, um sich erneut anzumelden",
      cardPanUnspecified: "Nicht angegeben",
      userBillingsDate: "Datum",
      userBillingsCost: "Kosten",
      userBillingsId: "ID",
      userBillingsMethod: "Zahlungsmethode",
      paymentSwitchToProHeader: "Zu Pro wechseln",
      paymentSwitchToProDescription: "Finden Sie den Plan, der zu Ihnen passt! Steigen Sie jederzeit auf oder ab - wir machen es Ihnen leicht, den gewünschten Plan zu erhalten.",
      paymentYourCurrentPlan: "Ihr aktueller Plan:",
      paymentYourFuturePlan: "Ihr neuer Plan:",
      paymentPlanLeadsLimit: "Begrenzung der Leads pro Monat:",
      paymentPlanLeadsLimitForTest: "Ihr Quiz-Testlimit:",
      paymentPlanLeadsLeft: "Verbleibende Leads:",
      paymentPlanPrice: "Preis:",
      paymentPlanRenew: "Tarifverlängerungsdatum:",
      addNewSectionHeader: "Neuen Abschnitt hinzufügen",
      addNewSectionList: "Liste",
      addNewSectionCustom: "Eingabefeld",
      addNewSectionCard: "Karte",
      addNewSectionWithEmoji: "Liste mit Emoji",
      addNewSectionTags: "Tags",
      addNewSectionSlider: "Schieberegler",
      templatesBackToQuiz: "Zurück zur Arbeitsfläche",
      constructorStepsSaved: "Alle Quizdaten wurden erfolgreich gespeichert",
      constructorDesignSaved: "Das Quizdesign wurde erfolgreich gespeichert",
      azureBlueWhite: "Standard",
      azureBlue: "Winternacht",
      moderatePurplishRed: "Roter Samt",
      violetBlueCrayola: "Winterbrise",
      wetRainforest: "Sommerahorn",
      lavender: "Raum",
      blueLilac: "Dunkler Lavendel",
      opalGreen: "Karpatenwald",
      dustyBlue: "Tiffany",
      almondCrayola: "Korallenriff",
      periwinkleCrayolaLilac: "Lila",
      periwinkleCrayolaAqua: "Synevir",
      jazzJam: "Jazz",
      slateGrayBlue: "Nebel",
      turquoiseBlueCrayola: "Diia",
      blackPearlescentOpal: "Asche",
      pearlescentGentianBlue: "Saphir",
      sapphireBlue: "Dunkler Saphir",
      errorUserDataUpdate: "Fehler in den aktualisierten Daten: ",
      completeUserDataUpdate: "Benutzerdaten aktualisiert",
      passwordDoNotCoincidence: "Passwörter stimmen nicht überein",
      passwordDoNotCoincidenceAgain: "Versuchen Sie es erneut",
      nonBillingsYet: "Sie haben noch keinen Abrechnungsverlauf",
      youCanChangePlan: "Sie können den aktuellen Plan ändern, indem Sie auf die Schaltfläche „Wechsel zu Pro“ klicken",
      rangeMin: "Min",
      rangeMax: "Max",
      rangePositionStart: "Positionsanfang",
      rangePositionEnd: "Positionsende",
      rangeStep: "Schritt",
      rangeIsRange: "Bereich",
      rangeIsRangeTrue: "Aktiviert",
      rangeIsRangeFalse: "Deaktiviert",
      recivePromotionNewsletter: "Erhalten Sie E-Mail-Newsletter",
      reciveEmailCheckbox: "E-Mail-Checkbox empfangen",
      additionalCheckbox: "Zusätzliche Checkbox",
      contactPrivacyPolicyCheckbox: "Kontakt Datenschutzrichtlinie Checkbox",
      contactAgreeIAgreeWith: "Ich stimme der",
      contactAgreePolicy: "Datenschutzrichtlinie",
      contactAgreeAnd: "und",
      contactAgreeTermsOfUse: "den Nutzungsbedingungen",
      contactAgreeAgreementAdditionalWord: "zu.",
      langEN: "Englisch",
      langDE: "Deutsch",
      langSP: "Spanisch",
      langPL: "Polnisch",
      langIT: "Italienisch",
      langKK: "Kasachisch",
      langRU: "Russisch",
      langUA: "Ukrainisch",
      langLT: "Litauisch",
      langLV: "Lettisch",
      langET: "Estnisch",
      langRO: "Rumänisch",
      leadsLeft: "Bleibt übrig",
      generateDomainTitle: "Geben Sie den Domänennamen ein",
      generateDomainDescription: "Dies ist die eindeutige Adresse, unter der auf Ihre Seite zugegriffen werden kann. Sie können die Adresse jederzeit ändern oder eine eigene anschließen.",
      generateDomainDomainNameLabel: "Domainname",
      cancelAllAction: "Stornieren",
      confirmAllAction: "Bestätigen",
      falseHasValidCharacters: "Ungültige Zeichen im Domänennamen. Erlaubte Zeichen sind Buchstaben, Zahlen und Bindestriche.",
      falseHasValidLength: "Die Länge des Domänennamen sollte zwischen 6 und 20 alphanumerischen Zeichen liegen.",
      falseDoesNotStartOrEndWithSpecialChar: "Der Domänennamen darf nicht mit einem Sonderzeichen beginnen oder enden.",
      falseDoesNotHaveConsecutiveSpecialChar: "Der Domänennamen darf keine zwei oder mehr aufeinanderfolgenden Sonderzeichen enthalten.",
      falseDoesNotHaveConsecutiveSameChar: "Der Domänennamen darf nicht drei oder mehr aufeinanderfolgende identische Zeichen enthalten.",
      falseDoesNotContainForbiddenSubstrings: "Der Domänennamen darf keine verbotenen Teilzeichenfolgen enthalten.",
      generateDomainTaken: "Dieses Haus ist noch nicht fertig. Versuchen Sie es",
      temporarilyUnavailable: "Vorübergehend nicht erreichbar",
      csettingsAdmin: "Einstellungen für Administratoren",
      questionLogicSetupTitle: "Einstellung der Fragelogik",
      questionLogicSetupDescription: "Fügen Sie Bedingungen hinzu, um die Umfrage für jeden Befragten anzupassen und genauere Daten zu sammeln.",
      questionLogicConditionTitle: "Bedingung",
      questionLogicQuestion: "Frage",
      questionLogicSetupQuestionDisableOption: "Frage auswählen",
      questionLogicSetupAnswer: "Antwort",
      questionLogicSetupLogicType: "Typ",
      questionLogicSetupAnswerDisableOption: "Bedingungstyp auswählen",
      questionLogicSetupRangeBetween: "Zwischen",
      questionLogicSetupRangeLess: "Weniger",
      questionLogicSetupRangeMore: "Mehr",
      questionLogicSetupRangeEqualTo: "Gleich",
      questionLogicSetupRangeFrom: "Von",
      questionLogicSetupRangeTo: "Bis",
      questionLogicSetupVariantAll: "Alle sind ausgewählt",
      questionLogicSetupVariantAny: "Обрано один з",
      questionLogicSetupVariantNot: "Einer der Auserwählten",
      questionLogicSetupCustomEqual: "Ist gleich",
      questionLogicSetupCustomContains: "Enthält",
      questionLogicSetupCustomNotContains: "Beinhaltet nicht",
      questionLogicSetupRangeValue: "Wert",
      questionLogicSetupExpectedAnswer: "Erwartete Antworten",
      questionLogicSetupPlaceholder: "Hier eingeben",
      questionLogicAllMandatory: "Alle oben genannten Bedingungen sind obligatorisch",
      questionLogicAddConditionBtn: "Bedingung hinzufügen",
      questionLogicNotAvailable: "Die für den ersten Schritt erforderlichen Logik-Logiken",
      questionLogicSaved: "Die Einstellungen der Schrittanzeigelogik werden gespeichert",
      billingPaymentInfoTitle: "Tarifinformation",
      billingPaymentInfoChangeTarrif: "Tarif ändern",
      billingPaymentInfoDescription: "Wenn Sie einen kostenpflichtigen Tarif bezahlen, erhalten Sie eine bestimmte Anzahl von Leads, die im von Ihnen gewählten Paket enthalten sind. Diese Leads werden für einen Monat bereitgestellt. Wenn Sie Ihre Leads innerhalb eines Monats ab dem Zeitpunkt der Zahlung nicht genutzt haben, werden sie storniert.",
      billingGeneralName: "Ihr Tarif",
      billingGeneralCost: "Kosten",
      billingMonth: "Monat",
      billingGeneralExpired: "Gültig bis",
      billingGeneralLeadsLeft: "Übrige Leads",
      billingGeneralAllTarifExpired: "Ablaufdatum des Tarifs",
      billingGeneralBonus: "Leads mit einem Promo-Code",
      billingGeneralLeadsFromPastTarifs: "Leads aus früheren Tarifen",
      billingAcordeonPaymentInfoTitle: "Zahlungsinformationen",
      billingAcordeonPaymentInfoFormat: "Format",
      billingAcordeonPaymentInfoSubscription: "Abonnement",
      billingAcordeonPaymentInfoPay: "Zahlung",
      billingAcordeonPaymentInfoPayMethod: "Zahlungsmethode",
      billingAcordeonPaymentInfoCancelSubscription: "Abonnement kündigen",
      billingAcordeonPromocode: "Promo-Code",
      billingAcordeonPromocodeLabel: "Geben Sie den Aktionscode ein",
      billingAcordeonPayHistory: "Transaktionsverlauf",
      paymentAllTarifInactive: "Tarif ist inaktiv",
      subscriptionUnsubscribeModalTitle: "Abbestellen",
      subscriptionUnsubscribeModalDescription: "Sind Sie sicher, dass Sie sich abmelden möchten? Die für das Abonnement berechneten Leads stehen Ihnen einen Monat ab dem letzten Abbuchungstag zur Verfügung.",
      subscriptionIsNotDeleteTitle: "Abonnement nicht gelöscht",
      subscriptionIsNotDeleteDescription: "Bitte kontaktieren Sie den Kundensupport",
      subscriptionCanceledTitle: "Abonnement gekündigt",
      modalSureUnsavedChangesTitle: "Sie haben ungespeicherte Änderungen",
      modalSureUnsavedChangesDescription: "Bevor Sie die aktuelle Seite verlassen, entscheiden Sie bitte, was mit den ungespeicherten Änderungen geschehen soll",
      modalSureUnsavedChangesDontSave: "Nicht speichern",
      modalSureUnsavedChangesSave: "Speichern",
      telegramTokenCopied: "Der Quiz-Token wurde in die Zwischenablage kopiert",
      cSettingsSettingQuizBackButtonTitle: "Zurück-Button",
      cSettingsSettingQuizBackButtonDescription: "Sie können die Zurück-Schaltfläche aktivieren oder deaktivieren. Dies bietet die Möglichkeit, die zuvor gegebene Antwort zu ändern.",
      cSettingsCustomThemePrimaryColor: "Farbe der Akzente",
      cSettingsCustomThemeSecondaryColor: "Hintergrundfarbe",
      cSettingsCustomThemeAdditionalColor: "Textfarbe",
      cSettingsCustomThemeButtonTextColor: "Text in Schaltflächen",
      cSettingsCustomThemeModeDark: "Dunkel",
      cSettingsCustomThemeModeLight: "Licht",
      allstepsPlaceholder: "Platzhalter",
      allstepsCustomInput: "Ihre Option",
      allstepsCustomInputOn: "Anmachen",
      allstepsCustomPlaceholder: "Eigene Option ",
      trashcanTitle: "Korb",
      trashcanImportant: "Wichtig!",
      trashcanDescription: "Das Quiz wird 30 Tage nach dem Verschieben in den Papierkorb automatisch endgültig gelöscht.",
      trashcanClearAll: "Alles löschen",
      quizSlotDeletedData: "Gelöscht um",
      workboardLeads: "Führt",
      workboardDuplicate: "Duplikat",
      leadsTable: "Leads-Liste",
      exportInfo: "Leads exportieren",
      leadHide: "Ausblenden",
      leadShowMore: "Mehr",
      poperTrashcan: "Korb",
      restoreQuizSendedTitle: "Eine Wiederherstellungsaufgabe wird ausgeführt",
      restoreSuizSendedImportant: "Wichtig",
      restoreQuizSendedDescription: "Je nachdem, wie viele Leads es in Ihrem Quiz gab, kann die Wiederherstellung des Quiz einige Sekunden bis 2 Minuten dauern. Nach der Wiederherstellung wird das Quiz auf Ihrem Hauptarbeitsbrett angezeigt.",
      ok: "Okay",
      month: "0 Monat | {n} Monat | {n} Monate",
      discount: "Rabatt bis zu",
      saveMoney: "Abonnement für ein Jahr",
      upProVersionMobile: "Zu Pro",
      upProVersionDesktop: "Wechsel zu Pro",




      csettingsIntegrationSendPulse: "Integration - Send Pulse",
      csettingsIntegrationMake: "Integration - Make",
      csettingsIntegrationZapier: "Integration - Zapier",
      csettingsIntegrationCRMBitrix: "Integration - CRM Bitrix",
      csettingsIntegrationPipeDrive: "Integration - Pipe Drive",
      csettingsIntegrationRemOnline: "Integration - Rem Online",
      csettingsIntegrationKeyCRM: "Integration - Key CRM",
            
      csettingsIntegrationZapierAbout: "Geben Sie die URL ein, um die Daten, die von Zapier bereitgestellt werden, in das Eingabefeld zu senden.",
      csettingsIntegrationMakeAbout: "Geben Sie die URL ein, um die Daten, die von Make bereitgestellt werden, in das Eingabefeld zu senden.",
      csettingsIntegrationCRMBitrixAbout: "Geben Sie die URL ein, um die Daten, die von CRM Bitrix bereitgestellt werden, in das Eingabefeld zu senden.",
      csettingsIntegrationSendPulseAbout: "Geben Sie die Kunden-ID, den geheimen Schlüssel, das Adressbuch und die Aktion von Send Pulse in die entsprechenden Eingabefelder ein.",
      csettingsIntegrationKeyCRMAbout: "Geben Sie den API-Schlüssel und die Pipeline-ID in die entsprechenden Eingabefelder ein.",
      csettingsIntegrationPipeDriveAbout: "Geben Sie den Token und die Domain des Unternehmens in die entsprechenden Eingabefelder ein.",
      csettingsIntegrationRemOnlineAbout: "Geben Sie den Rem Online API-Schlüssel in das entsprechende Eingabefeld ein.",
      csettingsIntegrationActiveIntegration: "Aktive Integration",
      cintegrationIntegrationData: "Integrationsdaten",
      cintegrationIntegrationUrl: "URL der Integration",
      cintegrationWriteUrl: "URL eingeben",
      cintegrationDataFBError: "Ungültige Pixel-ID",
      cintegrationDataWebHookError: "Ungültige Webhook-Adresse",
      cintegrationZapierDataSaved: "Integrationsdaten mit Zapier gespeichert",
      cintegrationDataZapierError: "Ungültige Integrationsadresse",
      cintegrationAPIxDriveDataSaved: "Integrationsdaten mit APIxDrive gespeichert",
      cintegrationDataAPIxDriveError: "Der String ist keine APIxDrive-Integrationsadresse",
      cintegrationMakeDataSaved: "Integrationsdaten mit Make gespeichert",
      cintegrationDataMakeError: "Ungültige Integrationsadresse",
      cintegrationSendPulseDataSaved: "Integrationsdaten mit SendPulse gespeichert",
      cintegrationDataSendPulseError: "Integrationsdaten nicht möglich oder leer",
      cintegrationPipeDriveDataSaved: "Integrationsdaten mit PipeDrive gespeichert",
      cintegrationDataPipeDriveError: "Integrationsdaten nicht möglich oder leer",
      cintegrationRemOnlineDataSaved: "Integrationsdaten mit RemOnline gespeichert",
      cintegrationDataRemOnlineError: "Integrationsdaten nicht möglich oder leer",
      cintegrationKeyCRMDataSaved: "Integrationsdaten mit KeyCRM gespeichert",
      cintegrationDataKeyCRMError: "Integrationsdaten nicht möglich oder leer",
      cintegrationInputEmailUrl: "E-Mail eingeben",
      cintegrationEmailDataSaved: "E-Mail gespeichert",
      cintegrationDataEmailError: "Der eingegebene String ist keine E-Mail-Adresse",
      cintegrationCRMBitrixDataSaved: "E-Mail gespeichert",
      cintegrationDataCRMBitrixError: "Der eingegebene String ist keine E-Mail-Adresse",
      cintegrationTelegramBotDataSaved: "Telegram-Integrationseinstellungen gespeichert",
      cintegrationDataTelegramBotError: "Fehler beim Speichern",
      cintegrationGTMDataSaved: "Google Tag Manager-ID gespeichert",
      cintegrationDataGTMError: "Ungültige ID",
      cintegrationDataGTMErrorDescription: "Die ID sollte im Format GTM-XXXXXX sein",
      cintegrationGoogleAnalyticsDataSaved: "Google Analytics-ID gespeichert",
      cintegrationDataGoogleAnalyticsError: "Ungültige ID",
      cintegrationDataGoogleAnalyticsErrorDescription: 'Die ID sollte im Format "UA-XXXXXXX-X" oder "G-XXXXXXXXXX-XX" sein',
      cintegrationTikTokDataSaved: "TikTok-Pixel gespeichert",
      cintegrationDataTikTokError: "Der eingegebene String ist kein TikTok-Pixel",
      cintegrationIntegrationToken: "Integrations-Token",
      cintegrationWriteToken: "Token eingeben",
      cintegrationDataYourAPIKey: "Geben Sie Ihren API-Schlüssel ein",
      viewQuizInMobile: "Quiz ansehen",
      notificationCSRFTokenMismatch: "CSRF-Token stimmt nicht überein",
      notificationReloadPage: "Bitte laden Sie die Seite neu und versuchen Sie es erneut",
      csettingsIntegrationKeyRemOnline: "Geben Sie den Token und die Kanal-ID in die entsprechenden Felder ein",
      cintegrationDataYourIntegrationToken: "API-Schlüssel",
      cintegrationDataLeadTypeId: "Kanal-ID",
      send_utm: "UTM-Tags senden",
      send_quiz: "Quiz-Daten senden",
      send_answers: "Fragen und Antworten senden",
      csettingsIntegrationKeyCrm: "KeyCRM-Integration",
      cintegrationSendPulseClientId: "Kunden-ID",
      cintegrationSendPulseClientSecret: "Geheimnis",
      cintegrationSendPulseAddressBookId: "Adressbuch-ID",
      cintegrationSendPulseAction: "Aktion mit Kontakt",
      sendpulseActionAddContact: "Kontakt hinzufügen",
      sendpulseActionDeleteContact: "Kontakt löschen",
      cintegrationPipeDriveToken: "API-Token",
      cintegrationPipeDriveDomain: "Ihre einzigartige Subdomain",
      cintegrationKeyCrmApiKey: "API-Schlüssel",
      cintegrationKeyCrmPipelineId: "Trichter-ID",
      cintegrationMenuGoogleTagManager:"Google Tag Manager",
      cintegrationMenuGoogleAnalytics:"Google Analytics",
      cintegrationMenuFacebookPixel:"Facebook pixel",
      cintegrationMenuTikTokPixel:"TikTok Pixel",
      cintegrationMenuWebHook:"Webhook",
      cintegrationMenuZapier:"Zapier",
      cintegrationMenuAPIxDrive:"APIx Drive",
      cintegrationMenuMake:"Make",
      cintegrationMenuSendPulse:"Send Pulse",
      cintegrationMenuEmail:"Email",
      cintegrationMenuTelegramBot:"Telegram-bot",
      cintegrationMenuPipeDrive:"PipeDrive",
      cintegrationMenuRemOnline:"Rem Online",
      cintegrationMenuKeyCRM:"Key CRM",
      cintegrationMenuCRMBitrix:"CRM Bitrix",
      trashcanRestoreQuiz: "Quiz wiederherstellen",
      cformValuePromotionAccepted: "Ich stimme dem E-Mail-Newsletter zu",


      constructorAddIntegration: "Integration hinzufügen",
      cintegrationKeyCrmSourceId: "Quellen-ID (optional)",
      cintegrationKeyCrmManagerId: "Manager-ID (optional)",
      cSettingsSettingQuizSpamFilterTitle: "Spamschutz",
      cSettingsSettingQuizSpamFilterDescription: "Die Funktion ist standardmäßig deaktiviert. Wenn Sie auf das Problem von Spam-Leads stoßen, aktivieren Sie den Schutz. Danach kann ein Benutzer nicht mehr als die angegebene Anzahl von Leads für dieses Quiz einreichen.",
      cSettingsSettingQuizSpamFilterLimitPlaceholder: "Anzahl der Leads",
      langNO: "Norwegisch",
      loadMoreLeads: "Mehr Leads anzeigen",
      userAdditionalSettingsHeader: "Erweiterte Profileinstellungen",
      userAdditionalSettingsDescription1: "Hier können Sie neue erweiterte Profileinstellungen konfigurieren.",
      userAdditionalSettingsPagination: "Paginierung auf der Arbeitstafel",

      benefitType: "Bonustyp",
      benefitLabelPlaceholder: "Bonustext",
      benefitDescriptionPlaceholderDiscount: "Gutscheincode oder Anweisung",
      benefitDescriptionPlaceholderVideo: "Link zum Video",
      benefitDescriptionPlaceholderTextfile: "Link zur Textdatei",
      benefitDescriptionPlaceholderGift: "Bonusbeschreibung",
      benefitDescriptionPlaceholderPDF: "Link zum PDF",
      benefitDescriptionPlaceholderLink: "Link",
      benefitDescriptionPlaceholderFile: "Link zur Datei",
      benefitLabelDiscount: "Rabatt",
      benefitLabelVideo: "Video",
      benefitLabelTextfile: "Textdatei",
      benefitLabelGift: "Geschenk",
      benefitLabelPDF: "PDF-Datei",
      benefitLabelLink: "Link",
      benefitLabelFile: "Datei",
      addBenefit: "Bonus hinzufügen",
      saveBenefit: "Bonus speichern",
      cfinishSocialNetworks: "Soziale Netzwerke",

      saveSocialNet: "Soziales Netzwerk speichern",
      networkLabelFacebook: "Facebook",
      networkLabelInstagram: "Instagram",
      networkLabelTelegram: "Telegram",
      networkLabelTiktok: "Tik-Tok",
      networkLabelYoutube: "Youtube",
      networkLabelLinkedin: "LinkedIn",
      networkLabelWhatsapp: "WhatsApp",
      socialNetDescriptionPlaceholderFacebook: "profil_nikname",
      socialNetDescriptionPlaceholderInstagram: "profil_nikname",
      socialNetDescriptionPlaceholderTelegram: "profil/kanal_nikname",
      socialNetDescriptionPlaceholderTiktok: "profil_nikname",
      socialNetDescriptionPlaceholderYoutube: "kanal_name",
      socialNetDescriptionPlaceholderLinkedin: "in/name or company/name",
      socialNetDescriptionPlaceholderWhatsapp: "+15555551234",

      addNewSectionEmptyPage: "Textseite",
      csanyMakeImpasse: "Sackgasse",
      impaseIsImpaseTrue: "Ermöglicht",
      impaseIsImpaseFalse: "Deaktiviert",
      defaultNewEmptyQuestionDescription: "Sie können hier langen Text einfügen und formatieren (wir empfehlen, nicht mehr als 3000 Zeichen einzufügen).",
      cSettingsSettingQuizTimezoneTitle: "Quiz-Zeitzone",
      cSettingsSettingQuizTimezoneDescription: "Geben Sie die Zeitzone ein, in der die Zeit für die Lead-Eingabe angegeben wird. Dies ist besonders nützlich, wenn Sie sich in einer Zeitzone befinden und der Kunde in einer anderen. In diesem Fall können Sie die Zeitzone des Kunden einstellen, und die Leads, die Sie erhalten, zeigen die Zeit entsprechend der Zeitzone des Kunden an.",
      cSettingsSettingQuizTimezonePlaceholder: "Zeitzone",
      userAdditionalSettingsTimezone: "Zeitzone des Benutzers",
      userAdditionalSettingsTimezonePlaceholder: "Zeitzone",
      langHE: "Hebräisch",
      langCS: "Tschechisch",
      cintegrationCAPIFBInstruction: "Um die Integration mit Facebook Conversion API einzurichten, müssen Sie die Pixel-ID und den Zugriffstoken eingeben. Diese Informationen finden Sie in den Einstellungen des Facebook Business Managers. Nachdem Sie die erforderlichen Daten eingegeben haben, speichern Sie die Integration.",
      csettingsIntegrationFbCapiAbout: "Die Integration mit Facebook Conversion API ermöglicht es Ihnen, Nutzerdaten direkt an Facebook zu senden, um die Wirksamkeit von Werbekampagnen zu verbessern.",
      cintegrationInputCapiAccessToken: "Zugriffstoken eingeben",
      cintegrationInputCapiCustomparameter: "Benutzerdefinierte Ereignisparameter eingeben",
      cintegrationDataFbCapiSaved: "Facebook Conversion API-Integration erfolgreich gespeichert",
      cintegrationComonRenovateHubInstructionTitle: "Anleitung zur Einrichtung der Integration mit RenovateHub",
      cintegrationComonRenovateHubInstructionDescription: "Um die Integration mit RenovateHub einzurichten, geben Sie die Webhook-URL an, an die die Daten gesendet werden. Stellen Sie sicher, dass die URL korrekt ist und Daten empfangen kann.",
      cintegrationComonRenovateHubInstructionShort: "Die Integration mit RenovateHub ermöglicht es Ihnen, Daten zur Prozessautomatisierung über einen Webhook an das CRM zu senden.",
      cintegrationRenovateHubUrl: "RenovateHub-URL eingeben",
      cintegrationInputRenovateHubUrl: "Geben Sie die Webhook-URL für RenovateHub an",
      cintegrationRenovateHubDataSaved: "RenovateHub-Integration erfolgreich gespeichert",
      cintegrationDataRenovateHubError: "Fehler beim Speichern der RenovateHub-Integration. Bitte überprüfen Sie die eingegebenen Daten.",
      cintegrationMenuFacebookConvAPI: "Facebook Conversions API",
      cintegrationMenuRenovateHub: "RenovateHub",
      csettingsIntegrationRenovateHub: "RenovateHub-Integration",
      csettingsIntegrationCAPIFB: "Facebook Conversions API - Integration",
      paymentPlanEnd: "Enddatum des Plans",
      paymentPlanLeadsLimitForYear: "Begrenzung der Leads pro Jahr:",
      paymentPlanLeadsLimitForPeriod: "Begrenzung der Leads pro Zeitraum:",
      EditTags: "Tags bearbeiten",
      quizzesTemplatesNoTags: "Keine Tags",
    
      Monday: "Montag",
      Tuesday: "Dienstag",
      Wednesday: "Mittwoch",
      Thursday: "Donnerstag",
      Friday: "Freitag",
      Saturday: "Samstag",
      Sunday: "Sonntag",
      datetimeDisplayMode: "Typ auswählen",
      datetimeDisplayModeSelect: "Typ auswählen",
      datetimeDisplayModeDate: "Datum",
      datetimeDisplayModeTime: "Uhrzeit",
      datetimeDisplayModeDatetime: "Datum & Uhrzeit",
      datetimeMinDate: "Min. Datum",
      datetimeMinDateCustom: "Min. Datum wählen",
      datetimeMaxDate: "Max. Datum",
      datetimeMaxDateCustom: "Max. Datum wählen",
      datetimeProhibitDaysOfWeek: "Tage sperren",
      datetimeIsRange: "Bereich aktivieren",
      datetimeIsRangeTrue: "Bereich aktiviert",
      datetimeIsRangeFalse: "Bereich deaktiviert",
      datetimeMinTime: "Min. Uhrzeit",
      datetimeMaxTime: "Max. Uhrzeit",
      datetimeIs24: "24-Stunden-Format",
      datetimeIs24True: "24-Stunden aktiviert",
      datetimeIs24False: "24-Stunden deaktiviert",
      csanyIsExactDate: "Wie möchten Sie das Datum einstellen",
      csanyIsExactDateTrue: "Genaues Datum",
      csanyIsExactDateFalse: "Relatives Datum",
      csanyDatetimeMinMaxNotExactPlaceholder: '"x" oder "-x" Tage ab heute',
      addNewSectionDatetime: "Datum/Uhrzeit",


    },
    PL: {
      acceptedFileSize:"Maksymalny rozmiar:",
      acceptedFileSizeMeasure:"MB",
      acceptedFileType:"Akceptowane formaty:",
      additionalCheckbox:"Dodatkowy Checkbox",
      addNewSectionCard:"Mapa",
      addNewSectionCustom:"Pole wprowadzania",
      addNewSectionHeader:"Dodaj nową sekcję",
      addNewSectionList:"Lista",
      addNewSectionSlider:"Suwak",
      addNewSectionTags:"Tagi",
      addNewSectionWithEmoji:"Lista z emoji",
      adminAvarageUsed:"Średnia ilość miesięcy użytkowania",
      adminEditorForPartnership:"Redaktor programu partnerskiego",
      adminLeadsForOne:"Na jednego użytkownika",
      adminLTV:"LTV",
      adminMadeQuiz:"Stworzono quizów",
      adminNewUsers:"Nowi użytkownicy za miesiąc",
      adminProAccounts:"Konta PRO",
      adminProfitAlltime:"Stworzono za cały czas",
      adminProfitMonth:"Stworzono za miesiąc",
      adminQuizForOne:"Na jednego użytkownika",
      adminReciveLeads:"Otrzymano aplikacji",
      adminSharePro:"PRO w stosunku do wszystkich",
      adminUsersList:"Baza użytkowników",
      adminUsersReg:"Użytkownicy w systemie",
      allAnd:"і",
      allOr:"lub",
      allstepsBack:"Wstecz",
      allstepsButtonText:"Tekst przycisku",
      allstepsChangePhoto:"Zmień zdjęcie",
      allstepsCustomInput:"Własna wersja",
      allstepsCustomInputOn:"Włącz",
      allstepsCustomPlaceholder:"Własna wersja",
      allstepsDeletePhoto:"Usuń zdjęcie",
      allstepsDescription:"Opis.",
      allstepsIsSaveBeforeRoute:"Zapisać dane przed przełączeniem?",
      allstepsOtherSettings:"Więcej ustawień",
      allstepsPhotoAdaptation:"Dostosowywanie zdjęć do urządzeń",
      allstepsPhotoFit:"Proporcje",
      allstepsPhotoMustBeLess:"Zdjęcie powinno być mniejsze",
      allstepsPhotoTooBig:"Plik jest za duży!",
      allstepsPlaceholder:"Placeholder",
      allstepsResetInHeader:"Reset",
      allstepsTitle:"Tytuł",
      allstepsUpploadPhoto:"Pobierz zdjęcie",
      almondCrayola:"Rafa koralowa",
      alreadyHaveAccount:"Masz już konto?",
      autoInternationalPrefix:"Wykryj kraj automatycznie",
      azureBlue:"Zimowa noc",
      azureBlueWhite:"Standard",
      billingAcordeonPayHistory:"Historia transakcji",
      billingAcordeonPaymentInfoCancelSubscription:"Anuluj subskrypcję",
      billingAcordeonPaymentInfoFormat:"Format",
      billingAcordeonPaymentInfoPay:"Płatność",
      billingAcordeonPaymentInfoPayMethod:"Metoda płatności",
      billingAcordeonPaymentInfoSubscription:"Subskrypcja",
      billingAcordeonPaymentInfoTitle:"Informacje o płatności",
      billingAcordeonPromocode:"Kod promocyjny",
      billingAcordeonPromocodeLabel:"Wprowadź kod promocyjny",
      billingBillings:"Płatności",
      billingBonusesActivated:"Kod promocyjny został aktywowany!",
      billingBonusesAlreadyActivated:"Bonusy za ten kod promocyjny zostały już przyznane",
      billingBonusesBtn:"Aktywuj",
      billingBonusesDescription:"Wprowadź otrzymany kod promocyjny w polu obok.",
      billingBonusesInvalid:"Nieprawidłowy kod promocyjny",
      billingBonusesLeadIncreased:"Ilość dostępnych leadów została zwiększona.",
      billingBonusesNotAvailable:"Ten kod promocyjny został już wykorzystany",
      billingBonusesPlaceholder:"Kod promocyjny",
      billingBonusesTitle:"Kod promocyjny",
      billingBtnChangeToPro:"Przejdź do",
      billingBtnRenewPro:"Aktualizacja",
      billingChangeData:"Zmień dane",
      billingChangePlan:"Zmień plan",
      billingChangePlanDescription:"Cena zostanie dostosowana do żądanej ilości leadów ze wszystkich quizów na koncie.",
      billingChangePlanDescriptionImportant:"Ważne:",
      billingChangePlanDescriptionImportantMonth:"Leady mogą być używane przez 1 miesiąc po zakupie",
      billingChangePlanDescriptionImportantMonthAfterBuy:"po zakupie.",
      billingChangePlanPay:"Zmiana taryfy",
      billingChangePlanWarning:"Zakupione leady będą",
      billingDeletePayMethod:"Usuń",
      billingFreeLeads:"Brak bezpłatnych leadów",
      billingGeneralAllTarifExpired:"Taryfy wygasły",
      billingGeneralBonus:"Leady według kodu promocyjnego",
      billingGeneralCost:"Koszt",
      billingGeneralExpired:"Ważne do",
      billingGeneralLeadsFromPastTarifs:"Saldo potencjalnych klientów z poprzednich taryf",
      billingGeneralLeadsLeft:"Zostało się  leadów",
      billingGeneralName:"Twoja taryfa",
      billingInfinity:"nieograniczony",
      billingLeads:"leady",
      billingModalDeleteCancel:"Anuluj",
      billingModalDeleteOk:"Usuń",
      billingModalDeleteShure:"Czy naprawdę chcesz usunąć tę metodę płatności?",
      billingModalDeleteSuccess:"Karta została pomyślnie usunięta",
      billingModalRegularPaymentDeleteOk:"OK",
      billingModalRegularPaymentDeleteSuccess:"Regularne płatności zostały anulowane",
      billingMonth:"miesiąc",
      billingOtherTariffFree:"Free.",
      billingOtherTariffPro:"Pro.",
      billingPaymentCard:"Karta",
      billingPaymentInfoChangeTarrif:"Zmiana taryfy",
      billingPaymentInfoDescription:"Płacąc za dowolną płatną taryfę, otrzymujesz określoną liczbę leadów zawartych w wybranym pakiecie. Leady  te są dostępne przez miesiąc. Jeśli nie wykorzystasz swojej liczby leadów w ciągu miesiąca od dokonania płatności, takie dane zostaną anulowane.",
      billingPaymentInfoTitle:"Informacje o taryfie",
      billingPaymentsMethod:"Metoda płatności",
      billingSelectPeriod:"Wybierz okres",
      billingTariffEndData:"Data wygaśnięcia taryfy",
      billingTariffFreeDescription:"Nieograniczona liczba nowych quizów (stron internetowych). Możliwość wygenerowania do 300 leadów",
      billingTariffMonth:"miesiąc",
      billingTariffProDescription:"Nieograniczone tworzenie nowych quizów (stron internetowych). Możliwość wygenerowania",
      billingTariffProDescriptionBold:"ponad 300 leadów",
      billingTariffRenewData:"Data aktualizacji taryfy",
      billingYorFree:"Bezpłatne",
      billingYouHasFreeLeads:"Zostało bezpłatnych leadów",
      billingYouHasLeads:"Zostało leadów",
      billingYouHaveNotBillings:"Nie masz jeszcze żadnych płatności",
      billingYouHavePro:"Obecnie korzystasz z wersji Pro. Dlaczego robisz krok w tył?",
      billingYourFutureTariff:"Twój przyszły plan",
      billingYourMonthPlan:"Twój plan miesięczny",
      billingYourPro:"Pro",
      billingYourTariff:"Twój obecny plan",
      blackPearlescentOpal:"Popiół",
      blueLilac:"Ciemna lawenda",
      cancel:"Anuluj",
      cancelAllAction:"Anuluj",
      cardPanUnspecified:"Nie określono",
      cdesignColorTheme:"Schemat kolorów",
      cdesignCustomTheme:"Stwórz własny schemat kolorów",
      cdesignDefaultThemes:"Gotowe schematy kolorów",
      cdesignShapeDesign:"Kształt designu",
      cdesignShapeRound:"Zaokrąglony",
      cdesignShapeSquare:"Kątowy",
      cdesignThemeDark:"Ciemny",
      cdesignThemeGradient:"Gradient",
      cdesignThemeNud:"Nude",
      cdesignThemeStandart:"Standardowy",
      cfinishAddImage:"Dodaj obrazek",
      cfinishButtonText:"Tekst przycisku",
      cfinishCheckDataIsCorrect:"Sprawdź poprawność wprowadzonego identyfikatora",
      cfinishDescription:"Opis.",
      cfinishFbWhichCorrect:"Wprowadź identyfikator (pseudonim) bez adresu. Na przykład: some.name",
      cfinishFinishPageDataUpdated:"Dane strony finałowej zostały zaktualizowane",
      cfinishFinishPageHiddenUpdated:"Strona finałowa jest ukryta.",
      cfinishFinishPageHiddenUpdatedGoContact:"Przejdź do strony Formularze kontaktowe",
      cfinishFinishPageHiddenUpdatedSetRedirect:"i skonfiguruj przekierowanie po przesłaniu danych",
      cfinishFinishPageTitle:"Strona finałowa ",
      cfinishIgWhichCorrect:"Wprowadź identyfikator (pseudonim) bez adresu. Na przykład: some.name",
      cfinishImageAdded:"Obrazek dodany",
      cfinishInsertNikname:"Wstaw tutaj swój pseudonim",
      cfinishText:"Tekst.",
      cfinishTgWhichCorrect:"Wprowadź identyfikator (pseudonim) bez adresu. Na przykład: some.name",
      cfinishURL:"URL",
      cfirstAddBonus:"Dodaj bonus",
      cfirstAddMediafile:"Dodaj plik multimedialny (zdjęcie)",
      cfirstBonus:"Bonus",
      cfirstBonusAvailable:"Dostępny",
      cfirstBonusDisabled:"Niedostępny",
      cfirstFormat:"Format",
      cfirstHeaderStartText:"Twój nagłówek powinien znaleźć się tutaj. Zmotywuj użytkowników do ukończenia quizu ",
      cfirstStartPageBonusPhotoUpdated:"Zaktualizowano zdjęcie bonusowe",
      cfirstStartPageDataUpdated:"Zaktualizowano dane strony startowej",
      cfirstStartPagePhotoUpdated:"Zaktualizowano zdjęcie strony głównej",
      cformActionAfterContactRecive:"Działania po otrzymaniu kontaktów",
      cformActionAfterContactRedirect:"Przekierowanie",
      cformActionAfterContactThankyou:"Strona z podziękowaniem",
      cformAdditionalSettings:"Dodatkowe ustawienia",
      cformDataContactsPageUpdated:"Zaktualizowano dane strony kontaktowej",
      cformForm:"Formularz",
      cformInputAvailable:"Dostępny",
      cformInputDisabled:"Wyłączony",
      cformRedirectURL:"URL",
      cformRequiredInput:"Wymagane",
      cformValueEmail:"E-mail",
      cformValueName:"Nazwa",
      cformValuePhone:"Numer telefonu",
      cformValueSurname:"Nazwisko",
      cintegrationAccesToken:"ACCES TOKEN",
      cintegrationAddEmail:"Dodaj e-mail",
      cintegrationAddIntegration:"Dodaj integrację",
      cintegrationAddWH:"Dodaj Web-hook",
      cintegrationApixDriveWHInstruction1Description1:"Link",
      cintegrationApixDriveWHInstruction1Description2:"dla rejestracji z bonusem.",
      cintegrationApixDriveWHInstruction1Title:"1. Zarejestruj się lub otwórz konto ApixDrive:",
      cintegrationApixDriveWHInstruction2Description1:"Przejdź do ustawień wymaganego połączenia i skopiuj adres, aby wysłać webhook:",
      cintegrationApixDriveWHInstruction2Description2:"Możesz utworzyć nowe połączenie, klikając przycisk „Utwórz połączenie” i wybierz „ADSQuiz” w kroku wyboru systemu.",
      cintegrationApixDriveWHInstruction2Title:"2. Uzyskaj adres URL elementu webhook:",
      cintegrationApixDriveWHInstruction3Description:"Wklej adres URL elementu webhook dostarczonego przez ApixDrive do pola wprowadzania.",
      cintegrationApixDriveWHInstruction3Title:"3. Wklej adres URL elementu webhook:",
      cintegrationApixDriveWHInstruction4Description:'Kliknij przycisk "Zapisz zmiany", aby zapisać ustawienia elementu webhook.',
      cintegrationApixDriveWHInstruction4Title:"4. Zapisz zmiany:",
      cintegrationApixDriveWHInstruction5Description:"Twój webhook jest teraz skonfigurowany do wysyłania danych do ApixDrive.",
      cintegrationApixDriveWHInstruction5Title:"5. Zakończ:",
      cintegrationApixDriveWHInstructionAdditional:"Dane będą wysyłane do webhooka za każdym razem, gdy otrzymasz lead. Metoda wysyłania - POST. Przykład danych, które zostaną wysłane to",
      cintegrationApixDriveWHInstructionAdditionalLink:"tutaj",
      cintegrationApixDriveWHInstructionShort:"Możesz powiadamiać ApixDrive o przychodzących leadach. Aby to zrobić, określ adres URL, na który zostanie wysłany webhook.",
      cintegrationApixDriveWHInstructionShort1:"Podczas rejestracji przez",
      cintegrationApixDriveWHInstructionShort2:"ten link",
      cintegrationApixDriveWHInstructionShort3:"nasi użytkownicy otrzymują bonus - 10 000 darmowych działań dla każdej integracji.",
      cintegrationApixDriveWHInstructionVideo:"Szczegółowa instrukcja wideo",
      cintegrationCheckDomenIsCorrect:"Sprawdź, czy wprowadzona domena jest poprawna",
      cintegrationComonWHInstruction1Description:"Wklej adres URL webhooka dostarczonego przez twoją usługę lub aplikację do pola wprowadzania.",
      cintegrationComonWHInstruction1Title:"1. Wklej adres URL elementu webhook w polu wprowadzania:",
      cintegrationComonWHInstruction2Description:"Kliknij przycisk Zapisz zmiany, aby zapisać ustawienia webhook.",
      cintegrationComonWHInstruction2Title:"2. Zapisz zmiany:",
      cintegrationComonWHInstruction3Description:"Twój webhook jest teraz skonfigurowany do wysyłania danych do wybranej usługi.",
      cintegrationComonWHInstruction3Title:"3. Zakończ:",
      cintegrationComonWHInstructionAdditional:"Dane będą wysyłane do webhooka za każdym razem, gdy otrzymasz lead. Metodą wysyłania jest POST. Przykład danych, które zostaną wysłane, można znaleźć",
      cintegrationComonWHInstructionAdditionalLink:"tutaj",
      cintegrationComonWHInstructionShort:"Możesz powiadamiać inne aplikacje o przychodzących leadach. Wpisz adres URL elementu webhook, do którego mają być wysyłane dane.",
      cintegrationComonWHInstructionVideo:"Szczegółowa instrukcja wideo",
      cintegrationConectOweDomain:"Podłączenie własnej domeny",
      cintegrationDataFBSaved:"Dane piksela FB zostały  zostały zapisane ",
      cintegrationDataGASaved:"Dane Google Analytics zostały  zapisane ",
      cintegrationDataGTMSaved:"Dane Google Tag Manager zostały  zapisane ",
      cintegrationDataTGSaved:"Dane integracji Telegram zostały  zapisane ",
      cintegrationDataTTSaved:"Dane TT piksel zostały zapisane",
      cintegrationDocsDataSaved:"Linki do Twoich dokumentów zostały zapisane",
      cintegrationDomain:"Domena",
      cintegrationDomainDataSaved:"Dane domeny zostały zapisane",
      cintegrationDomainWhichCorrect:"Wprowadź domenę bez protokołów. np: some.site.com",
      cintegrationEmailDeleted:"Usunięto dane integracji poczty e-mail",
      cintegrationEmailInstruction:"Wprowadź wymagany adres e-mail, na który chcesz wysłać informacje w polu poniżej. Każde lead z tego quizu zostanie zduplikowany na podany adres e-mail.",
      cintegrationEmailSaved:"Dane e-mail integracji zostały zapisane",
      cintegrationFBInstruction:"Zdarzeniem, dla którego należy skonfigurować konwersję jest „Lead”. To zdarzenie zostanie uruchomione, gdy tylko użytkownik pomyślnie wyśli dane do quizu.  ",
      cintegrationFBInstruction1:"Przejdź do swojego konta Facebook Ads i skopiuj identyfikator piksela Facebooka w sekcji Event Manager.",
      cintegrationFBInstruction2:"Nie kopiuj całego kodu, tylko 15-16 cyfrowy identyfikator.",
      cintegrationFBInstruction3:"Skopiuj go i wklej w polu poniżej.",
      cintegrationGAInstruction:"Po otrzymaniu danych w quizie, zdarzenie „generate_lead” zostanie uruchomione w Google Analytics.",
      cintegrationGAInstructionDescription:"Dowiedz się, jak ludzie znajdują i wchodzą w interakcję z Twoim quizem. Uzyskaj dane potrzebne do analizy kampanii, poprawy konwersji i nie tylko.",
      cintegrationGTMInstruction:"Skopiuj identyfikator Google Tag Manager i wklej go w poniższe pole.",
      cintegrationGTMInstructionDescription:"Opis dla Google Tag Manager (nieużywany)",
      cintegrationHowReciveTGLeadBot:"Jak mogę otrzymywać leady (dane) do bota Telegram?",
      cintegrationHowReciveTGLeadChat:"Jak mogę otrzymywać leady (dane) w czat Telegram?",
      cintegrationIDFolow:"ID śledzenia",
      cintegrationIDPixel:"Pixel ID",
      cintegrationInputEmail:"Wprowadź swój adres e-mail",
      cintegrationInputID:"Wprowadź ID pixela",
      cintegrationInputIDPixel:"Wprowadź ID piksela",
      cintegrationInputTGToken:"Wprowadź token telegramu dla chatbota",
      cintegrationInputWHUrl:"Wprowadź adres webhook",
      cintegrationInputYourDomain:"Wprowadź swoją domenę",
      cintegrationInsertedDefaultDomain:"Domena jest ustawiona domyślnie",
      cintegrationInstruction:"Instrukcje",
      cintegrationInstructionDetail:"Szczegóły",
      cintegrationIntegration:"Integracje",
      cintegrationOweDomainInstruction1:"1. Aby umieścić quiz w wymaganej domenie, zaloguj się na swoje konto hostingowe. Przejdź do ustawień DNS i utwórz A-rekord z parametrem IP: 185.25.117.21",
      cintegrationOweDomainInstruction2:"2. W powyższym polu określ domenę i zapisz quiz.",
      cintegrationOweDomainInstructionImportant:"Ważne: Adres na koncie hostingowym i w polu poniżej musi się zgadzać. Czas na zastosowanie zmian w ustawieniach DNS i wydanie certyfikatu SSL wynosi do 3 godzin od zapisania.",
      cintegrationOweDomainInstructionVideo:"Obejrzyj instrukcję wideo",
      cintegrationPrivacyPolicy:"Polityka prywatności.",
      cintegrationPrivacyPolicyAdventage:"Wyjaśni to potencjalnym klientom, w jaki sposób wykorzystujesz otrzymane dane kontaktowe. Jest to również wymagane przez niektóre platformy reklamowe.",
      cintegrationPrivacyPolicyByDefault:"Domyślnie używamy naszej standardowej polityki prywatności. Zalecamy dodanie własnej polityki prywatności, która jest odpowiednia dla Twojej firmy.",
      cintegrationPrivacyPolicyInputYourDocsLink:"Wstaw link do Twoich dokumentów",
      cintegrationPrivacyPolicyUrl:"Link do Twoich dokumentów",
      cintegrationPublishAfterEdit:"Zapisz quiz po edycji",
      cintegrationSendNewLeadsTo:"Wysyłaj powiadomienia o nowych leadach do:",
      cintegrationShareLeads:"Przeniesienie leadów",
      cintegrationTGBotLink:"link do chatbota",
      cintegrationTGInstructionBot1:"1. Skopiuj token w polu poniżej.",
      cintegrationTGInstructionBot2:"2. Podążaj za linkiem do bota, kliknij przycisk „START”.",
      cintegrationTGInstructionBot3:"3. Wyślij skopiowany token do naszego bota za pośrednictwem wiadomości.",
      cintegrationTGInstructionChat1:"1. Dodaj naszego bota do wybranego czatu i ustaw bota na status administratora.",
      cintegrationTGInstructionChat2:"2. Skopiuj token w polu poniżej i wyślij go do wybranego czatu.",
      cintegrationTGInstructionChat3:"3. Poczekaj na odpowiedź OK! Your quiz token was saved!`",
      cintegrationTGInstructionChat4:"4. Teraz dane będą wysyłane do tego czatu.",
      cintegrationTGInstructionWarning:"Ważne:",
      cintegrationTGInstructionWarningText:"Należy pamiętać, że powinna to być grupa, a nie kanał Telegram. Dane nie będą wysywać się do kanalu Telegram.",
      cintegrationTGTitleToken:"TOKEN",
      cintegrationTitleEmail:"E-MAIL",
      cintegrationTitleFB:"Piksel FB",
      cintegrationTitleGA:"Google Analytics",
      cintegrationTitleGTM:"Google Tag Manager",
      cintegrationTitleTG:"Telegram",
      cintegrationTitleTT:"Piksel TT",
      cintegrationTitleWH:"WebHook",
      cintegrationTTInstruction:"Aby śledzić zdarzenia na koncie Tik-Tok Ads, skopiuj identyfikator piksela i wklej go w poniższe pole.",
      cintegrationWHDataSaved:"WebHook dane zostały zapisane",
      cintegrationWHInstruction1:"Możesz powiadamiać inne aplikacje o przychodzących danych. Aby to zrobić, wpropwadź adres URL, na który zostanie wysłany WebHook.",
      cintegrationWHInstruction10:"dla rejestracji z bonusem.",
      cintegrationWHInstruction2:"Przygotowaliśmy",
      cintegrationWHInstruction3:"wideo instrukcja ",
      cintegrationWHInstruction4:"ze szczegółowym opisem tej funkcjonalności.",
      cintegrationWHInstruction5:"Obejrzyj wideo.",
      cintegrationWHInstruction6:"W tym filmie omówimy wysyłanie huka w oparciu o wysyłanie danych do arkusza Google i wysyłanie danych do systemu Trello CRM.",
      cintegrationWHInstruction7:"Zalecamy korzystanie dla tego serwisui",
      cintegrationWHInstruction8:"Rejestrując się za pomocą tego linku, nasi użytkownicy otrzymują bonus - 10 000 darmowych leadów dla dowolnej integracji.",
      cintegrationWHInstruction9:"Link",
      cintegrationWHUrl:"Adres webhooka",
      completeUserDataUpdate:"Dane użytkownika zostały zaktualizowane",
      confirmAllAction:"Potwierdź",
      conjunctionAnd:"i",
      constructorBack:"Wstecz",
      constructorCancel:"Anuluj",
      constructorDesignSaved:"Design quizu został pomyślnie zapisany",
      constructorextraNavContact:"C",
      constructorextraNavFinish:"F",
      constructorextraNavStart:"S",
      constructorGetLink:"Link do quizu",
      constructorLink:"link",
      constructorLinkCopied:"Skopiowany do schowka:",
      constructorNavDesign:"Design",
      constructorNavSettings:"Ustawienia",
      constructorNavSteps:"Kroki",
      constructorSave:"Zapisz",
      constructorSaveChanges:"Zapisz zmiany",
      constructorStep:"krok",
      constructorStepsSaved:"Wszystkie dane quizu zostały pomyślnie zapisane",
      constructorYouHideStep:"Ten krok został ukryty",
      contactAgreeAgreementAdditionalWord:"",
      contactAgreeAnd:"i",
      contactAgreeIAgreeWith:"Zgadzam się z",
      contactAgreePolicy:"polityką prywatności",
      contactAgreeTermsOfUse:"warunki użytkowania",
      contactPrivacyPolicyCheckbox:"Pole wyboru umowy o ochronie prywatności",
      createNewGroup:"Utwórz nową grupę",
      createQuizCPButton:"Gotowe!",
      createQuizCPDescription:"Wypełnij poniższy formularz",
      createQuizCPTitle:"Jak możemy się z Tobą skontaktować?",
      createQuizFPButton:"Pokaż mi coś!",
      createQuizFPDescription:"Teraz znajdziemy najlepsze rozwiązanie dla Ciebie!",
      createQuizFPTitle:"Dziękujemy!",
      createQuizSPDescription:"To jest miejsce na krótki opis",
      csanyAddAnswer:"dodać",
      csanyAlowMultiple:"Zezwalaj na wiele odpowiedzi",
      csanyAnswerOrderSaved:"Kolejność odpowiedzi jest zapisywana",
      csanyAnswers:"Odpowiedzi",
      csanyAnswerType:"Typ pytania",
      csanyATCard:"karta",
      csanyATCustom:"wolne pole",
      csanyATList:"lista",
      csanyAutoRedirect:"Automatycznie przejdź dalej",
      csanyDataStepUpdated:"Dane strony z pytaniami zostały zaktualizowane",
      csanyQuestion:"Pytania",
      csanyQuestionLogic:"Konfigurowanie logiki pytań",
      csanyRequiredStep:"Wymagany krok",
      cSettingDataSaved:"Ustawienia dodatkowe zostały zapisane",
      csettingsAdmin:"Ustawienia dla administratorów",
      cSettingsCustomThemeAdditionalColor:"Kolor tekstu",
      cSettingsCustomThemeButtonTextColor:"Tekst w przyciskach",
      cSettingsCustomThemeModeDark:"Ciemny",
      cSettingsCustomThemeModeLight:"Jasny",
      cSettingsCustomThemePrimaryColor:"Kolor akcentu",
      cSettingsCustomThemeSecondaryColor:"Kolor tła",
      csettingsDocs:"Dokumenty prawne",
      csettingsIntegration:"Integracje",
      csettingsIntegrationAbout:"Szczegóły",
      csettingsIntegrationADAbout:"Coś o APIxDrive",
      csettingsIntegrationApixDrive:"Integracja - APIxDrive",
      csettingsIntegrationEmail:"Integracja - E-mail",
      csettingsIntegrationEmailAbout:"Aktywuj wiadomość e-mail dla respondenta i poinformuj o tym swój zespół. Współpracuj ze swoim zespołem.",
      csettingsIntegrationFB:"Integracja - Piksel Facebooka",
      csettingsIntegrationFBAbout:"Przejdź do swojego konta Facebook Ads i skopiuj identyfikator Facebook Pixel w sekcji Event Manager. Skopiuj tylko 15-cyfrowy identyfikator, a nie cały kod, i wklej go w polu poniżej.",
      csettingsIntegrationGA:"Integracja - Google Analytics",
      csettingsIntegrationGAAbout:"Skopiuj identyfikator Google Analytics i wklej go w polu poniżej.",
      csettingsIntegrationGTM:"Integracja - Google Tag Manager",
      csettingsIntegrationGTMAbout:"Skopiuj identyfikator  Google Tag Manager i wklej go w polu poniżej.",
      cSettingsIntegrationNeedHelp:"Potrzebujesz pomocy?",
      cSettingsIntegrationRefreshToken:"Zaktualizuj swój token",
      csettingsIntegrationTG:"Integracja - Telegram",
      csettingsIntegrationTGAbout:"Ważne: Należy pamiętać, że powinna to być grupa, a nie kanał w Telegramie. Leady nie będą wysyłane na kanał Telegram.",
      csettingsIntegrationTT:"Integracja - TikTok",
      csettingsIntegrationTTAbout:"Aby śledzić wydarzenia na koncie reklamowym TikTok, skopiuj identyfikator piksela i wklej go w poniższe pole.",
      csettingsIntegrationWH:"Integracja - WebHook",
      csettingsIntegrationWHAbout1:"Możesz powiadamiać inne aplikacje o przychodzących leadach. Aby to zrobić, wprowadź adres URL, na który zostanie wysłany webhook. Zalecamy korzystanie z tej usługi.",
      csettingsIntegrationWHAbout2:"Rejestrując się za pomocą tego linku, nasi użytkownicy otrzymują bonus - 10 000 darmowych akcji dla każdej integracji.",
      csettingsIntegrationWHAboutApix:"Apix-Drive",
      csettingsOweDomain:"Podłącz własną domenę",
      csettingsSettingASettings:"Dodatkowe ustawienia",
      cSettingsSettingQuizBackButtonDescription:"Możesz włączyć lub wyłączyć przycisk powrotu do poprzedniego kroku. Umożliwi to zmianę udzielonej wcześniej odpowiedzi.",
      cSettingsSettingQuizBackButtonTitle:"Przycisk Wstecz",
      cSettingsSettingQuizHideLabelDescription:"Włączając tę opcję, będziesz mógł cieszyć się interfejsem „made by adsquiz”, pozwalając Tobie i Twoim odwiedzającym skupić się wyłącznie na pytaniach.",
      cSettingsSettingQuizHideLabelTitle:"Ukryj tag",
      cSettingsSettingQuizLangDescription:"Aby zapewnić jak najlepsze wrażenia, wybierz preferowany język quizu z poniższych opcji. Język ten będzie używany do wyświetlania powiadomień systemowych i niektórych innych niezmienionych tekstów podczas quizu.",
      cSettingsSettingQuizLangTitle:"Język quizu",
      cstepsAddSection:"Dodaj sekcję",
      cstepsContactPage:"Formularz kontaktowy",
      cstepsDeleteStep:"Usuń krok",
      cstepsEdit:"Edytuj",
      cstepsFinishPage:"Strona finałowa",
      cstepsHiddenStep:"Ukryj krok",
      cstepsMove:"Przesuń",
      cstepsQuestionsOrderSaved:"Kolejność pytań została zapisana",
      cstepsSection:"Sekcje",
      cstepsShowStep:"Pokaż krok",
      cstepsStartPage:"Strona początkowa",
      defaultBtnText:"Rozpocznij",
      defaultNewQuestion:"Nowe pytanie",
      defaultNewQuestionDescription:"Tutaj można wstawić krótki opis pytania (do 80 znaków).",
      defaultNewVariant:"Nowa opcja",
      developmentPage:"Sekcja jest w trakcie opracowywania",
      discount:"Zniżka do",
      dontHaveAccount:"Nie masz konta?",
      dovnloadCsvCancel:"Anuluj",
      dovnloadCsvExport:"Eksportuj do",
      dovnloadCsvSelectAllPeriod:"Wybierz dla całego okresu",
      dovnloadCsvTitle:"Eksportuj dane dla wybranego okresu",
      dustyBlue:"Tiffany",
      enterYourEmail:"Wprowadź swój adres e-mail",
      enterYourName:"Wprowadź swoje imię i nazwisko",
      enterYourPassword:"Wprowadź hasło",
      enterYourPhone:"Wprowadź swój numer telefonu",
      errorUserDataUpdate:"Błąd podczas aktualizacji danych: ",
      exportInfo:"Eksportowanie leadów",
      falseDoesNotContainForbiddenSubstrings:"Domena nie może zawierać niedozwolonych podciągów.",
      falseDoesNotHaveConsecutiveSameChar:"Domena nie może zawierać trzech lub więcej następujących po sobie identycznych znaków.",
      falseDoesNotHaveConsecutiveSpecialChar:"Domena nie może zawierać dwóch lub więcej następujących po sobie znaków specjalnych.",
      falseDoesNotStartOrEndWithSpecialChar:"Domena nie może zaczynać się ani kończyć znakiem specjalnym.",
      falseHasValidCharacters:"Nieprawidłowe znaki w domenie. Prawidłowe znaki to litery alfabetu łacińskiego, cyfry i myślniki.",
      falseHasValidLength:"Długość domeny musi wynosić od 6 do 20 znaków alfanumerycznych.",
      footerContactUs:"Masz jakieś pytania? Skontaktuj się z nami:",
      generateDomainDescription:"Jest to unikalny adres, pod którym Twoja strona będzie dostępna. Możesz zmienić adres lub dodać własny w dowolnym momencie.",
      generateDomainDomainNameLabel:"Nazwa domeny",
      generateDomainTaken:"Ta domena jest już zajęta. Wypróbuj inną",
      generateDomainTitle:"Wprowadź nazwę domeny",
      getBackAccount:"Powrót do konta",
      getLeads:"Kup leady",
      helloHeader:"Cześć",
      inMinutes:"za minutę",
      jazzJam:"Jazz",
      langDE:"Niemiecki",
      langEN:"angielski",
      langIT:"włoski",
      langKK:"Kazachski",
      langPL:"polski",
      langRU:"rosyjski",
      langSP:"hiszpański",
      langUA:"ukraiński",
      lavender:"Kosmos",
      leadHide:"Ukryć",
      leadShowMore:"Więcej",
      leadsLeft:"Pozostały leady",
      leadsTable:"Lista leadów",
      loginBtnValue:"Zaloguj się",
      loginEmailPlaceholder:"E-mail",
      loginHeader:"Zaloguj się",
      loginHeader2Part:"aby kontynuować",
      loginPasswordlPlaceholder:"○ ○ ○ ○ ○ ○ ○ ○ ○",
      loginSignup:"Zarejestruj konto",
      loginWith:"Zaloguj się za pomocą",
      madeInUkr:"wyprodukowano na Ukrainie",
      modalSureUnsavedChangesDescription:"Przed opuszczeniem bieżącej strony zdecyduj, co zrobić z niezapisanymi zmianami",
      modalSureUnsavedChangesDontSave:"Nie zapisuj",
      modalSureUnsavedChangesSave:"Zapisz",
      modalSureUnsavedChangesTitle:"Masz niezapisane zmiany",
      moderatePurplishRed:"Czerwony aksamit",
      month:"0 miesięcy |{n} miesiąc |{n} miesiąc |{n} miesiąc",
      navBillings:"Płatności",
      navPartnership:"Program partnerski",
      navProfile:"Profil",
      nonBillingsYet:"Nie masz jeszcze historii płatności.",
      notificationAgreePrivacyPolicy:"Prosimy o zaakceptowanie polityki prywatności",
      notificationCheckInputEmail:"Sprawdź poprawność adresu e-mail",
      notificationCheckInputPhone:"Sprawdź, czy Twój numer telefonu jest poprawny",
      notificationErrorLogin:"Wystąpił błąd autoryzacji:",
      notificationInputAllInputs:"Wypełnij wszystkie pola, aby się zarejestrować",
      notificationInputLoginData:"Wprowadź dane logowania",
      notificationLogin:"Zaloguj się...",
      notificationNetworkError:"Sprawdź połączenie internetowe",
      notificationSuccess:"sukces!",
      notificationSuccessDeleteAccount:"Twoje konto zostało pomyślnie usunięte! Użyj nowego hasła, aby się zalogować",
      notificationSuccessSaveAccount:"Twoje hasło zostało pomyślnie zmienione! Użyj nowego hasła, aby się zalogować",
      notificationWrongLoginData:"Nieprawidłowe dane logowania",
      ok:"Dobrze",
      opalGreen:"Karpacki las",
      openPopupQuizText:"Rozpocznij zadanie",
      passwordAreForgetPassword:"Zapomniałeś hasła?",
      passwordChangePassword:"Zmień hasło",
      passwordConfirmPassword:"Potwierdź hasło",
      passwordDoNotCoincidence:"Hasła nie pasują",
      passwordDoNotCoincidenceAgain:"Spróbuj ponownie",
      passwordGoToWorkboard:"Zaloguj się do swojego konta",
      passwordInputNewPassword:"Wprowadź nowe hasło",
      passwordResetedPasswordCompleted:"Twoje hasło zostało pomyślnie zmienione!",
      passwordResetPassword:"Odzyskiwanie hasła",
      passwordResetPasswordHeader:"Odzyskiwanie hasła",
      passwordWeSentReset:"Link do resetowania hasła został wysłany pocztą e-mail.",
      paymentAllTarifInactive:"taryfa jest nieaktywna",
      paymentPlanRenew:"Data odnowienia taryfy:",
      paymentPlanLeadsLeft:"Pozostało leadów:",
      paymentPlanLeadsLimit:"Limit leadów na miesiąc:",
      paymentPlanLeadsLimitForTest:"Twój limit leadów testowych:",
      paymentPlanPrice:"Cena:",
      paymentSwitchToProDescription:"Znajdź plan odpowiedni dla siebie! Uaktualnij lub obniż plan w dowolnym momencie - ułatwiamy wybór odpowiedniego planu dla Ciebie.",
      paymentSwitchToProHeader:"Aktualizacja do Pro",
      paymentYourCurrentPlan:"Twój obecny plan:",
      paymentYourFuturePlan:"Twój nowy plan:",
      pearlescentGentianBlue:"Sapphire",
      periwinkleCrayolaAqua:"Cynevir",
      periwinkleCrayolaLilac:"Liliowy",
      placeholderText:"Wprowadź tutaj",
      poperBillings:"Płatności",
      poperLogout:"Wylogować się",
      poperPartnership:"Program partnerski",
      poperProfile:"Profil",
      poperTemplates:"Szablony",
      poperTrashcan:"Koszyk",
      profileChangePassword:"Zmiana hasła",
      profileDeleteAccount:"Usuń konto",
      profileDeleteAccountCheckedWord:"USUŃ",
      profileDeleteAccountPopupDescription:'Czy na pewno chcesz usunąć swoje konto? Tej czynności nie można anulować. Aby potwierdzić usunięcie, wpisz słowo "USUŃ" w polu poniżej.',
      profileDeleteAccountPopupHeader:"Chcesz usunąć swoje konto?",
      profileDeleteAccountPopupPlaceholder:"Wpisz tutaj",
      profileEmail:"E-mail",
      profileEmailCurent:"Aktualny adres e-mail",
      profileEmailNew:"Nowy adres e-mail",
      profileName:"Imię",
      profileNameFirst:"Imię",
      profileNameLast:"Nazwisko",
      profileNewPassword:"Nowe hasło",
      profileNewPasswordConfirm:"Potwierdź nowe hasło",
      profilePassword:"Hasło",
      profilePhone:"Numer telefonu",
      profileSaveNewData:"Zaktualizuj dane",
      profileStatus:"Status",
      profileStatusAgency:"Agencja",
      profileStatusBuisines:"Właściciel firmy",
      profileStatusFreelancer:"Freelancer",
      profileStatusPlaceholder:"Wybierz to, co najlepiej Cię opisuje",
      questionLogicAddConditionBtn:"Dodaj warunek",
      questionLogicAllMandatory:"Wszystkie poniższe warunki są wymagane",
      questionLogicConditionTitle:"Warunek.",
      questionLogicNotAvailable:"Ustawienia logiki nie są dostępne dla pierwszego pytania",
      questionLogicQuestion:"Pytanie.",
      questionLogicSaved:"Ustawienia logiki wyświetlania kroków są zapisane",
      questionLogicSetupAnswer:"Odpowiedź.",
      questionLogicSetupAnswerDisableOption:"Wybierz typ warunku",
      questionLogicSetupCustomContains:"Zawiera",
      questionLogicSetupCustomEqual:"Jest równy",
      questionLogicSetupCustomNotContains:"Nie zawiera",
      questionLogicSetupDescription:"Dodaj warunki, aby dostosować ankietę do każdego respondenta i zebrać dokładniejsze dane.",
      questionLogicSetupExpectedAnswer:"Oczekiwane odpowiedzi",
      questionLogicSetupLogicType:"Typ.",
      questionLogicSetupPlaceholder:"Wpisz tutaj",
      questionLogicSetupQuestionDisableOption:"Wybierz pytanie",
      questionLogicSetupRangeBetween:"Pomiędzy",
      questionLogicSetupRangeEqualTo:"Równy",
      questionLogicSetupRangeFrom:"Od",
      questionLogicSetupRangeLess:"Mniej niż",
      questionLogicSetupRangeMore:"Więcej niż",
      questionLogicSetupRangeTo:"Do",
      questionLogicSetupRangeValue:"Wartość",
      questionLogicSetupTitle:"Konfigurowanie logiki pytań",
      questionLogicSetupVariantAll:"Wszystkie",
      questionLogicSetupVariantAny:"Wybrana jest jedna z poniższych wartości",
      questionLogicSetupVariantNot:"Żadne z wybranych",
      quizSlotDeletedData:"Usunięto",
      quizSlotLastEdit:"Ostatnia zmiana",
      QuizTemplateSelect:"Wybierz",
      QuizTemplatesReadyTemplates:"Gotowe szablony",
      QuizTemplatesSearchBtn:"Wyszukiwanie",
      QuizTemplatesSearchInput:"Nazwa szablonu i/lub kategorii",
      QuizTemplateView:"Podgląd",
      quizzesTemplatesAllTags:"Wszystkie szablony",
      rangeIsRange:"Zakres",
      rangeIsRangeFalse:"Wyłączone",
      rangeIsRangeTrue:"Włączone",
      rangeMax:"Maks.",
      rangeMin:"Min",
      rangePositionEnd:"Pozycja końcowa",
      rangePositionStart:"Pozycja początkowa",
      rangeStep:"Krok",
      reciveEmailCheckbox:"Pole wyboru odbierania wiadomości e-mail",
      recivePromotionNewsletter:"Otrzymywanie wiadomości e-mail z newsletterem",
      resetPasswordTip:"W przypadku dalszych wątpliwości lub pytań prosimy o kontakt z naszym zespołem obsługi klienta. Jesteśmy tutaj, aby pomóc.",
      restoreQuizSendedDescription:"W zależności od liczby leadów w quizie, przywrócenie quizu może potrwać od kilku sekund do 2 minut. Po przywróceniu lead pojawi się na Workboardzie.",
      restoreQuizSendedTitle:"Zadanie odzyskiwania jest w toku",
      restoreSuizSendedImportant:"Ważne",
      sapphireBlue:"Ciemny szafir",
      saveMoney:"Abonament na rok",
      secondReduction:"sek",
      selectInternationalPrefix:"Wybierz format międzynarodowy",
      selectOption:"Wybierz opcję",
      selectQuizLanguage:"Wybierz język quizu",
      signupAgreeAnd:"i",
      signupAgreeIAgreeWith:"Zgadzam się z",
      signupAgreeOffer:"oferta publiczna",
      signupAgreePolicy:"polityka prywatności",
      signupAgreeService:"serwisu",
      signupBtnValue:"Utwórz konto",
      signupEmailPlaceholder:"E-mail",
      signupHeader:"Zarejestruj konto",
      signupHeader2Part:"aby kontynuować",
      signupLogin:"Zaloguj się do mojego konta",
      signupNamePlaceholder:"Alexander",
      signupPasswordlPlaceholder:"○ ○ ○ ○ ○ ○ ○ ○ ○",
      signupPhonePlaceholder:"numer telefonu",
      signupTariffFree:"Bezpłatnie.",
      signupTariffFreeDescription:"Nieograniczone tworzenie nowych quizów (stron internetowych). Możliwość wygenerowania do 300 leadów miesięcznie za darmo.",
      signupTariffHeader:"Taryfy",
      signupTariffPro:"Pro.",
      signupTariffProDescription:"Nieograniczone tworzenie nowych quizów (stron internetowych). Możliwość wygenerowania ",
      signupTariffProDescriptionBold:"ponad 300 leadów.",
      signupTariffProPrice:"$20 / miesiąc",
      signupWith:"Zarejestruj się w",
      slateGrayBlue:"Mgla",
      subscriptionCanceledTitle:"Subskrypcja została anulowana",
      subscriptionIsNotDeleteDescription:"Prosimy o kontakt z obsługą klienta",
      subscriptionIsNotDeleteTitle:"Subskrypcja nie została anulowana",
      subscriptionTarifLable:"Subskrybuj naszą automatyczną miesięczną subskrypcję. Plan można zmienić w dowolnym momencie.",
      subscriptionUnsubscribeModalDescription:"Czy na pewno chcesz zrezygnować z subskrypcji? Leady zgromadzone w ramach subskrypcji będzie można wykorzystać w ciągu miesiąca od daty ostatniego rozliczenia.",
      subscriptionUnsubscribeModalTitle:"Rezygnacja z subskrypcji",
      telegramTokenCopied:"Token quizu został skopiowany do schowka",
      templatesBackToQuiz:"Powrót do Workboard",
      temporarilyUnavailable:"Tymczasowo niedostępne",
      trashcanClearAll:"Wyczyść wszystko",
      trashcanDescription:"Quiz zostanie automatycznie usunięty na stałe w ciągu 30 dni po przeniesieniu go do koszyka.",
      trashcanImportant:"Ważne!",
      trashcanTitle:"Koszyk",
      turquoiseBlueCrayola:"Działanie",
      upProVersion:"Przełącz na PRO",
      upProVersionDesktop:"Przełącz na PRO",
      upProVersionMobile:"Do PRO",
      userAccountInfo:"Informacje o koncie",
      userBackToQuiz:"Powrót do Workboard",
      userBackToSettings:"Powrót do ustawień",
      userBillingsCost:"Koszt",
      userBillingsDate:"Data",
      userBillingsId:"ID",
      userBillingsMethod:"Metoda płatności",
      userGeneralInfoDescription1:"Profil użytkownika umożliwia zarządzanie kontem i ustawieniami.",
      userGeneralInfoDescription2:"Zaktualizuj swoje dane osobowe, zmień hasło i dostosuj informacje o powiadomieniach.",
      userGeneralInfoHeader:"Informacje ogólne",
      userSecuritySettingsDescription:"Aby zmienić hasło, wystarczy dwukrotnie wprowadzić nowe hasło w celu potwierdzenia.",
      userSecuritySettingsHeader:"Ustawienia zabezpieczeń",
      videoInstruction:"Wideo instrukcja ",
      violetBlueCrayola:"Zimowa bryza",
      welcomeBack:"Witamy ponownie!",
      wetRainforest:"Letni klon",
      widgetButtonBgColor:"Kolor przycisku",
      widgetButtonCornerRound:"Zaokrąglenie przycisku",
      widgetButtonInsertBeforeAfterBody:"Wstaw kod quizu tuż po tagu otwierającym `<body>` lub tuż przed tagiem zamykającym `</body>`.",
      widgetButtonInstruction0:"Możesz umieścić quiz na swojej stronie internetowej, który zostanie otwarty po kliknięciu przycisku.",
      widgetButtonInstruction1:"Dodaj zaokrąglenie, cień i tekst przycisku. Możesz także zmienić kolor przycisku i tekstu wewnątrz.",
      widgetButtonInstruction2:"Skopiuj skrypt znajdujący się w pierwszym polu kodu i wklej go przed tagiem zamykającym `</head>`.",
      widgetButtonInstruction3:"Skopiuj wygenerowany kod przycisku z drugiego pola kodu i wklej go w dowolnym miejscu na swojej stronie internetowej.",
      widgetButtonInstruction4:'Możesz pominąć kod z drugiego pola przycisku i po prostu dodać `id="button_open_quiz_popup"` do dowolnego elementu `<input type="button">` lub `<button></button>` na stronie internetowej.',
      widgetButtonShadow:"Cień przycisku",
      widgetButtonTextColor:"Kolor tekstu",
      widgetButtonTextValue:"Tekst przycisku",
      widgetEmbedVidgetButton:"Konstruktor przycisku",
      widgetEmbedVidgetChat:"Przycisk quizu",
      widgetEmbedVidgetInProgres:"W opracowaniu",
      widgetEmbedVidgetPopup:"Wyskakujące okienko",
      widgetEmbedVidgetSelectEmbedVariant:"Wybierz opcję integracji",
      widgetEmbedVidgetSitecode:"W kodzie strony",
      widgetEmbedVidgetToSite:"Umieszczenie quizu na stronie internetowej",
      widgetEmbedVidgetToSiteCode:"Umieszczenie quizu na stronie internetowej",
      widgetPopupCopyToBody:"Skopiuj i wklej kod przed tagiem zamykającym `</footer>`.",
      widgetPopupCopyToHead:"Skopiuj i wklej do `<head>` swojej strony internetowej.",
      widgetPopupInstruction0:"Aby umieścić quiz na swojej stronie internetowej, dodaj kod z pola kodu do tagu `<head>` na swojej stronie internetowej lub do Google Tag Manager, jeśli jest on zintegrowany z Twoją stroną internetową. Jeśli zmienisz domenę quizu lub open triggers (procent przewijania lub timing) po umieszczeniu quizu na swojej stronie internetowej, będziesz musiał zaktualizować kod integracji na swojej stronie internetowej.",
      widgetPopupInstruction1:"Tuż przed zamknięciem tagu `</footer>' wklej kod z drugiego pola.",
      widgetPopupInstruction2:"Dla programistów lub zaawansowanych użytkowników",
      widgetPopupInstruction3:"Jeśli z jakiegoś powodu nie czujesz się komfortowo z umieszczaniem quizu przed zamykającym tagiem `</footer>`, możesz wstawić kod z drugiego pola w dowolnym dogodnym miejscu, ale pamiętaj, że kontener, w którym wstawiasz kod z drugiego pola, powinien znajdować się na górze wszystkich okien i, najlepiej, powinien mieć maksymalną możliwą wysokość i szerokość wyświetlania.",
      widgetPopupScrollClarification:"Wyskakujące okienko pojawi się po określonym powyżej procencie przewijania strony internetowej",
      widgetPopupScrollToOpen:"Procent przewijania strony internetowej",
      widgetPopupTimeClarification:"Wyskakujące okienko pojawi się po upływie powyższego zaznaczonego czasu w sekundach",
      widgetPopupTimeToOpen:"Przedział czasu",
      widgetRealTimeChange:"Możesz zobaczyć zmiany w czasie rzeczywistym po prawej stronie ekranu.",
      widgetSiteCodeCopyButton:"Wklej kod przycisku w dowolnym miejscu na swojej stronie internetowej ",
      widgetSiteCodeCopyToBody:"Wklej kod w miejscu, w którym chcesz umieścić przycisk",
      widgetSiteCodeCopyToHead:"Skopiuj i wklej do `<head>` swojej strony internetowej.",
      widgetSiteCodeInstruction0:"Aby umieścić quiz na swojej stronie internetowej, dodaj kod z pola kodu do tagu `<head>` na swojej stronie internetowej lub do Google Tag Manager, jeśli jest on zintegrowany z Twoją stroną internetową. Jeśli zmienisz domenę quizu na swojej stronie internetowej, będziesz musiał zaktualizować kod integracji na swojej stronie internetowej.",
      widgetSiteCodeInstruction1:"Wklej kod z drugiego pola w miejscu, w którym chcesz zintegrować quiz.",
      widgetSiteCodeInstruction2:"Quiz można zintegrować z kontenerem o dowolnym rozmiarze, nawet z sidebarem! Maksymalny rozmiar quizu, jak również w przypadku uruchomienia w oddzielnej domenie, wynosi 900x552 pikseli. Można go dodać do kontenerów o większym rozmiarze, w tym przypadku quiz zostanie wyśrodkowany.",
      widgetSiteCodeInstruction3:"Nie wahaj się eksperymentować!",
      workboardAddNewQuiz:"Stwórz projekt",
      workboardAlertGiveAnyName:"Wprowadź przynajmniej nazwę",
      workboardAlertNextTimeAddQuiz:"Cóż, następnym razem odważ się:)",
      workboardChangeQuizName:"Zmień nazwę",
      workboardCopyQuiz:"Skopiować cytat",
      workboardDeleteQuiz:"Usuń",
      workboardDuplicate:"Duplikować",
      workboardEditQuiz:"Edytuj",
      workboardLeads:"Leady",
      workboardModalAddQuizHeader:"Stwórz nowy quiz",
      workboardModalDeleteQuizCancel:"Anuluj",
      workboardModalDeleteQuizDelete:"Usuń",
      workboardModalDeleteQuizIrreversible:"To działanie jest nieodwracalne!",
      workboardModalDeleteQuizSuccess:"Quiz został pomyślnie usunięty",
      workboardModalDeleteQuizSure:"Czy na pewno chcesz usunąć ten quiz?",
      workboardModalGiveQuizName:"Nadaj quizu nazwę",
      workboardModalGiveQuizNameAndMethod:"Wymyśl nazwę dla swojego quizu i wybierz preferowaną metodę tworzenia.",
      workboardModalLeadsAnswersCount:"Odpowiedzi na zapytania",
      workboardModalLeadsHaveNotSended:"Masz nieodebrane leady",
      workboardModalLeadsLeadActions:"Działania",
      workboardModalLeadsLeadAnswerScipped:"Nieodebrane",
      workboardModalLeadsLeadDate:"Data",
      workboardModalLeadsLeadEmail:"E-mail",
      workboardModalLeadsLeadEmailCopied:"Email skopiowany do bufora",
      workboardModalLeadsLeadHideMore:"Ukryć",
      workboardModalLeadsLeadLimit:"Osiągnięto limit leadów",
      workboardModalLeadsLeadLimitInstruction1:"Aby zobaczyć leadów,",
      workboardModalLeadsLeadLimitInstruction2:"przełącz się na inny plan",
      workboardModalLeadsLeadLimitInstruction3:"uaktualnić bieżący plan",
      workboardModalLeadsLeadLimitInstruction4:'(nie dotyczy "Test") lub poczekaj na aktualizację planu, jeśli masz włączone automatyczne odnawianie planu.',
      workboardModalLeadsLeadName:"Nazwa",
      workboardModalLeadsLeadPhone:"Numer telefonu",
      workboardModalLeadsLeadPhoneCopied:"Numer telefonu jest kopiowany do bufora",
      workboardModalLeadsLeadReferrer:"Niezdefiniowany",
      workboardModalLeadsLeadReferrerClarification:"Dane źródła przełączenia są zablokowane lub niezdefiniowane",
      workboardModalLeadsLeadReload:"Odbierz",
      workboardModalLeadsLeadReloaded:"Jeśli pozostały jakieś leady, nieodebrane leady zostaną załadowane na górze listy. Zostaną one również wysłane do dodanych integracji",
      workboardModalLeadsLeadReloadedTitle:"Wniosek został przyjęty",
      workboardModalLeadsLeadSerial:"№",
      workboardModalLeadsLeadShowMore:"Więcej",
      workboardModalNewQuizBtnValue:"dodać",
      workboardModalNewQuizFromStart:"Stwórz od 0",
      workboardModalNewQuizFromTemplates:"Gotowe szablony",
      workboardModalNoLeads:"Nie ma jeszcze żadnych leadów",
      workboardModalQuizDeleteOk:"OK",
      workboardModalQuizNamePlaceholder:"Nowy quiz",
      workboardQuizCopied:"Quiz został pomyślnie skopiowany",
      workboardQuizLink:"link",
      workboardQuizLinkCopied:"Link został skopiowany do bufora",
      workboardQuizzesTitle:"Moje quizy",
      workboardStatsTitle:"Statystyki",
      youCanChangePlan:'Możesz zmienić swój obecny plan, klikając przycisk "Przełącz na PRO"',




      csettingsIntegrationSendPulse: "Integracja - Send Pulse",
      csettingsIntegrationMake: "Integracja - Make",
      csettingsIntegrationZapier: "Integracja - Zapier",
      csettingsIntegrationCRMBitrix: "Integracja - CRM Bitrix",
      csettingsIntegrationPipeDrive: "Integracja - Pipe Drive",
      csettingsIntegrationRemOnline: "Integracja - Rem Online",
      csettingsIntegrationKeyCRM: "Integracja - Key CRM",
      csettingsIntegrationZapierAbout: "Wklej adres URL wysyłania danych dostarczony przez Zapier do pola wejściowego.",
      csettingsIntegrationMakeAbout: "Wklej adres URL wysyłania danych dostarczony przez Make do pola wejściowego.",
      csettingsIntegrationCRMBitrixAbout: "Wklej adres URL wysyłania danych dostarczony przez CRM Bitrix do pola wejściowego.",
      csettingsIntegrationSendPulseAbout: "Wklej identyfikator klienta, klucz sekretny, książkę adresową i działanie Send Pulse do odpowiednich pól wejściowych.",
      csettingsIntegrationKeyCRMAbout: "Wklej klucz API i identyfikator potoku do odpowiednich pól wejściowych.",
      csettingsIntegrationPipeDriveAbout: "Wklej token i domenę firmy do odpowiednich pól wejściowych.",
      csettingsIntegrationRemOnlineAbout: "Wklej klucz API Rem Online do odpowiedniego pola wejściowego.",
      csettingsIntegrationActiveIntegration: "Aktywna integracja",
      cintegrationIntegrationData: "Dane integracji",
      cintegrationIntegrationUrl: "URL integracji",
      cintegrationWriteUrl: "Wprowadź URL",
      cintegrationDataFBError: "Nieprawidłowy identyfikator piksela",
      cintegrationDataWebHookError: "Nieprawidłowy adres webhooka",
      cintegrationZapierDataSaved: "Dane integracji Zapier zapisane",
      cintegrationDataZapierError: "Nieprawidłowy adres integracji",
      cintegrationAPIxDriveDataSaved: "Dane integracji APIxDrive zapisane",
      cintegrationDataAPIxDriveError: "Ciąg nie jest adresem integracji APIxDrive",
      cintegrationMakeDataSaved: "Dane integracji Make zapisane",
      cintegrationDataMakeError: "Nieprawidłowy adres integracji",
      cintegrationSendPulseDataSaved: "Dane integracji SendPulse zapisane",
      cintegrationDataSendPulseError: "Nieprawidłowe lub puste dane integracji",
      cintegrationPipeDriveDataSaved: "Dane integracji PipeDrive zapisane",
      cintegrationDataPipeDriveError: "Nieprawidłowe lub puste dane integracji",
      cintegrationRemOnlineDataSaved: "Dane integracji RemOnline zapisane",
      cintegrationDataRemOnlineError: "Nieprawidłowe lub puste dane integracji",
      cintegrationKeyCRMDataSaved: "Dane integracji KeyCRM zapisane",
      cintegrationDataKeyCRMError: "Nieprawidłowe lub puste dane integracji",
      cintegrationInputEmailUrl: "Wprowadź e-mail",
      cintegrationEmailDataSaved: "E-mail zapisany",
      cintegrationDataEmailError: "Wprowadzony ciąg nie jest adresem e-mail",
      cintegrationCRMBitrixDataSaved: "E-mail zapisany",
      cintegrationDataCRMBitrixError: "Wprowadzony ciąg nie jest adresem e-mail",
      cintegrationTelegramBotDataSaved: "Ustawienia integracji z Telegram zapisane",
      cintegrationDataTelegramBotError: "Błąd zapisu",
      cintegrationGTMDataSaved: "Zapisany identyfikator Google Tag Manager",
      cintegrationDataGTMError: "Nieprawidłowy identyfikator",
      cintegrationDataGTMErrorDescription: "Identyfikator powinien mieć format GTM-XXXXXX",
      cintegrationGoogleAnalyticsDataSaved: "Zapisany identyfikator Google Analytics",
      cintegrationDataGoogleAnalyticsError: "Nieprawidłowy identyfikator",
      cintegrationDataGoogleAnalyticsErrorDescription: 'Identyfikator powinien mieć format "UA-XXXXXXX-X" lub "G-XXXXXXXXXX-XX"',
      cintegrationTikTokDataSaved: "Piksel Tik-Tok zapisany",
      cintegrationDataTikTokError: "Wprowadzony ciąg nie jest pikselem Tik-Tok",
      cintegrationIntegrationToken: "Token integracji",
      cintegrationWriteToken: "Wprowadź token integracji",
      cintegrationDataYourAPIKey: "Wprowadź swój klucz API",
      cintegrationDataLeadTypeId: "Wprowadź identyfikator kanału",
      send_utm: "Wyślij znaczniki UTM",
      send_quiz: "Wyślij dane quizu",
      send_answers: "Wyślij odpowiedzi",
      csettingsIntegrationKeyCrm: "Integracja KeyCRM",
      cintegrationSendPulseClientId: "Identyfikator klienta",
      cintegrationSendPulseClientSecret: "Sekret",
      cintegrationSendPulseAddressBookId: "Identyfikator książki adresowej",
      cintegrationSendPulseAction: "Działanie z kontaktem",
      sendpulseActionAddContact: "Dodaj kontakt",
      sendpulseActionDeleteContact: "Usuń kontakt",
      cintegrationPipeDriveToken: "Token API",
      cintegrationPipeDriveDomain: "Twój unikalny subdomena",
      cintegrationKeyCrmApiKey: "Klucz API",
      cintegrationKeyCrmPipelineId: "Identyfikator potoku",
      cintegrationMenuGoogleTagManager:"Menadżer tagów Google",
      cintegrationMenuGoogleAnalytics:"Google Analytics",
      cintegrationMenuFacebookPixel:"Piksel Facebooka",
      cintegrationMenuTikTokPixel:"Piksel TikTok",
      cintegrationMenuWebHook:"Webhook",
      cintegrationMenuZapier:"Zapier",
      cintegrationMenuAPIxDrive:"APIx Drive",
      cintegrationMenuMake:"Make",
      cintegrationMenuSendPulse:"Send Pulse",
      cintegrationMenuEmail:"E-mail",
      cintegrationMenuTelegramBot:"Bot Telegram",
      cintegrationMenuPipeDrive:"PipeDrive",
      cintegrationMenuRemOnline:"Rem Online",
      cintegrationMenuKeyCRM:"Key CRM",
      cintegrationMenuCRMBitrix:"CRM Bitrix",
      trashcanRestoreQuiz: "Przywróć quiz",
      cformValuePromotionAccepted: "Zgadzam się na newsletter",


      constructorAddIntegration: "Dodaj nową integrację",
      cintegrationKeyCrmSourceId: "Identyfikator źródła (opcjonalnie)",
      cintegrationKeyCrmManagerId: "Identyfikator menedżera (opcjonalnie)",
      cSettingsSettingQuizSpamFilterTitle: "Ochrona przed spamem",
      cSettingsSettingQuizSpamFilterDescription: "Funkcja jest domyślnie wyłączona. Jeśli napotkasz problem spamowych leadów, włącz ochronę. Po tym jeden użytkownik nie będzie mógł przesłać więcej niż określona liczba leadów dla tego quizu.",
      cSettingsSettingQuizSpamFilterLimitPlaceholder: "Liczba leadów",
      langNO: "Norweski",
      loadMoreLeads: "Zobacz więcej leadów",
      userAdditionalSettingsHeader: "Zaawansowane ustawienia",
      userAdditionalSettingsDescription1: "Tutaj możesz skonfigurować nowe zaawansowane ustawienia profilu.",
      userAdditionalSettingsPagination: "Paginacja na pulpicie roboczym",

      benefitType: "Typ bonusu",
      benefitLabelPlaceholder: "Tekst bonusu",
      benefitDescriptionPlaceholderDiscount: "Kod promocyjny lub instrukcja",
      benefitDescriptionPlaceholderVideo: "Link do wideo",
      benefitDescriptionPlaceholderTextfile: "Link do pliku tekstowego",
      benefitDescriptionPlaceholderGift: "Opis bonusu",
      benefitDescriptionPlaceholderPDF: "Link do PDF",
      benefitDescriptionPlaceholderLink: "Link",
      benefitDescriptionPlaceholderFile: "Link do pliku",
      benefitLabelDiscount: "Zniżka",
      benefitLabelVideo: "Wideo",
      benefitLabelTextfile: "Plik tekstowy",
      benefitLabelGift: "Prezent",
      benefitLabelPDF: "Plik PDF",
      benefitLabelLink: "Link",
      benefitLabelFile: "Plik",
      addBenefit: "Dodaj bonus",
      saveBenefit: "Zapisz bonus",
      cfinishSocialNetworks: "Media społecznościowe",
      
      saveSocialNet: "Zapisz sieć społecznościową",
      networkLabelFacebook: "Facebook",
      networkLabelInstagram: "Instagram",
      networkLabelTelegram: "Telegram",
      networkLabelTiktok: "Tik-Tok",
      networkLabelYoutube: "Youtube",
      networkLabelLinkedin: "LinkedIn",
      networkLabelWhatsapp: "WhatsApp",
      socialNetDescriptionPlaceholderFacebook: "nazwa_profilu",
      socialNetDescriptionPlaceholderInstagram: "nazwa_profilu",
      socialNetDescriptionPlaceholderTelegram: "nazwa_profilu/kanalu",
      socialNetDescriptionPlaceholderTiktok: "nazwa_profilu",
      socialNetDescriptionPlaceholderYoutube: "nazwa_kanalu",
      socialNetDescriptionPlaceholderLinkedin: "in/name or company/name",
      socialNetDescriptionPlaceholderWhatsapp: "+15555551234",

      addNewSectionEmptyPage: "Strona tekstowa",
      csanyMakeImpasse: "Zrób ślepy zaułek",
      impaseIsImpaseTrue: "Włączony",
      impaseIsImpaseFalse: "Wyłączony",
      defaultNewEmptyQuestionDescription: "Tutaj możesz wklejać i formatować długi tekst (nie zalecamy wklejania tekstu dłuższego niż 3000 znaków).",
      cSettingsSettingQuizTimezoneTitle: "Strefa czasowa quizu",
      cSettingsSettingQuizTimezoneDescription: "Wprowadź strefę czasową, według której będzie rejestrowany czas wypełniania leadów. Jest to szczególnie przydatne, gdy Ty znajdujesz się w jednej strefie czasowej, a klient w innej. W takim przypadku możesz ustawić strefę czasową klienta, a w leadach, które otrzymasz, czas będzie podawany zgodnie z jego strefą czasową.",
      cSettingsSettingQuizTimezonePlaceholder: "Strefa czasowa",
      userAdditionalSettingsTimezone: "Strefa czasowa użytkownika",
      userAdditionalSettingsTimezonePlaceholder: "Strefa czasowa",
      langHE: "Hebrajski",
      langCS: "Czeski",
      cintegrationCAPIFBInstruction: "Aby skonfigurować integrację z Facebook Conversion API, należy wprowadzić identyfikator piksela i token dostępu. Te dane można uzyskać w ustawieniach Facebook Business Manager. Po wprowadzeniu wymaganych informacji zapisz integrację.",
      csettingsIntegrationFbCapiAbout: "Integracja z Facebook Conversion API umożliwia przesyłanie danych o interakcjach użytkowników bezpośrednio do Facebooka w celu zwiększenia skuteczności kampanii reklamowych.",
      cintegrationInputCapiAccessToken: "Wprowadź token dostępu",
      cintegrationInputCapiCustomparameter: "Wprowadź niestandardowe parametry zdarzeń",
      cintegrationDataFbCapiSaved: "Integracja z Facebook Conversion API została pomyślnie zapisana",
      cintegrationComonRenovateHubInstructionTitle: "Instrukcja konfiguracji integracji z RenovateHub",
      cintegrationComonRenovateHubInstructionDescription: "Aby skonfigurować integrację z RenovateHub, podaj URL webhooka, na który będą przesyłane dane. Upewnij się, że URL jest poprawny i dostępny do odbierania danych.",
      cintegrationComonRenovateHubInstructionShort: "Integracja z RenovateHub pozwala na przesyłanie danych do CRM przez webhook w celu automatyzacji procesów.",
      cintegrationRenovateHubUrl: "Wprowadź URL RenovateHub",
      cintegrationInputRenovateHubUrl: "Podaj URL webhooka dla RenovateHub",
      cintegrationRenovateHubDataSaved: "Integracja z RenovateHub została pomyślnie zapisana",
      cintegrationDataRenovateHubError: "Błąd podczas zapisywania integracji z RenovateHub. Sprawdź poprawność wprowadzonych danych.",
      cintegrationMenuFacebookConvAPI: "Facebook Conversions API",
      cintegrationMenuRenovateHub: "RenovateHub",
      csettingsIntegrationRenovateHub: "Integracja RenovateHub",
      csettingsIntegrationCAPIFB: "Integracja Facebook Conversions API",
      paymentPlanEnd: "Data wygaśnięcia taryfy",
      paymentPlanLeadsLimitForYear:"Limit leadów na rok:",
      paymentPlanLeadsLimitForPeriod:"Limit leadów na okres:",
      EditTags: "Edytuj tagi",
      quizzesTemplatesNoTags: "Brak tagów",

      Monday: "Poniedziałek",
      Tuesday: "Wtorek",
      Wednesday: "Środa",
      Thursday: "Czwartek",
      Friday: "Piątek",
      Saturday: "Sobota",
      Sunday: "Niedziela",
      datetimeDisplayMode: "Typ wybierania",
      datetimeDisplayModeSelect: "Wybierz typ",
      datetimeDisplayModeDate: "Data",
      datetimeDisplayModeTime: "Godzina",
      datetimeDisplayModeDatetime: "Data i godzina",
      datetimeMinDate: "Min. data",
      datetimeMinDateCustom: "Wybierz min. datę",
      datetimeMaxDate: "Maks. data",
      datetimeMaxDateCustom: "Wybierz maks. datę",
      datetimeProhibitDaysOfWeek: "Zablokuj dni",
      datetimeIsRange: "Włącz zakres",
      datetimeIsRangeTrue: "Zakres włączony",
      datetimeIsRangeFalse: "Zakres wyłączony",
      datetimeMinTime: "Min. godzina",
      datetimeMaxTime: "Maks. godzina",
      datetimeIs24: "Format 24 godz.",
      datetimeIs24True: "24 godz. włączone",
      datetimeIs24False: "24 godz. wyłączone",
      csanyIsExactDate: "Jak chcesz ustawić datę",
      csanyIsExactDateTrue: "Dokładna data",
      csanyIsExactDateFalse: "Data względna",
      csanyDatetimeMinMaxNotExactPlaceholder: '"x" lub "-x" dni od dzisiaj',
      addNewSectionDatetime: "Data/godzina",


    },
  },
})
export default i18n