import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'

import axios from 'axios';
window.axios = require('axios');
axios.defaults.withCredentials=true;

import { createPinia } from 'pinia'
const pinia = createPinia()

import PerfectScrollbar from 'vue3-perfect-scrollbar'
import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";
import Popper from "vue3-popper"
import Notifications from '@kyvg/vue3-notification'
import tooltip from "./assets/v-tooltip/tooltip.js";
import "./assets/v-tooltip/tooltip.css";
import QuillEditor from './components/common/QuillEditor.vue';

import VueTelInput from 'vue-tel-input'
const globalOptionsVueTelInput = { mode: 'auto',};
import 'vue-tel-input/dist/vue-tel-input.css'

import ModalHiddenStep from "@/components/modals/ModalHiddenStep.vue"; 
import authMixin from './mixins/authMixin';





// This is work but right now I conect this Stylesheet from App.vue (because of file-version)
// import './../public/css/style.css'

const app = createApp(App)
    .use(pinia)
    .use(router)
    .use(i18n)
    .use(PerfectScrollbar)
    .use(VueAwesomePaginate)
    .use(Notifications)
    .use(VueTelInput, globalOptionsVueTelInput)
    .directive("tooltip", tooltip)
    .mixin(authMixin)
    .component("ModalHiddenStep", ModalHiddenStep)
    .component("Popper", Popper)
    .component('QuillEditor', QuillEditor);

    
app.mount('#app')


